<div class="home-style-two home-bg">
    <div class="container">
        <div class="home-text-two">
            <h1>Stay Neat & Clean Stay Healthy</h1>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ut labore et dolore magna aliqua.</p>
            <form>
                <div class="request-call">
                    <input class="form-control" placeholder="Your Number">
                    <button type="submit">Request Call</button>
                </div>
            </form>
        </div>
        
        <div class="home-image">
            <img src="assets/img/home/5.png" alt="home image">
            <img src="assets/img/home/6.png" alt="home-image">
        </div>

        <div class="home-shape">
            <img src="assets/img/service/shapes/1.png" alt="shape">
            <img src="assets/img/service/shapes/2.png" alt="shape">
            <img src="assets/img/service/shapes/3.png" alt="shape">
            <img src="assets/img/service/shapes/4.png" alt="shape">
            <img src="assets/img/service/shapes/5.png" alt="shape">
            <img src="assets/img/service/shapes/6.png" alt="shape">
            <img src="assets/img/service/shapes/7.png" alt="shape">
            <img src="assets/img/service/shapes/8.png" alt="shape">
            <img src="assets/img/service/shapes/9.png" alt="shape">
            <img src="assets/img/service/shapes/10.png" alt="shape">
        </div>
    </div>
</div>

<div class="info-section">
    <div class="container">
        <div class="info-area">
            <div class="row">
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="info-text">
                        <i class="icofont-wall-clock"></i>
                        <h6>We Are Available</h6>
                        <p>Sun - Thu : 10:00AM - 06:00PM</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="info-text">
                        <i class="icofont-location-pin"></i>
                        <h6>Location</h6>
                        <p>28/A Street, New York City</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="info-text">
                        <i class="icofont-phone"></i>
                        <h6>Call Emergency</h6>
                        <p>+88 0123 456 789</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="info-text">
                        <i class="icofont-ui-message"></i>
                        <h6>Message Us</h6>
                        <p>hello@kiaz.com</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="service-style-two pt-100">
    <div class="container">
        <div class="section-head text-center">
            <h2>Viscous Provided <span>Services</span></h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore et dolore magna aliqua.</p>
        </div>

        <div class="service-slider-wrapper owl-carousel owl-theme">
            <div class="row align-items-center bg-white">
                <div class="col-lg-6">
                    <div class="service-img">
                        <img src="assets/img/service/1.png" alt="service image">
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="service-text-two">
                        <h4>House Cleaning</h4>
                        <p>It is a long fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less.</p>

                        <ul>
                            <li><i class="icofont-check-circled"></i> Kitchen Cleaning</li>
                            <li><i class="icofont-check-circled"></i> Balcony</li>
                            <li><i class="icofont-check-circled"></i> Floor Cleaning</li>
                            <li><i class="icofont-check-circled"></i> Bathroom</li>
                            <li><i class="icofont-check-circled"></i> Window Cleaning</li>
                        </ul>

                        <div class="theme-button">
                            <a routerLink="/service" class="default-btn">More Services</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row align-items-center bg-white">
                <div class="col-lg-6">
                    <div class="service-img">
                        <img src="assets/img/service/3.png" alt="service image">
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="service-text-two">
                        <h4>House Cleaning</h4>
                        <p>It is a long fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less.</p>

                        <ul>
                            <li><i class="icofont-check-circled"></i> Kitchen Cleaning</li>
                            <li><i class="icofont-check-circled"></i> Balcony</li>
                            <li><i class="icofont-check-circled"></i> Floor Cleaning</li>
                            <li><i class="icofont-check-circled"></i> Bathroom</li>
                            <li><i class="icofont-check-circled"></i> Window Cleaning</li>
                        </ul>

                        <div class="theme-button">
                            <a routerLink="/service" class="default-btn">More Services</a>
                        </div>
                    </div>
                </div>
            </div>                  
        </div>
    </div>
</div>

<section class="hire-section">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6">
                <div class="hire-text ptb-100">
                    <div class="section-head blue-title">
                        <h2>Why Should <span>Hire Us</span></h2>
                        <p>Lorem Ipsum is simply dummied text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the.</p>
                    </div>

                    <div class="row">
                        <div class="col-lg-5 col-md-5 col-sm-6 p-0 mr-20">
                            <ul>
                                <li><i class="icofont-check-circled"></i> 100% Secured</li>
                                <li><i class="icofont-check-circled"></i> Well Disciplined</li>
                                <li><i class="icofont-check-circled"></i> Budget Oriented</li>
                                <li><i class="icofont-check-circled"></i> Eco Friendly</li>
                                <li><i class="icofont-check-circled"></i> Top Rated</li>
                            </ul>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6 p-0">
                            <ul>
                                <li><i class="icofont-check-circled"></i> Fast & Clean Service</li>
                                <li><i class="icofont-check-circled"></i> 24/7 Services</li>
                                <li><i class="icofont-check-circled"></i> Online Payment</li>
                                <li><i class="icofont-check-circled"></i> 100% Satisfaction</li>
                                <li><i class="icofont-check-circled"></i> Clean Record</li>
                            </ul>
                        </div>
                    </div>

                    <div class="theme-button">
                        <a routerLink="/lien-he" class="default-btn">Get A Quote</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 pr-0">
                <div class="hire-img"></div>

                <div class="discount-text">
                    <h2><span>15% Discount</span> for Our New Client</h2>

                    <div class="theme-button">
                        <a routerLink="/pricing" class="default-btn">Get A Quote</a>
                    </div>
                    <div class="discount-shape">
                        <img src="assets/img/hire/shape-1.png" alt="shape">
                        <img src="assets/img/hire/shape-2.png" alt="shape">
                        <img src="assets/img/hire/shape-3.png" alt="shape">
                        <img src="assets/img/hire/shape-4.png" alt="shape">
                        <img src="assets/img/hire/shape-3.png" alt="shape">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="process-section process-style-two pb-70">
    <div class="container">
        <div class="section-head blue-title text-center">
            <h2>How Do We <span>Work</span></h2>
            <p>Lorem Ipsum is simply dummied text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.</p>
        </div>
        <div class="row">
            <div class="col-md-4 col-sm-6">
                <div class="process-card">
                    <i class="icofont-touch"></i>
                    <h3>Choose Services</h3>
                    <p>Lorem Ipsum is simply dummied text of the printing and typesetting industry.</p>
                    <img src="assets/img/process-pattern-2.png" alt="arrow">
                </div>
            </div> 
            
            <div class="col-md-4 col-sm-6">
                <div class="process-card">
                    <i class="icofont-world"></i>
                    <h3>Booking Online</h3>
                    <p>Lorem Ipsum is simply dummied text of the printing and typesetting industry.</p>
                    <img src="assets/img/process-pattern-2.png" alt="arrow">
                </div>
            </div> 

            <div class="col-md-4 col-sm-6 offset-sm-3 offset-md-0">
                <div class="process-card">
                    <i class="icofont-recycle-alt"></i>
                    <h3>Enjoy Cleaning</h3>
                    <p>Lorem Ipsum is simply dummied text of the printing and typesetting industry.</p>
                </div>
            </div> 
        </div>
    </div>
</div>

<div class="counter-style-two pt-100 pb-70">
    <div class="container">
        <h2 class="text-center">Our Track <span>Records</span></h2>

        <div class="row">
            <div class="col-md-3 col-5">
                <div class="counter-text">
                    <h2><span class="counter">1</span>K</h2>
                    <p>Job Done</p>
                </div>
            </div>

            <div class="col-md-3 col-7">
                <div class="counter-text">
                    <h2><span class="counter">989</span>+</h2>
                    <p>Happy Clients</p>
                </div>
            </div>

            <div class="col-md-3 col-5">
                <div class="counter-text">
                    <h2><span class="counter">45</span>+</h2>
                    <p>Experts</p>
                </div>
            </div>

            <div class="col-md-3 col-7">
                <div class="counter-text">
                    <h2><span class="counter">12</span>+</h2>
                    <p>Years of Experience</p>
                </div>
            </div>
        </div>

        <div class="counter-shape">
            <img src="assets/img/counter/1.png" alt="shape">
            <img src="assets/img/counter/2.png" alt="shape">
            <img src="assets/img/counter/3.png" alt="shape">
            <img src="assets/img/counter/4.png" alt="shape">
            <img src="assets/img/counter/5.png" alt="shape">
            <img src="assets/img/counter/6.png" alt="shape">
            <img src="assets/img/counter/7.png" alt="shape">
            <img src="assets/img/counter/1.png" alt="shape">
            <img src="assets/img/counter/8.png" alt="shape">
            <img src="assets/img/counter/4.png" alt="shape">
        </div>
    </div>
</div>

<section class="recent-work-section pt-100">
    <div class="container-fluid p-0">
        <div class="section-head blue-title text-center">
            <h2>Recently <span>We Worked</span> With</h2>
            <p>Lorem Ipsum is simply dummied text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.</p>
        </div>

        <div class="recent-work-slider owl-carousel owl-theme">
            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/recent-work/1.png" alt="project image">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/recent-work/1.png" class="popup-gallery d-block"><i class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/recent-work/2.png" alt="project image">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/recent-work/2.png" class="popup-gallery d-block"><i class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/recent-work/3.png" alt="project image">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/recent-work/3.png" class="popup-gallery d-block"><i class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/recent-work/4.png" alt="project image">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/recent-work/4.png" class="popup-gallery d-block"><i class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/recent-work/5.png" alt="project image">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">                
                                <a href="assets/img/recent-work/5.png" class="popup-gallery d-block"><i class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/recent-work/1.png" alt="project image">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/recent-work/1.png" class="popup-gallery d-block"><i class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/recent-work/2.png" alt="project image">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/recent-work/2.png" class="popup-gallery d-block"><i class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/recent-work/3.png" alt="project image">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/recent-work/3.png" class="popup-gallery d-block"><i class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/recent-work/4.png" alt="project image">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/recent-work/4.png" class="popup-gallery d-block"><i class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/recent-work/5.png" alt="project image">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">                
                                <a href="assets/img/recent-work/5.png" class="popup-gallery d-block"><i class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/recent-work/1.png" alt="project image">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/recent-work/1.png" class="popup-gallery d-block"><i class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/recent-work/2.png" alt="project image">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/recent-work/2.png" class="popup-gallery d-block"><i class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/recent-work/3.png" alt="project image">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/recent-work/3.png" class="popup-gallery d-block"><i class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/recent-work/4.png" alt="project image">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/recent-work/4.png" class="popup-gallery d-block"><i class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/recent-work/5.png" alt="project image">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">                
                                <a href="assets/img/recent-work/5.png" class="popup-gallery d-block"><i class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="pricing-section pb-70">
    <div class="container">
        <div class="section-head text-center">
            <h2>Our <span>Pricing Plan</span></h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="price-card-area">
                    <div class="price-head-text">
                        <h4>Basic Plan</h4>
                        <h2>$<span>60</span>/month</h2>
                    </div>

                    <div class="plan-features">
                        <ul>
                            <li><i class="icofont-check-circled"></i> Fully Satisfaction</li>
                            <li><i class="icofont-check-circled"></i> 5 Hours</li>
                            <li><i class="icofont-check-circled"></i> 1 time in a week</li>
                            <li><i class="icofont-check-circled"></i> Full Cleaning</li>
                            <li><i class="icofont-check-circled"></i> All Our Equipment</li>
                        </ul>
                    </div>

                    <div class="plan-btn">
                        <a routerLink="/">Booking Now</a>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="price-card-area active-price">
                    <div class="price-head-text">
                        <h4>Standard Plan</h4>
                        <h2>$<span>99</span>/month</h2>
                    </div>

                    <div class="plan-features">
                        <ul>
                            <li><i class="icofont-check-circled"></i> Fully Satisfaction</li>
                            <li><i class="icofont-check-circled"></i> 7 Hours</li>
                            <li><i class="icofont-check-circled"></i> 2 time in a week</li>
                            <li><i class="icofont-check-circled"></i> Full Cleaning</li>
                            <li><i class="icofont-check-circled"></i> All Our Equipment</li>
                        </ul>
                    </div>

                    <div class="plan-btn">
                        <a routerLink="/">Booking Now</a>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 offset-md-3 col-sm-6  offset-lg-0 offset-sm-3">
                <div class="price-card-area">
                    <div class="price-head-text">
                        <h4>Premium Plan</h4>
                        <h2>$<span>159</span>/month</h2>
                    </div>

                    <div class="plan-features">
                        <ul>
                            <li><i class="icofont-check-circled"></i> Fully Satisfaction</li>
                            <li><i class="icofont-check-circled"></i> 10 Hours</li>
                            <li><i class="icofont-check-circled"></i> 3 time in a week</li>
                            <li><i class="icofont-check-circled"></i> Full Cleaning</li>
                            <li><i class="icofont-check-circled"></i> All Our Equipment</li>
                        </ul>
                    </div>

                    <div class="plan-btn">
                        <a routerLink="/">Booking Now</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="team-section pb-70">
    <div class="container">
        <div class="section-head text-center">
            <h2>Our <span>Team Members</span></h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/1.png" alt="team member">

                        <div class="team-social">
                            <a href="#" target="_blank"><i class="icofont-facebook"></i></a>
                            <a href="#" target="_blank"><i class="icofont-twitter"></i></a>
                            <a href="#" target="_blank"><i class="icofont-linkedin"></i></a>
                            <a href="#" target="_blank"><i class="icofont-instagram"></i></a>
                        </div>
                    </div>

                    <div class="team-text">
                        <h4>John Smith</h4>
                        <p>Head of Cleaner</p>
                    </div>
                </div>
            </div> 
            
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/2.png" alt="team member">

                        <div class="team-social">
                            <a href="#" target="_blank"><i class="icofont-facebook"></i></a>
                            <a href="#" target="_blank"><i class="icofont-twitter"></i></a>
                            <a href="#" target="_blank"><i class="icofont-linkedin"></i></a>
                            <a href="#" target="_blank"><i class="icofont-instagram"></i></a>
                        </div>
                    </div>

                    <div class="team-text">
                        <h4>Marry</h4>
                        <p>Cleaner</p>
                    </div>
                </div>
            </div> 

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/3.png" alt="team member">

                        <div class="team-social">
                            <a href="#" target="_blank"><i class="icofont-facebook"></i></a>
                            <a href="#" target="_blank"><i class="icofont-twitter"></i></a>
                            <a href="#" target="_blank"><i class="icofont-linkedin"></i></a>
                            <a href="#" target="_blank"><i class="icofont-instagram"></i></a>
                        </div>
                    </div>

                    <div class="team-text">
                        <h4>John Smith</h4>
                        <p>Office Cleaner</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/4.png" alt="team member">

                        <div class="team-social">
                            <a href="#" target="_blank"><i class="icofont-facebook"></i></a>
                            <a href="#" target="_blank"><i class="icofont-twitter"></i></a>
                            <a href="#" target="_blank"><i class="icofont-linkedin"></i></a>
                            <a href="#" target="_blank"><i class="icofont-instagram"></i></a>
                        </div>
                    </div>

                    <div class="team-text">
                        <h4>Eve</h4>
                        <p>Assistant</p>
                    </div>
                </div>
            </div> 
        </div>
    </div>
</section>

<div class="testimonial-section testimonial-bg ptb-100">
    <div class="testimonial-area">
        <div class="container">
            <div class="testimonial-slider owl-carousel owl-theme text-center">
                <div class="sin-testiText">
                    <h2>Stepheny Mc.man</h2>
                    <span>Co-worker of 3S</span>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do is ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices . Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                </div>

                <div class="sin-testiText">
                    <h2>Jackson</h2>
                    <span>Graphics Designer of 3S</span>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do is ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices . Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                </div>

                <div class="sin-testiText">
                    <h2>Amelia</h2>
                    <span>Web Developer of 3S</span>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do is ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices . Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="blog-section pt-100">
    <div class="container">
        <div class="section-head text-center">
            <h2>Latest <span>Blogs</span></h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore et dolore magna aliqua.</p>
        </div>

        <div class="blog-slider owl-carousel owl-theme">
            <div class="blog-area">
                <div class="blog-img">
                    <a routerLink="/blog-details" class="d-block"><img src="assets/img/blog/1.png" alt="blog image"></a>
                </div>

                <div class="blog-text">
                    <h4><a routerLink="/blog-details">How to cleanroom yourself</a></h4>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2020</li>
                        <li><i class="icofont-ui-user"></i> <a routerLink="/">John Doe</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a routerLink="/blog-details" class="blog-btn">Read More <i class="icofont-rounded-right"></i></a>
                </div>
            </div>

            <div class="blog-area">
                <div class="blog-img">
                    <a routerLink="/blog-details" class="d-block"><img src="assets/img/blog/2.png" alt="blog image"></a>
                </div>

                <div class="blog-text">
                    <h4><a routerLink="/blog-details">Enjoy the working time</a></h4>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2020</li>
                        <li><i class="icofont-ui-user"></i> <a routerLink="/">John Doe</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a routerLink="/blog-details" class="blog-btn">Read More <i class="icofont-rounded-right"></i></a>
                </div>
            </div>

            <div class="blog-area">
                <div class="blog-img">
                    <a routerLink="/blog-details" class="d-block"><img src="assets/img/blog/3.png" alt="blog image"></a>
                </div>

                <div class="blog-text">
                    <h4><a routerLink="/blog-details">Self cleaning is the best drill</a></h4>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2020</li>
                        <li><i class="icofont-ui-user"></i> <a routerLink="/">John Doe</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a routerLink="/blog-details" class="blog-btn">Read More <i class="icofont-rounded-right"></i></a>
                </div>
            </div>

            <div class="blog-area">
                <div class="blog-img">
                    <a routerLink="/blog-details" class="d-block"><img src="assets/img/blog/1.png" alt="blog image"></a>
                </div>

                <div class="blog-text">
                    <h4><a routerLink="/blog-details">How to cleanroom yourself</a></h4>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2020</li>
                        <li><i class="icofont-ui-user"></i> <a routerLink="/">John Doe</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a routerLink="/blog-details" class="blog-btn">Read More <i class="icofont-rounded-right"></i></a>
                </div>
            </div>

            <div class="blog-area">
                <div class="blog-img">
                    <a routerLink="/blog-details" class="d-block"><img src="assets/img/blog/2.png" alt="blog image"></a>
                </div>

                <div class="blog-text">
                    <h4><a routerLink="/blog-details">Enjoy the working time</a></h4>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2020</li>
                        <li><i class="icofont-ui-user"></i> <a routerLink="/">John Doe</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a routerLink="/blog-details" class="blog-btn">Read More <i class="icofont-rounded-right"></i></a>
                </div>
            </div>

            <div class="blog-area">
                <div class="blog-img">
                    <a routerLink="/blog-details" class="d-block"><img src="assets/img/blog/3.png" alt="blog image"></a>
                </div>

                <div class="blog-text">
                    <h4><a routerLink="/blog-details">Self cleaning is the best drill</a></h4>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2020</li>
                        <li><i class="icofont-ui-user"></i> <a routerLink="/">John Doe</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a routerLink="/blog-details" class="blog-btn">Read More <i class="icofont-rounded-right"></i></a>
                </div>
            </div>

            <div class="blog-area">
                <div class="blog-img">
                    <a routerLink="/blog-details" class="d-block"><img src="assets/img/blog/1.png" alt="blog image"></a>
                </div>

                <div class="blog-text">
                    <h4><a routerLink="/blog-details">How to cleanroom yourself</a></h4>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2020</li>
                        <li><i class="icofont-ui-user"></i> <a routerLink="/">John Doe</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a routerLink="/blog-details" class="blog-btn">Read More <i class="icofont-rounded-right"></i></a>
                </div>
            </div>

            <div class="blog-area">
                <div class="blog-img">
                    <a routerLink="/blog-details" class="d-block"><img src="assets/img/blog/2.png" alt="blog image"></a>
                </div>

                <div class="blog-text">
                    <h4><a routerLink="/blog-details">Enjoy the working time</a></h4>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2020</li>
                        <li><i class="icofont-ui-user"></i> <a routerLink="/">John Doe</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a routerLink="/blog-details" class="blog-btn">Read More <i class="icofont-rounded-right"></i></a>
                </div>
            </div>

            <div class="blog-area">
                <div class="blog-img">
                    <a routerLink="/blog-details" class="d-block"><img src="assets/img/blog/3.png" alt="blog image"></a>
                </div>

                <div class="blog-text">
                    <h4><a routerLink="/blog-details">Self cleaning is the best drill</a></h4>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2020</li>
                        <li><i class="icofont-ui-user"></i> <a routerLink="/">John Doe</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a routerLink="/blog-details" class="blog-btn">Read More <i class="icofont-rounded-right"></i></a>
                </div>
            </div>
        </div>                 
    </div>
</section>