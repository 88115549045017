import { Component, Input, OnInit, OnDestroy, HostListener } from '@angular/core';
import { CarouselItem } from './carousel.models';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit, OnDestroy {
  @Input() items: CarouselItem[] = [
    {
      image: '../../../../assets/img/module-website-ban-hang-tkwr-9.png',
      title: 'Phong cảnh núi non',
      description: 'Khám phá vẻ đẹp hùng vĩ của thiên nhiên'
    },
    {
      image: 'https://picsum.photos/id/1015/800/400',
      title: 'Rừng sương mù',
      description: 'Bí ẩn và quyến rũ trong màn sương sớm'
    },
    {
      image: 'https://picsum.photos/id/1019/800/400',
      title: 'Bình minh trên biển',
      description: 'Khoảnh khắc bình yên khi ngày mới bắt đầu'
    },
    {
      image: 'https://picsum.photos/id/1016/800/400',
      title: 'Thác nước hùng vĩ',
      description: 'Sức mạnh và vẻ đẹp của dòng chảy tự nhiên'
    },
    {
      image: 'https://picsum.photos/id/1020/800/400',
      title: 'Gấu trong tự nhiên',
      description: 'Cuộc sống hoang dã của loài gấu nâu'
    },
    {
      image: 'https://picsum.photos/id/1021/800/400',
      title: 'Rừng mùa thu',
      description: 'Sắc màu rực rỡ của lá cây mùa thu'
    },
    {
      image: 'https://picsum.photos/id/1022/800/400',
      title: 'Bắc cực quang',
      description: 'Hiện tượng thiên nhiên kỳ diệu trên bầu trời đêm'
    },
    {
      image: 'https://picsum.photos/id/1023/800/400',
      title: 'Động vật hoang dã',
      description: 'Vẻ đẹp và sự đa dạng của thế giới động vật'
    },
    {
      image: 'https://picsum.photos/id/1024/800/400',
      title: 'Đồng cỏ xanh',
      description: 'Cảnh quan yên bình của vùng nông thôn'
    },
    {
      image: 'https://picsum.photos/id/1025/800/400',
      title: 'Chim cánh cụt',
      description: 'Cuộc sống thú vị của loài chim không biết bay'
    }
  ];
  @Input() autoplay: boolean = false;
  @Input() interval: number = 5000; // Mặc định là 5 giây
  @Input() showIndicators: boolean = true; // Mặc định là không show
  @Input() itemsPerSlide: number = 2; // Số lượng item mặc định trên mỗi slide
  @Input() itemsPerSlideMobile: number = 1; // Số lượng item trên mỗi slide cho mobile
  @Input() isShowTitle: boolean = true;
  @Input() isShowDescription: boolean = true;
  @Input() isShowButtonNextPrev: boolean = true;



  slides: CarouselItem[][] = [];
  currentIndex: number = 0;
  private autoplayInterval: any;
  isMobile: boolean = false;

  constructor(private sanitizer: DomSanitizer) {}
  sanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenSize();
  }

  ngOnInit(): void {
    this.checkScreenSize();
    this.createSlides();
    if (this.autoplay) {
      this.startAutoplay();
    }
  }

  ngOnDestroy(): void {
    this.stopAutoplay();
  }

  checkScreenSize() {
    this.isMobile = window.innerWidth < 768;
    this.createSlides();
  }

  createSlides(): void {
    this.slides = [];
    const itemsPerSlide = this.isMobile ? this.itemsPerSlideMobile : this.itemsPerSlide;
    for (let i = 0; i < this.items.length; i += itemsPerSlide) {
      this.slides.push(this.items.slice(i, i + itemsPerSlide));
    }
    // Đảm bảo currentIndex vẫn hợp lệ sau khi tạo lại slides
    this.currentIndex = Math.min(this.currentIndex, this.slides.length - 1);
  }

  startAutoplay(): void {
    this.autoplayInterval = setInterval(() => {
      this.next();
    }, this.interval);
  }

  stopAutoplay(): void {
    if (this.autoplayInterval) {
      clearInterval(this.autoplayInterval);
    }
  }

  next(): void {
    this.currentIndex = (this.currentIndex + 1) % this.slides.length;
  }

  prev(): void {
    this.currentIndex = (this.currentIndex - 1 + this.slides.length) % this.slides.length;
  }

  goToSlide(index: number): void {
    this.currentIndex = index;
  }

  onMouseEnter(): void {
    if (this.autoplay) {
      this.stopAutoplay();
    }
  }

  onMouseLeave(): void {
    if (this.autoplay) {
      this.startAutoplay();
    }
  }
}