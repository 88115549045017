<div class="contact-section">
    <div class="container">
        <div class="contact-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="contact-img">
                        <img src="assets/img/contact.png" alt="contact image">
                    </div>
                </div>

                <div class="col-lg-6 col-md-6">
                    <div class="contact-text">
                        <div class="section-head">
                            <h2>Để lại thông tin của bạn nhé: </h2>
                            <p>Chúng tôi sẽ liên hệ lại ngay ♥</p>
                        </div>

                        <div class="contact-form">
                            <form id="contactForm">
                                <div class="row">
                                    <div class="col-md-12 col-sm-6">
                                        <div class="form-group mb-3">
                                            <input type="text" name="name" id="name" class="form-control"
                                                [(ngModel)]="ThongTinKhachHang.FullName" placeholder="Tên của bạn">
                                        </div>
                                    </div>

                                    <div class="col-md-12 col-sm-6">
                                        <div class="form-group mb-3">
                                            <input type="email" name="email" id="phone" class="form-control"
                                                [(ngModel)]="ThongTinKhachHang.PhoneNumber"
                                                placeholder="Số điện thoại của bạn">
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group mb-3">
                                            <textarea name="message" class="form-control" id="note" cols="30"
                                                [(ngModel)]="ThongTinKhachHang.Note" rows="5"
                                                placeholder="Ghi chú"></textarea>
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-12">
                                        <!-- <button type="submit" class="default-btn page-btn">Send Message</button> -->
                                        <button (click)="LuuThongTinKhachHang()" *ngIf="!LodingLuuThongTinKhachHang">
                                            <div class="svg-wrapper-1">
                                                <div class="svg-wrapper">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                                        width="24" height="24">
                                                        <path fill="none" d="M0 0h24v24H0z"></path>
                                                        <path fill="currentColor"
                                                            d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z">
                                                        </path>
                                                    </svg>
                                                </div>
                                            </div>
                                            <span>Gửi</span>
                                        </button>
                                        <div class="loader" *ngIf="LodingLuuThongTinKhachHang">
                                            <div class="circle">
                                                <div class="dot"></div>
                                                <div class="outline"></div>
                                            </div>
                                            <div class="circle">
                                                <div class="dot"></div>
                                                <div class="outline"></div>
                                            </div>
                                            <div class="circle">
                                                <div class="dot"></div>
                                                <div class="outline"></div>
                                            </div>
                                            <div class="circle">
                                                <div class="dot"></div>
                                                <div class="outline"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="footer-widget">
                    <div class="logo">
                        <a routerLink="/">
                            <img src="assets/img/logo-footer.png" alt="logo">
                        </a>
                    </div>
                    <p>Niềm tin tạo giá trị - Đổi mới tạo thành công.</p>

                    <div class="email">
                        <form class="newsletter-form">
                            <input type="email" id="email" class="form-control" placeholder="Nhập email của bạn"
                                name="EMAIL" [(ngModel)]="EmailKhachHang">
                            <button class="default-btn electronics-btn" type="submit"
                                (click)="LuuThongTinKhachHangEmail()"><i class="icofont-location-arrow"></i></button>
                        </form>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="footer-widget pl-40">
                    <h3>Dịch vụ</h3>
                    <ul>
                        <li><i class="icofont-simple-right"></i> <a routerLink="/service-details">Thiết kế website</a>
                        </li>
                        <li><i class="icofont-simple-right"></i> <a routerLink="/service-details">Quảng cáo
                                Faceebook</a></li>
                        <li><i class="icofont-simple-right"></i> <a routerLink="/service-details">Quảng cáo Google</a>
                        </li>
                        <li><i class="icofont-simple-right"></i> <a routerLink="/service-details">Quảng cáo Tiktok</a>
                        </li>
                        <li><i class="icofont-simple-right"></i> <a routerLink="/service-details">Thiết kế nhận diện
                                thương hiệu</a></li>
                        <li><i class="icofont-simple-right"></i> <a routerLink="/service-details">Xây dựng Fanpage</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="footer-widget pl-40">
                    <h3>Menu</h3>
                    <ul>
                        <li><i class="icofont-simple-right"></i> <a routerLink="/index">Giới thiệu</a></li>
                        <li><i class="icofont-simple-right"></i> <a routerLink="/gioi-thieu">Digital marketing </a></li>
                        <li><i class="icofont-simple-right"></i> <a routerLink="/tin-tuc">Giải pháp công nghệ </a></li>
                        <li><i class="icofont-simple-right"></i> <a routerLink="/team">Dịch vụ thiết kế </a></li>
                        <li><i class="icofont-simple-right"></i> <a routerLink="/tin-tuc">Tin tức</a></li>
                        <li><i class="icofont-simple-right"></i> <a routerLink="/testimonial">Liên hệ</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="footer-widget">
                    <h3>
                        Thông tin liên hệ
                    </h3>
                    <!-- <p class="find-text">Niềm tin tạo giá trị -  Đổi mới tạo thành công.</p> -->

                    <ul class="find-us">
                        <li>
                            <i class="icofont-location-pin"></i> Bạch Đằng, Tiên Lãng, Hải Phòng
                        </li>
                        <li>
                            <i class="icofont-phone"></i>
                            <a href="tel:+84968630869">0968.630.869</a>
                        </li>
                        <li>
                            <i class="icofont-ui-message"></i>
                            <a href="newtrust.contact@gmail.com"> newtrust.contact@gmail.com</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="bottom-footer">
        <div class="container">
            <div class="row  align-items-center ">
                <div class="col-lg-6">
                    <div class="footer-social">
                        <ul>
                            <li><a href="https://www.facebook.com/newtrust.vn?gidzl=j_z21jrMvsosu4S0taxcQRRcH0J2HkrleEr4Lvb7vZhqkqHGcHNaEAUqHWB5Jk0zhR14NpXQc95RtrFbQ0"
                                    target="_blank"><i class="icofont-facebook"
                                        style="background-color: #0671e1;"></i></a></li>
                            <li><a href="https://www.youtube.com/@NewtrustOffical" target="_blank"><i
                                        class="icofont-youtube" style="background-color: red;"></i></a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="copyright-text text-end">
                        <p>&copy;Copyright © 2024 Newtrust.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>


<a class="contact-btn ripple-effect zalo" target="_blank" href="https://zalo.me/0968630869">
    <img src="../../../../assets/img/zalo-icon.png" alt="">
</a>

<a class="contact-btn ripple-effect PhoneNumber" href="tel:+84968630869">
    <img src="../../../../assets/img/phone-call.png" alt="">
</a>

<div class="top-btn">
    <i class="icofont-scroll-long-up"></i>
</div>