import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-three',
  templateUrl: './home-three.component.html',
  styleUrls: ['./home-three.component.scss']
})
export class HomeThreeComponent implements OnInit {
listHopTac = [
  {
    url:"../../../../assets/img/boyue-no-bg-2.png"
  },
  {
    url:"../../../../assets/img/cropped-lo.png"
  },
  {
    url:"../../../../assets/img/z5907901449700_91ea9c51913169e7ad272438ac312ebc.jpg"
  },
  {
    url:"../../../../assets/img/kia.png"
  },
  {
    url:"../../../../assets/img/logo-toyota-inkythuatso-3-01-11-15-32-59.jpg"
  },
  {
    url:"../../../../assets/img/images.jfif"
  },
  {
    url:"../../../../assets/img/images (1).jfif"
  },
]
  constructor() { }

  ngOnInit(): void {
  }

}
