import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-thiet-ke-bo-nhan-dien-thuong-hieu',
  templateUrl: './thiet-ke-bo-nhan-dien-thuong-hieu.component.html',
  styleUrls: ['./thiet-ke-bo-nhan-dien-thuong-hieu.component.scss']
})
export class ThietKeBoNhanDienThuongHieuComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
