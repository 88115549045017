<!-- <div class="header-section">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-8 col-md-12">
                <div class="header-widget">
                    <ul>
                        <li><i class="icofont-clock-time"></i> Thứ 2 - Thứ 6 : 08:00 - 17:00</li>
                        <li><i class="icofont-location-pin"></i> Bạch Đằng, Tiên Lãng, Hải Phòng</li>
                        <li><i class="icofont-phone"></i> <a href="tel:+880123456789">0968.630.869</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-md-4">
                <div class="header-social text-end">
                    <ul>
                        <li><a target="_blank" href="https://www.facebook.com/newtrust.vn?gidzl=j_z21jrMvsosu4S0taxcQRRcH0J2HkrleEr4Lvb7vZhqkqHGcHNaEAUqHWB5Jk0zhR14NpXQc95RtrFbQ0"><i class="icofont-facebook"></i></a></li>
                        <li><a target="_blank" href="https://www.youtube.com/@NewtrustOffical"><i class="icofont-youtube"></i></a></li>
                    </ul>
                </div>
            </div>              
        </div>
    </div>
</div> -->

<div class="navbar-area">
    <div class="mobile-nav">
        <a routerLink="/lien-he" class="logo">
            <img src="assets/img/logo.png" alt="logo">
        </a>
    </div>

    <div class="main-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" href="index.html">
                    <img src="assets/img/logo.png" alt="logo" class="responsive-img">

                </a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav ms-auto">
                        <!-- <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Trang chủ <i class="icofont-rounded-right"></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Page 1</a></li>

                                <li class="nav-item"><a routerLink="/home-two" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Page 2</a></li>

                                <li class="nav-item"><a routerLink="/home-three" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Page 3</a></li>
                            </ul>
                        </li> -->

                        <li class="nav-item"><a routerLink="/gioi-thieu" class="nav-link dropdown-toggle" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Giới thiệu</a></li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle" routerLinkActive="active"[routerLinkActiveOptions]="{exact: false}">Digital marketing <i class="icofont-rounded-right"></i></a>

                            <ul class="dropdown-menu">
                                <!-- <li class="nav-item"><a routerLink="/service" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Service one</a></li>

                                <li class="nav-item"><a routerLink="/service-two" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Services two</a></li>

                                <li class="nav-item"><a routerLink="/service-three" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Service three</a></li>

                                <li class="nav-item"><a routerLink="/service-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Service details</a></li> -->

                                <li class="nav-item"><a routerLink="/facebook-ads" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Facebook Ads</a></li>
                                <li class="nav-item"><a routerLink="/google-ads" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Google Ads</a></li>
                                <li class="nav-item"><a routerLink="/tiktok-ads" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Tiktok Ads</a></li>
                                <li class="nav-item"><a routerLink="/xay-dung-kenh-tiktok" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Xây dựng kênh Tiktok</a></li>
                                <li class="nav-item"><a routerLink="/xay-dung-fanpage" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Xây dựng fanpage</a></li>
                                <li class="nav-item"><a routerLink="/live-stream-chuyen-nghiep" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Livestream chuyên nghiệp</a></li>
                            </ul>
                        </li>


                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Giải pháp công nghệ <i class="icofont-rounded-right"></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/thiet-ke-website" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Thiết kế website</a></li>
                                <li class="nav-item"><a routerLink="/thiet-ke-ung-dung-di-dong" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Thiết kế ứng dụng di động</a></li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Dịch vụ thiết kế <i class="icofont-rounded-right"></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/thiet-ke-logo" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Thiết kế logo</a></li>
                                <li class="nav-item"><a routerLink="/thiet-ke-bo-nhan-dien-thuong-hieu" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Thiết kế bộ nhận diện thương hiệu</a></li>
                                <li class="nav-item"><a routerLink="/thiet-ke-poster-to-roi" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Thiết kế poster, tờ rơi</a></li>
                                <li class="nav-item"><a routerLink="/thiet-ke-ho-so-nang-luc-catalog" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Thiết kế hồ sơ năng lực, catalogue</a></li>
                                <li class="nav-item"><a routerLink="/thiet-ke-bien-quang-cao" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Thiết kế biển quảng cáo</a></li>
                            
                            </ul>
                        </li>


                        <!-- <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Blog <i class="icofont-rounded-right"></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/blog" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog</a></li>

                                <li class="nav-item"><a routerLink="/blog-right-sidebar" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Right sidebar</a></li>

                                <li class="nav-item"><a routerLink="/blog-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog details</a></li>
                            </ul>
                        </li> -->

                        <!-- <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Pages <i class="icofont-rounded-right"></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/pricing" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Pricing</a></li>

                                <li class="nav-item"><a routerLink="/recent-project" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Recent Project</a></li>

                                <li class="nav-item"><a routerLink="/error" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">404 Page</a></li>
                            </ul>
                        </li> -->
<!-- 
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Team <i class="icofont-rounded-right"></i></a>
                            
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/team" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Team</a></li>

                                <li class="nav-item"><a routerLink="/team-two" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Team two</a></li>
                            </ul>
                        </li> -->
                        <li class="nav-item"><a routerLink="/tin-tuc" class="nav-link dropdown-toggle" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Tin tức</a></li>

                        <li class="nav-item"><a routerLink="/lien-he" class="nav-link dropdown-toggle" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Liên hệ</a></li>
                    </ul>

                    <!-- <div class="navbar-button">
                        <a routerLink="/contact">Get Quote</a>
                    </div> -->
                </div>
            </nav>
        </div>
    </div>
</div>