<div class="blog-title blog-title-bg bg-2 bg-google-ads">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="blog-title-text">
                    <h2>Tiktok Ads</h2>
                    <ul>
                        <li>
                            <a routerLink="/index">Home</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            <a routerLink="/tiktok-ads">Tiktok Ads</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container">
    <div class="row">
        <div class="col-lg-12">
            <div class="google-ads-content d-flex justify-content-start align-items-center flex-wrap">
                <div class="google-ads-content-item" *ngFor="let item of listFunction"
                    [ngClass]="{'active': item.active}" (click)="handleClickViewAction(item.id)">
                    <div class="item-background"></div>
                    <div class="item-content">{{item.title}}</div>
                </div>
            </div>
        </div>
    </div>
</div>


<div *ngIf="idActionTab == 1" @expandCollapse>
    <div class="container-fluid wrapper-quang-cao-google bg-google-ads-color">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <h2 class="line-before text-md-32 mb-20 text-white">Quảng Cáo TikTok Chuyên Nghiệp Cùng Newtrust
                    </h2>
                    <div class="text-white text-justify text-quang-cao-google">
                        <p>Giải pháp tiếp thị trực tuyến số 1 trên nền tảng smartphone</p>
                        <p>Lan tỏa câu chuyện thương hiệu đến các đối tượng trên toàn cầu</p>
                        <p>
                            Kết nối thương hiệu nhanh nhất với đối tượng yêu thích khám phá và cập nhật các xu thế mới
                        </p>
                        <a class="btn-lien-he-google  mb-3" routerLink="/lien-he">Liên hệ ngay</a>
                    </div>
                </div>
                <div class="col-lg-6">
                    <img src="../../../../../assets/img/tai-khoan-qc-tiktok-ads-viet-tra-sau-bao-camp_102308.png"
                        width="550px" height="320px" alt="" srcset="" class="w-100">
                </div>
            </div>
        </div>
    </div>


    <div class="container mt-5">
        <div class="row">
            <div class="col-lg-6 d-flex justify-content-center ">
                <img src="../../../../../assets/img/phone-1627527590.png" alt="">
            </div>

            <div class="col-lg-6 d-flex justify-content-center align-items-center">
                <div>
                    <h2>Quảng cáo TikTok là gì?</h2>
                    <p>TikTok là mạng xã hội cho phép người dùng tạo, chỉnh sửa hiệu ứng, âm nhạc cho video ngắn qua
                        smartphone và theo dõi, tương tác với các video dọc có chủ đề và nội dung khác nhau.</p>
                </div>
            </div>
        </div>
    </div>

    <div class="container mt-5">
        <div class="row">
            <div class="col-12 d-flex justify-content-center flex-column align-items-center ">
                <h3 class="mb-2">Tại sao doanh nghiệp nên chạy quảng cáo TikTok?</h3>
                <p class="mb-2">Vài năm gần đây, TikTok có tốc độ phát triển thần tốc</p>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4" style="margin-bottom: 24px;"
                *ngFor="let item of listTaiSaoDoanhNghiepCanQuangCaoTiktok">
                <div class="wrap-tai-sao-can-tiktok d-flex flex-column align-items-center p-5 ">
                    <div class="title-tai-sao-can-tik-tok text-center">
                        {{item.title}}
                    </div>
                    <div class="des-tai-sao-can-tik-tok text-center  ">
                        {{item.des}}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container mt-5">
        <div class="row ">
            <div class="col-lg-6 ">
                <div class="text-justify">
                    <p>Ở Việt Nam, TikTok là ứng dụng được tải nhiều nhất tại Việt Nam (năm 2020) và đã có hơn 12 triệu
                        người đăng ký, trong đó nhóm tuổi sử dụng nhiều nhất là 18 - 34 tuổi.</p>
                    <p>Đây là nhóm tuổi có lối sống hiện đại, có sự nhiệt tình và đam mê. Họ thích cập nhật và khám phá
                        những xu thế mới trên toàn thế giới, những nội dung dựa trên sở thích và thói quen xem.</p>
                    <p>TikTok mang lại sự tương tác cao khi các thương hiệu có thể chuyển đổi người xem thành đại sứ
                        thương
                        hiệu thông qua sức mạnh của việc chia sẻ trải nghiệm bằng video.</p>
                    <p class="mb-20">Qua đó, doanh nghiệp sẽ nhận được nhiều lợi ích khi triển khai chiến dịch quảng cáo
                        TikTok đúng hướng: thống trị người xem, chuyển đổi họ thành khách hàng và đạt được tốc độ tăng
                        trưởng nhanh...</p>
                    <p class="mb-20"><strong>Bạn đã sẵn sàng thúc đẩy sự phát triển của doanh nghiệp trực tuyến cùng BIN
                            Media và TikTok Ads?</strong></p>
                    <a class="btn-lien-he-google mb-3"
                        style="background-color: #D6343D !important; color: #fff !important;" routerLink="/lien-he">Liên
                        hệ ngay</a>
                </div>
            </div>
            <div class="col-lg-6 d-flex align-items-center justify-content-center ">
                <img src="../../../../../assets/img/bg-1627530557.png" alt="">
            </div>
        </div>
    </div>

    <div class="container-fluid  wrap-google-sosanh">
        <div class="container">
            <div class="row text-center title-google-tien-hanh">
                <h2>Sơ bộ về cách tính phí</h2>
            </div>
            <div class="row">
                <div class="col-lg-3 wrap-item-bang-gia" *ngFor="let item of listQunagCaoTikTokgiupgi">
                    <div class="">
                        <div class="wrapper-google-ads-help-item-2-item-content pl-0 pr-0"
                            style="padding: 0 !important;">
                            <img src="{{item.urlImg}}" alt="">
                            <h4>{{item.title}}</h4>
                            <div>{{item.description}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container mt-5">
        <div class="row">
            <h2 class="text-center">Quy trình thực hiện quảng cáo TikTok của NEWTRUST Media</h2>
            <div class="row mb-80 mb-xs-40">
                <div class="col-lg-6 order-lg-2 mb-xs-20"><img
                        data-src="https://d32q3bqti6sa3p.cloudfront.net/uploads/bg-step-1-1627532050.png"
                        src="https://d32q3bqti6sa3p.cloudfront.net/uploads/bg-step-1-1627532050.png"
                        alt="Doanh nghiệp tạo TikTok Official Account và chuẩn bị các video cần chạy quảng cáo"
                        title="Doanh nghiệp tạo TikTok Official Account và chuẩn bị các video cần chạy quảng cáo"
                        class=" lazyloaded"></div>
                <div class="col-lg-6 order-lg-1 d-flex align-items-center">
                    <div>
                        <h3 class="text-pink-tt mb-0">Bước 1</h3> <img
                            src="https://d32q3bqti6sa3p.cloudfront.net/uploads/title-decor-1627531733.svg" alt="Bước 1"
                            title="Bước 1">
                        <p class="text-18 text-lg-24"><strong>Doanh nghiệp tạo TikTok Official Account và chuẩn bị các
                                video
                                cần chạy quảng cáo</strong></p>
                    </div>
                </div>
            </div>
            <div class="row mb-80 mb-xs-40">
                <div class="col-lg-6"><img
                        data-src="https://d32q3bqti6sa3p.cloudfront.net/uploads/bg-step-2-1627532336.png"
                        src="https://d32q3bqti6sa3p.cloudfront.net/uploads/bg-step-2-1627532336.png"
                        alt="Đội ngũ chuyên viên của NEWTRUST Media tìm hiểu nhu cầu và tư vấn cho bạn hướng quảng cáo phù hợp với đối tượng mục tiêu và ngân sách của doanh nghiệp bạn"
                        title="Đội ngũ chuyên viên của NEWTRUST Media tìm hiểu nhu cầu và tư vấn cho bạn hướng quảng cáo phù hợp với đối tượng mục tiêu và ngân sách của doanh nghiệp bạn"
                        class=" lazyloaded"></div>
                <div class="col-lg-6 d-flex align-items-center">
                    <div>
                        <h3 class="text-pink-tt mb-0">Bước 2</h3> <img
                            src="https://d32q3bqti6sa3p.cloudfront.net/uploads/title-decor-1627531733.svg" alt="Bước 2"
                            title="Bước 2">
                        <p class="text-18 text-lg-24"><strong>Đội ngũ chuyên viên của NEWTRUST Media tìm hiểu nhu cầu và tư
                                vấn
                                cho bạn hướng quảng cáo phù hợp với đối tượng mục tiêu và ngân sách của doanh nghiệp
                                bạn</strong></p>
                    </div>
                </div>
            </div>
            <div class="row mb-80 mb-xs-40">
                <div class="col-lg-6 order-lg-2 mb-xs-20 d-flex align-items-center"><img
                        data-src="https://d32q3bqti6sa3p.cloudfront.net/uploads/bg-step-3-1627532509.png"
                        src="https://d32q3bqti6sa3p.cloudfront.net/uploads/bg-step-3-1627532509.png"
                        alt="NEWTRUST Media triển khai chiến dịch quảng cáo nhắm ĐÚNG mục tiêu và mang lại HIỆU QUẢ"
                        title="NEWTRUST Media triển khai chiến dịch quảng cáo nhắm ĐÚNG mục tiêu và mang lại HIỆU QUẢ"
                        class=" lazyloaded"></div>
                <div class="col-lg-6 order-lg-1">
                    <h3 class="text-pink-tt mb-0">Bước 3</h3> <img
                        src="https://d32q3bqti6sa3p.cloudfront.net/uploads/title-decor-1627531733.svg" alt="Bước 3"
                        title="Bước 3">
                    <p class="text-18 text-lg-24 mb-20"><strong>NEWTRUST Media triển khai chiến dịch quảng cáo nhắm <span
                                class="text-pink-tt">ĐÚNG</span> mục tiêu và mang lại <span class="text-pink-tt">HIỆU
                                QUẢ</span></strong></p>
                    <ul class="list-arrow-lg list-unstyled mb-0 text-black d-flex flex-column ">
                        <li style="margin-bottom: 10px;"> <img style="margin-right: 10px;"
                                src="../../../../../assets/img/icon-arrow-right-lg.svg" alt=""> Chọn mục tiêu</li>
                        <li style="margin-bottom: 10px;"> <img style="margin-right: 10px;"
                                src="../../../../../assets/img/icon-arrow-right-lg.svg" alt=""> Chọn đối tượng</li>
                        <li style="margin-bottom: 10px;"> <img style="margin-right: 10px;"
                                src="../../../../../assets/img/icon-arrow-right-lg.svg" alt=""> Thiết lập ngân sách</li>
                        <li style="margin-bottom: 10px;"> <img style="margin-right: 10px;"
                                src="../../../../../assets/img/icon-arrow-right-lg.svg" alt=""> Thiết kế quảng cáo</li>
                        <li style="margin-bottom: 10px;"> <img style="margin-right: 10px;"
                                src="../../../../../assets/img/icon-arrow-right-lg.svg" alt=""> Triển khai</li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6"><img
                        data-src="https://d32q3bqti6sa3p.cloudfront.net/uploads/bg-step-4-1627533159.png"
                        src="https://d32q3bqti6sa3p.cloudfront.net/uploads/bg-step-4-1627533159.png"
                        alt="Chúng tôi quản lý, đo lường và tối ưu hiệu quả của quảng cáo TikTok cho bạn."
                        title="Chúng tôi quản lý, đo lường và tối ưu hiệu quả của quảng cáo TikTok cho bạn."
                        class=" ls-is-cached lazyloaded"></div>
                <div class="col-lg-6 d-flex align-items-center">
                    <div>
                        <h3 class="text-pink-tt mb-0">Bước 4</h3> <img
                            src="https://d32q3bqti6sa3p.cloudfront.net/uploads/title-decor-1627531733.svg" alt="Bước 4"
                            title="Bước 4">
                        <p class="text-18 text-lg-24"><strong>Chúng tôi quản lý, đo lường và tối ưu hiệu quả của quảng
                                cáo
                                TikTok cho bạn.</strong></p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid  wrap-google-sosanh">
        <div class="container">
            <div class="row text-center title-google-tien-hanh">
                <h2>Dịch vụ quảng cáo TikTok tại NEWTRUST Media đem đến điều gì?</h2>
            </div>
            <div class="row">
                <div class="col-lg-3 wrap-item-bang-gia" *ngFor="let item of listQunagCaoTikTokgiupgiDemLaiGi">
                    <div class="">
                        <div class="wrapper-google-ads-help-item-2-item-content pl-0 pr-0"
                            style="padding: 0 !important;">
                            <img src="{{item.urlImg}}" alt="">
                            <h4>{{item.title}}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="idActionTab ==2" @expandCollapse>
    <div class="container">
        <h1 class="text-center text-dark">Nơi câu chuyện thương hiệu của bạn được tôn vinh <br
                class="d-none d-lg-block"> như một phần của trải nghiệm TikTok.</h1>
        <div class="row mt-60 mt-xs-40">
            <div class="col-lg-6">
                <h2>TOPVIEW</h2>
                <p>Quảng cáo thu hút sự chú ý của người dùng TikTok ngay khi họ mở ứng dụng TikTok. Sau 3s người dùng
                    được phép bấm nút "Bỏ qua quảng cáo". Video TopView hiển thị 100% trong một ngày, đảm bảo hàng triệu
                    lượt xem trong thời gian 24 giờ, mang lại kết quả tương tác và nhận thức thương hiệu đáng kinh ngạc.
                </p>
                <div class="pl-40 pl-xs-5">
                    <ul class="text-gray pl-20 mb-60 mb-xs-40">
                        <li class="mb-10">Thời lượng: 5 - 60 giây (thời lượng lý tưởng: 15 giây)</li>
                        <li class="mb-10">Video dọc màn hình có bật âm thanh</li>
                        <li class="mb-10">Điều hướng người dùng về các trang đích bên trong và bên ngoài TikTok như
                            landing page của thương hiệu, link hastag challenge...</li>
                        <li class="mb-10">Chỉ số đo lường: lượt like, comment, share, follow</li>
                    </ul> <img data-src="https://d32q3bqti6sa3p.cloudfront.net/uploads/phone-1627541238.png"
                        src="https://d32q3bqti6sa3p.cloudfront.net/uploads/sx-1599637799.svg" alt="TOPVIEW"
                        title="TOPVIEW" class="lazyload d-block d-lg-none mx-auto">
                    <h4>MỤC TIÊU</h4>
                    <ul class="list-unstyled list-arrow-tiktok">
                        <li><strong class="text-black">Tăng tương tác:</strong> Khuyến khích lượt thích, bình luận, chia
                            sẻ và theo dõi</li>
                        <li><strong class="text-black">Tăng nhận thức thương hiệu</strong></li>
                        <li><strong class="text-black">Tăng lượt truy cập</strong> vào trang bán hàng</li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 text-center"><img
                    data-src="https://d32q3bqti6sa3p.cloudfront.net/uploads/phone-1627541238.png"
                    src="https://d32q3bqti6sa3p.cloudfront.net/uploads/phone-1627541238.png" alt="TOPVIEW"
                    title="TOPVIEW" class="d-none d-lg-inline-block lazyloaded"></div>
        </div>
        <div class="row mt-60 mt-xs-40">
            <div class="col-lg-6 text-center order-lg-1"><img
                    data-src="https://d32q3bqti6sa3p.cloudfront.net/uploads/phone-2-1627541899.png"
                    src="https://d32q3bqti6sa3p.cloudfront.net/uploads/phone-2-1627541899.png" alt="BRAND TAKEOVER"
                    title="BRAND TAKEOVER" class="d-none d-lg-inline-block lazyloaded"></div>
            <div class="col-lg-6 order-lg-2">
                <h2>BRAND TAKEOVER</h2>
                <p class="mb-0">Quảng cáo xuất hiện ngay khi người dùng khởi động ứng dụng TikTok.</p>
                <p>Gồm 2 dạng:</p>
                <ul class="list-unstyled list-circle-red mb-60 mb-xs-40">
                    <li><strong class="text-danger text-18">First-view Takeover</strong> <br> Quảng cáo sẽ được hiển thị
                        đầu tiên ngay trên giao diện chờ khởi động của TikTok</li>
                    <li><strong class="text-danger text-18">Regular Takeover</strong> <br> Hiển thị ngẫu nhiên bất kỳ
                        lúc nào khi người dùng sử dụng App <ul class="text-gray mt-30">
                            <li class="mb-5px">Thời lượng: 3s cho ảnh tĩnh &amp; từ 3-5s cho video</li>
                            <li class="mb-5px">Điều hướng người dùng về trang bán hàng, liên kết website/ứng dụng của
                                thương hiệu thông qua nút CTA (Call-to-action) hoặc một video khác trên TikTok</li>
                            <li>Chỉ số đo lường: Lượt hiển thị, lượt click, lượt tiếp cận</li>
                        </ul>
                    </li>
                </ul> <img data-src="https://d32q3bqti6sa3p.cloudfront.net/uploads/phone-2-1627541899.png"
                    src="https://d32q3bqti6sa3p.cloudfront.net/uploads/sx-1599637799.svg" alt="BRAND TAKEOVER"
                    title="BRAND TAKEOVER" class="lazyload d-block d-lg-none mx-auto">
                <div class="pl-20">
                    <h4>MỤC TIÊU</h4>
                    <ul class="list-unstyled list-arrow-tiktok">
                        <li><strong class="text-black">Tăng lượt view video</strong> thuộc hashtag challange</li>
                        <li><strong class="text-black">Tăng nhận thức thương hiệu:</strong> thu hút sự chú ý của người
                            dùng thông qua trải nghiệm hình ảnh toàn màn hình, từ đó, đem đến tác động trực quan cho
                            thương hiệu</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="row mt-60 mt-xs-40">
            <div class="col-lg-6">
                <h2>IN-FEED ADS</h2>
                <p>Quảng cáo video bằng cách tích hợp nội dung video vào dòng thời gian "For You" (Dành cho bạn) của
                    người dùng TikTok hoặc được đặt ở cuối video TikTok. Hình thức quảng cáo này có chi phí phải chăng
                    hơn hình thức BrandTakeover, do đó, nó thích hợp cho các thương hiệu vừa và nhỏ. Gồm 2 định dạng:
                </p>
                <ul class="list-unstyled list-circle-red mb-60 mb-xs-40">
                    <li><strong class="text-danger text-18">Direct Native Ad</strong> <br> Mục tiêu tăng lượt xem và
                        tương tác với video ngay trên nền tảng</li>
                    <li><strong class="text-danger text-18">Direct Diversion Ad</strong> <br> Mục tiêu tăng lượng truy
                        cập vào trang chủ <ul class="text-gray mt-30">
                            <li class="mb-5px">Thời lượng: 5-60 giây</li>
                            <li class="mb-5px">Video dọc toàn màn hình, có bật âm thanh</li>
                            <li class="mb-5px">Chỉ số đo lường: lượt hiển thị, lượt xem video, lượt truy cập, lượt cài
                                đặt ứng dụng...</li>
                            <li class="mb-5px">Tùy chọn nhắm mục tiêu chính xác bao gồm sở thích, hành vi dựa trên sự
                                kiện, đối tượng tùy chỉnh và đối tượng giống nhau</li>
                            <li class="mb-5px">Tùy chọn thanh toán, CPM, CPC, CPV và oCPC</li>
                            <li>Điều hướng người dùng về landing page, website của thương hiệu hoặc trang tải ứng dụng
                            </li>
                        </ul>
                    </li>
                </ul> <img data-src="https://d32q3bqti6sa3p.cloudfront.net/uploads/phone-3-1627543227.png"
                    src="https://d32q3bqti6sa3p.cloudfront.net/uploads/sx-1599637799.svg" alt="IN-FEED ADS"
                    title="IN-FEED ADS" class="lazyload d-block d-lg-none mx-auto">
                <div class="pl-20">
                    <h4>MỤC TIÊU</h4>
                    <ul class="list-unstyled list-arrow-tiktok">
                        <li><strong class="text-black">Tăng lưu lượng truy cập</strong> và mục tiêu chuyển đổi</li>
                        <li><strong class="text-black">Tăng nhận thức thương hiệu</strong> thông qua việc kể câu chuyện
                            thương hiệu như một người sáng tạo nội dung</li>
                        <li><strong class="text-black">Tăng đơn hàng</strong> trực tuyến, lượt cài đặt ứng dụng và sự
                            kiện trong ứng dụng thông qua việc người dùng tương tác trực tiếp với quảng cáo qua nút Mua
                            ngay, Tìm hiểu thêm...</li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 text-center"><img
                    data-src="https://d32q3bqti6sa3p.cloudfront.net/uploads/phone-3-1627543227.png"
                    src="https://d32q3bqti6sa3p.cloudfront.net/uploads/phone-3-1627543227.png" alt="IN-FEED ADS"
                    title="IN-FEED ADS" class="d-none d-lg-inline-block lazyloaded"></div>
        </div>
        <div class="row mt-60 mt-xs-40">
            <div class="col-lg-6 text-center order-lg-1"><img
                    data-src="https://d32q3bqti6sa3p.cloudfront.net/uploads/phone-4-1627543532.png"
                    src="https://d32q3bqti6sa3p.cloudfront.net/uploads/phone-4-1627543532.png"
                    alt="BRANDED HASHTAG CHALLENGE" title="BRANDED HASHTAG CHALLENGE"
                    class="d-none d-lg-inline-block lazyloaded"></div>
            <div class="col-lg-6 order-lg-2">
                <h2>BRANDED HASHTAG CHALLENGE</h2>
                <p>Bạn đã sẵn sàng mở rộng độ lan tỏa của một trào lưu? Branded Hashtag Challenge là một định dàng được
                    nhắc đến nhiều nhất của TikTok. Đây là một hình thức quảng cáo TikTok bằng việc tạo ra 1 trào lưu
                    (trend) với 1 chủ đề nào đó và đính kèm thẻ bắt đầu bằng dấu thăng (#).</p>
                <p class="mb-30">Bạn có thể hợp tác với đội ngũ marketing của TikTok để tạo ra 1 hashtag riêng. TikTok
                    còn cho phép các banner giới thiệu về thử thách xuất hiện ở mục TikTok Discover (biểu ngữ trang Khám
                    phá) và thúc đẩy người dùng TikTok tạo ra content để đẩy trend cho hashtag đó.</p>
                <div class="pl-20">
                    <ul class="text-gray pl-20 mb-60 mb-xs-40">
                        <li class="mb-5px">Thời gian: 6 ngày cho chiến dịch đơn lẻ hoặc nhiều thị trường</li>
                        <li class="mb-5px">Khuếch đại với người sáng tạo, có hiệu ứng thương hiệu và âm nhạc riêng</li>
                        <li class="mb-5px">Điều hướng người dùng về trang chủ của thương hiệu hoặc trang danh sách các
                            video tham gia thử thách</li>
                        <li class="mb-5px">Chỉ số đo lường: số lượt view, số lượt nhấp vào banner, lượt xem trang
                            hashtag, số video được tạo và lượt tương tác với video</li>
                    </ul> <img data-src="https://d32q3bqti6sa3p.cloudfront.net/uploads/phone-4-1627543532.png"
                        src="https://d32q3bqti6sa3p.cloudfront.net/uploads/sx-1599637799.svg"
                        alt="BRANDED HASHTAG CHALLENGE" title="BRANDED HASHTAG CHALLENGE"
                        class="lazyload d-block d-lg-none mx-auto">
                    <h4>MỤC TIÊU</h4>
                    <ul class="list-unstyled list-arrow-tiktok">
                        <li><strong class="text-black">Tăng tương tác với quảng cáo:</strong> kích thích niềm đam mê
                            sáng tạo và khát khao thể hiện của người dùng</li>
                        <li><strong class="text-black">Tăng nhận thức thương hiệu</strong> thông qua thử thách mà thương
                            hiệu tạo ra</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="row mt-60 mt-xs-40">
            <div class="col-lg-6">
                <h2>BRANDED EFFECTS</h2>
                <p>Quảng cáo TikTok Hiệu ứng thương hiệu sáng tạo là định dạng khai phá tiềm năng sáng tạo của thương
                    hiệu bằng việc sử dụng các nhãn dán, bộ lọc và các hiệu ứng 2D, 3D, AR được thiết kế riêng. Nó giúp
                    kết nối thương hiệu của bạn liền mạch với những khoảnh khắc đáng nhớ nhất của người dùng.</p>
                <ul class="list-unstyled list-circle-red mb-60 mb-xs-40">
                    <li>Quảng cáo được xuất hiện trong mục “hot” hay “trending” trong 5 ngày đầu ra mắt và kéo dài tối
                        đa 10 ngày.</li>
                    <li>Có các tính năng tương tác bổ sung như CTA (Call-to-action) và mã QR</li>
                </ul> <img data-src="https://d32q3bqti6sa3p.cloudfront.net/uploads/phone-5-1627543929.png"
                    src="https://d32q3bqti6sa3p.cloudfront.net/uploads/sx-1599637799.svg" alt="BRANDED EFFECTS"
                    title="BRANDED EFFECTS" class="lazyload d-block d-lg-none mx-auto">
                <div class="pl-20">
                    <h4>MỤC TIÊU</h4>
                    <ul class="list-unstyled list-arrow-tiktok">
                        <li><strong class="text-black">Nâng cao mức độ tương tác:</strong> thúc đẩy tương tác 2 chiều
                            giữa người dùng và thương hiệu trên quy mô toàn cầu</li>
                        <li><strong class="text-black">Nâng cao nhận thức về thương hiệu</strong></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 text-center"><img
                    data-src="https://d32q3bqti6sa3p.cloudfront.net/uploads/phone-5-1627543929.png"
                    src="https://d32q3bqti6sa3p.cloudfront.net/uploads/phone-5-1627543929.png" alt="BRANDED EFFECTS"
                    title="BRANDED EFFECTS" class="d-none d-lg-inline-block lazyloaded"></div>
        </div>
    </div>

    <div class="container mb-5">
        <h2 class="text-center">Một số điều có thể bạn chưa biết về TikTok Ads?</h2>
        <div class="row mt-60 mt-xs-40">
            <div class="col-lg-6 text-center mb-xs-40"><img
                    data-src="https://d32q3bqti6sa3p.cloudfront.net/uploads/bg-1627544255.png"
                    src="https://d32q3bqti6sa3p.cloudfront.net/uploads/bg-1627544255.png"
                    alt="Một số điều có thể bạn chưa biết về TikTok Ads?"
                    title="Một số điều có thể bạn chưa biết về TikTok Ads?" class=" lazyloaded"></div>
            <div class="col-lg-6 d-flex align-items-center">
                <ul class="list-unstyled list-circle-red text-gray-1">
                    <li style="margin-bottom: 10px;"><img style="margin-right: 10px;"
                            src="../../../../../assets/img/icon-arrow-right-lg.svg" alt="">Tài khoản cá nhân không được
                        tự tạo, triển khai và quản lý các chiến dịch quảng cáo
                    </li>
                    <li style="margin-bottom: 10px;"> <img style="margin-right: 10px;"
                            src="../../../../../assets/img/icon-arrow-right-lg.svg" alt="">Agency chạy quảng cáo và
                        TikTok cùng chịu trách nhiệm thiết lập và tối ưu chiến dịch
                    </li>
                    <li style="margin-bottom: 10px;"> <img style="margin-right: 10px;"
                            src="../../../../../assets/img/icon-arrow-right-lg.svg" alt="">TikTok cam kết tối thiểu
                        500,000 lượt hiển thị/ngày cho các quảng cáo (tùy thuộc vào
                        nhu cầu,
                        mục tiêu và ngân sách của nhà quảng cáo...)</li>
                </ul>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="form-960">
            <h2 class="text-center">So sánh TikTok Ads và nền tảng quảng cáo khác</h2>
            <div class="mt-60 mt-xs-50">
                <table class="table table-borderless table-style-1">
                    <thead>
                        <tr>
                            <th width="45%">&nbsp;</th>
                            <th width="265" class="tb-head tb-head-blue" class="text-center" >TikTok Ads</th>
                            <th width="265" class="tb-head tb-head-gray" class="text-center">Nền tảng quảng cáo khác</th>
                        </tr>
                    </thead>
                    <tbody class="text-gray">
                        <tr>
                            <td class="pt-30">Cách thức nhắm mục tiêu đơn giản</td>
                            <td class="text-center pt-30"><img
                                    src="https://d32q3bqti6sa3p.cloudfront.net/uploads/icon-check-1627551429.svg"
                                    alt="Yes" title="Yes"></td>
                            <td class="text-center pt-30"><img
                                    src="https://d32q3bqti6sa3p.cloudfront.net/uploads/icon-check-1627551429.svg"
                                    alt="Yes" title="Yes"></td>
                        </tr>
                        <tr>
                            <td>Chính sách về các sản phẩm cho phép chạy trên Tiktok tương đồng với Facebook. Nghĩa là
                                những sản phẩm/dịch vụ không được chạy quảng cáo Facebook thì cũng không chạy được trên
                                Tiktok.</td>
                            <td class="text-center"><img
                                    src="https://d32q3bqti6sa3p.cloudfront.net/uploads/icon-check-1627551429.svg"
                                    alt="Yes" title="Yes"></td>
                            <td class="text-center"><img
                                    src="https://d32q3bqti6sa3p.cloudfront.net/uploads/icon-check-1627551429.svg"
                                    alt="Yes" title="Yes"></td>
                        </tr>
                        <tr>
                            <td>Nội dung quảng cáo chỉ từ 5 giây – 60 giây</td>
                            <td class="text-center"><img
                                    src="https://d32q3bqti6sa3p.cloudfront.net/uploads/icon-check-1627551429.svg"
                                    alt="Yes" title="Yes"></td>
                            <td class="text-center"><img
                                    src="https://d32q3bqti6sa3p.cloudfront.net/uploads/icon-cancel-1627552655.svg"
                                    alt="No" title="No"></td>
                        </tr>
                        <tr>
                            <td>Cơ chế tính phí quảng cáo theo CPM (Cost-per-1000 impression)</td>
                            <td class="text-center"><img
                                    src="https://d32q3bqti6sa3p.cloudfront.net/uploads/icon-check-1627551429.svg"
                                    alt="Yes" title="Yes"></td>
                            <td class="text-center"><img
                                    src="https://d32q3bqti6sa3p.cloudfront.net/uploads/icon-cancel-1627552655.svg"
                                    alt="No" title="No"></td>
                        </tr>
                        <tr>
                            <td>Phần lớn đối tượng thích cập nhật và khám phá các xu hướng mới trên toàn cầu</td>
                            <td class="text-center"><img
                                    src="https://d32q3bqti6sa3p.cloudfront.net/uploads/icon-check-1627551429.svg"
                                    alt="Yes" title="Yes"></td>
                            <td class="text-center"><img
                                    src="https://d32q3bqti6sa3p.cloudfront.net/uploads/icon-cancel-1627552655.svg"
                                    alt="No" title="No"></td>
                        </tr>
                        <tr>
                            <td>Kết hợp cơ chế duyệt quảng cáo tự động và thủ công</td>
                            <td class="text-center"><img
                                    src="https://d32q3bqti6sa3p.cloudfront.net/uploads/icon-check-1627551429.svg"
                                    alt="Yes" title="Yes"></td>
                            <td class="text-center"><img
                                    src="https://d32q3bqti6sa3p.cloudfront.net/uploads/icon-cancel-1627552655.svg"
                                    alt="No" title="No"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>


<div class="container-fluid wraper-doi-ngu-tu-van">
    <div class="container">
        <div class="row">
            <div class="col-12 wrap-content-doi-ngu-tu-van">
                <h3>Đội ngũ tư vấn của NEWTRUST</h3>
                <div>Luôn sẵn sàng hỗ trợ bạn mọi lúc mọi nơi</div>
                <a class="btn-lien-he-google" href="tel:+84968630869">Hotline: 0968.630.869</a>
            </div>
        </div>
    </div>
</div>