import { Component, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-google-ads',
  templateUrl: './google-ads.component.html',
  styleUrls: ['./google-ads.component.scss'],
  animations: [
    trigger('expandCollapse', [
      transition(':enter', [
        style({ height: 0, opacity: 0 }),
        animate('500ms ease-out', style({ height: '*', opacity: 1 }))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({  opacity: 0 }))
      ])
    ]),
    trigger('expandCollapse2', [
      transition(':enter', [
        style({ height: 0, opacity: 0 }),
        animate('100ms ease-out', style({ height: '*' }))
      ]),
      transition(':leave', [
        animate('100ms ease-in', style({  height: 0}))
      ])
    ])
  ]
})
export class GoogleAdsComponent implements OnInit {

  constructor() { }
  listFunction = [
    {
      active: true,
      id: 1,
      title: "Tổng quan về Google",
      url: ""
    },
    {
      active: false,
      id: 2,
      title: "Goofle display network",
      url: ""
    },
    {
      active: false,
      id: 3,
      title: "Remarketing",
      url: ""
    },
    {
      active: false,
      id: 4,
      title: "App campaigns",
      url: ""
    },
    {
      active: false,
      id: 5,
      title: "Google shopping",
      url: ""
    },
    // {
    //   active: false,
    //   id: 6,
    //   title: "Báo giá",
    //   url: ""
    // },
    // {
    //   active: false,
    //   id: 7,
    //   title: "Câu hỏi thường gặp",
    //   url: ""
    // }
  ]

  lisThuHutNGuoiDung = [
    {
      id: 1,
      text: "Dễ dàng xác định được khách hàng mục tiêu của bạn thông qua việc người dùng cài đặt ứng dụng hay cần người dùng thực hiện các hành động trong ứng dụng (đặt mua sản phẩm, lên cấp 2,..).",
      bgColor: "#E1E5E9",
      padding: "mb-3"
    },
    {
      id: 2,
      text: "Nội dung quảng cáo đa dạng (hình ảnh hoặc video có kèm 4 dòng văn bản) và có thể xem trước.",
      bgColor: "#F1F5F9",
      padding: "mb-3"
    },
    {
      id: 3,
      text: "Quảng cáo ứng dụng sẽ xoay vòng quảng cáo và điều chỉnh giá thầu một cách tự động để hiển thị quảng cáo cho những người phù hợp với mức giá phù hợp của doanh nghiệp bạn.",
      bgColor: "#E1E5E9",
      padding: "mb-3"
    },
    {
      id: 4,
      text: "Số liệu báo cáo thống kê chi tiết sẽ đánh giá hiệu suất dựa trên mục tiêu của bạn.",
      bgColor: "#F1F5F9",
      padding: ""
    },
  ]

  listChienDichQuangCao = [
    {
      id: 1,
      urlImg: "../../../../../assets/img/icon-thu-hut-them-khach-hang-1600246336.svg",
      title: "Thu hút thêm khách hàng",
      description: "Để lại thông tin như link đăng ký, nút gọi, chỉ đường,... khách hàng sẽ dễ dàng truy cập và xem các thông tin về doanh nghiệp/sản phẩm, thúc đẩy doanh thu cho doanh nghiệp.",
    },
    {
      id: 2,
      urlImg: "../../../../../assets/img/icon-tiep-can-dung-khach-hang-1600238335.svg",
      title: "Tiếp cận đúng khách hàng tiềm năng",
      description: "Khi khách hàng tìm kiếm những sản phẩm/dịch vụ liên quan tới những gì mà công ty đang kinh doanh, quảng cáo sẽ được hiển thị trên máy tính hoặc thiết bị di động.",
    },
    {
      id: 3,
      urlImg: "../../../../../assets/img/icon-lua-chon-dung-pham-vi-thiet-lap-1600238334.svg",
      title: "Lựa chọn đúng phạm vi mà bạn thiết lập",
      description: "Bạn sẽ có quyền thiết lập phạm vi mà quảng cáo hiển thị, giúp tiếp cận chính xác khách hàng mục tiêu.",
    },
    {
      id: 4,
      urlImg: "../../../../../assets/img/icon-ngan-sach-it-hieu-qua-cao-1600238334.svg",
      title: "Ngân sách ít - hiệu quả cao - Chi phí minh bạch",
      description: "Với các chiến dịch trên Google Ads, bạn có thể kiểm soát ngân sách tối ưu nhất bằng cách theo dõi cách thức mà chiến dịch hoạt động cùng với ngân sách đã thiết lập trước.",
    }
  ]

  listCacBuocQuangCao = [
    {
      id: 1,
      buoc: "Bước 1",
      urlImg: "../../../../../assets/img/step1-google-campaign-1599638453.png",
      title: "Nghiên cứu và tư vấn từ khóa",
      description: "Chúng tôi sẽ kiểm tra trang web, tìm những từ khóa liên quan để tạo ra các từ khóa thích hợp dành riêng cho bạn và đề xuất những giải pháp phù hợp với ngân sách và nhu cầu của bạn.",
    },
    {
      id: 2,
      buoc: "Bước 2",
      urlImg: "../../../../../assets/img/step2-google-campaign-1599638454.png",
      title: "Tạo chiến dịch quảng cáo",
      description: "Sau khi bạn hài lòng với từ khóa, chúng tôi sẽ tạo ngay một chiến dịch xuất hiện trên Google. Quảng cáo hiển thị theo từng khu vực, từng quốc gia mà bạn muốn hướng đến.",
    },
    {
      id: 3,
      buoc: "Bước 3",
      urlImg: "../../../../../assets/img/step3-google-campaign-1599638454.png",
      title: "Tối ưu hóa quảng cáo",
      description: "NEWTRUST Media luôn theo dõi quảng cáo giúp bạn từng giờ, ngay lập tức chỉnh sửa thay đổi khi có lỗi để luôn giữ cho bạn mức ngân sách thấp nhất.",
    },
    {
      id: 4,
      buoc: "Bước 4",
      urlImg: "../../../../../assets/img/step4-google-campaign-1599638455.png",
      title: "Báo cáo hiệu suất",
      description: "Bạn sẽ luôn được chúng tôi cập nhật đầy đủ thông tin về hiệu suất chiến dịch từng giai đoạn. Giúp bạn luôn luôn an tâm khi làm việc với chúng tôi.",
    }
  ]

  listCacBuocGoogleShopping = [
    {
      id: 1,
      buoc: "Bước 1",
      urlImg: "../../../../../assets/img/icon-google-shopping-step-1.png",
      title: "Chọn ngành hàng",
      description: "Chúng tôi sẽ kiểm tra trang web, tìm những từ khóa liên quan để tạo ra các từ khóa thích hợp dành riêng cho bạn và đề xuất những giải pháp phù hợp với ngân sách và nhu cầu của bạn.",
    },
    {
      id: 2,
      buoc: "Bước 2",
      urlImg: "../../../../../assets/img/icon-google-shopping-step-2.png",
      title: "Chọn ngân sách",
      description: "Sau khi bạn hài lòng với từ khóa, chúng tôi sẽ tạo ngay một chiến dịch xuất hiện trên Google. Quảng cáo hiển thị theo từng khu vực, từng quốc gia mà bạn muốn hướng đến.",
    },
    {
      id: 3,
      buoc: "Bước 3",
      urlImg: "../../../../../assets/img/icon-google-shopping-step-3.png",
      title: "NEWTRUST tối ưu giá",
      description: "NEWTRUST Media luôn theo dõi quảng cáo giúp bạn từng giờ, ngay lập tức chỉnh sửa thay đổi khi có lỗi để luôn giữ cho bạn mức ngân sách thấp nhất.",
    },
    {
      id: 4,
      buoc: "Bước 4",
      urlImg: "../../../../../assets/img/icon-google-shopping-step-4.png",
      title: "Bắt đầu chiến dịch",
      description: "Bạn sẽ luôn được chúng tôi cập nhật đầy đủ thông tin về hiệu suất chiến dịch từng giai đoạn. Giúp bạn luôn luôn an tâm khi làm việc với chúng tôi.",
    }
  ]
  listBangGia = [
    {
      id: 1,
      number: "01",
      bgColor: "#3c8bd933",
      color: "#3C8BD9",
      description: "Khách hàng cung cấp cho NEWTRUST bộ từ khóa cần quảng cáo. Bộ phận báo giá bên NEWTRUST sẽ khảo sát độ cạnh tranh bộ từ khóa sau đó sẽ đưa ra một mức giá cụ thể. Trường hợp khách hàng chưa có bộ từ khóa vui lòng cung cấp cho NEWTRUST website đội ngũ nhân viên của chúng tôi sẽ tìm từ khóa phù hợp với ngân sách và ngành nghề của bạn."
    },
    {
      id: 2,
      bgColor: "#85b91533",
      color: "#34A852",
      number: "02",
      description: "Dựa vào bộ từ khóa NEWTRUST sẽ báo giá trọn gói trong 1 tháng, khách hàng không phải trả thêm bất kỳ chi phí nào.Quảng cáo của bạn sẽ luôn hiển thị vị trí top 1-4 ( Top VIP) trên công cụ tìm kiếm của Google , Hiển thị 24/24 , Không giới hạn click."
    },
    {
      id: 3,
      bgColor: "#fcaf1733",
      color: "#FCAF17",
      number: "03",
      description: "Tùy vào số lượng từ khóa, ngành nghề khác nhau, vị trí khác nhau sẽ có mức giá tại mỗi thời điểm khác nhau. Khách hàng có thể đăng ký gói 3 tháng, 6 tháng hoặc 1 năm để có mức giá Ưu Đãi nhất."
    },
  ]
  listSoBo = [
    {
      id: 1,
      urlImg: "https://d32q3bqti6sa3p.cloudfront.net/uploads/icon-budget-manage-1600306370.svg",
      title: "Đặt hạn mức ngân sách",
      description: "Bạn thoải mái đặt mức ngân sách để đảm bảo chi tiêu không vượt quá giới hạn."
    },
    {
      id: 2,
      urlImg: "https://d32q3bqti6sa3p.cloudfront.net/uploads/icon-budget-manage-1600306370.svg",
      title: "Quản lý linh hoạt",
      description: "Không cần phải cam kết sử dụng lâu dài. Bạn có thể tạm dừng bất cứ khi nào bạn muốn."
    },
    {
      id: 3,
      urlImg: "https://d32q3bqti6sa3p.cloudfront.net/uploads/icon-pay-for-result-1600306371.svg",
      title: "Trả tiền khi có kết quả",
      description: "Bạn chỉ mất tiền khi khách hàng nhấp vào quảng cáo và thực hiện các hành động xoay quanh quảng cáo."
    },
  ]
  listUuDienQuangCaoGoogle = [
    {
      id: 1,
      urlImg: "../../../../../assets/img/advantages-1.png",
      title: "Đặt hạn mức ngân sách",
      description: "Quảng bá website doanh nghiệp mạnh mẽ với thị trường khách hàng rộng lớn"
    },
    {
      id: 2,
      urlImg: "../../../../../assets/img/advantages-3.png",
      title: "Quản lý linh hoạt",
      description: "Lựa chọn nội dung để quảng cáo, theo: lĩnh vực, quốc gia và ngôn ngữ."
    },
    {
      id: 3,
      urlImg: "../../../../../assets/img/advantages-4.png",
      title: "Trả tiền khi có kết quả",
      description: "Tạo được nhận thức về sản phẩm."
    },
    {
      id: 4,
      urlImg: "../../../../../assets/img/advantages-5.png",
      title: "Trả tiền khi có kết quả",
      description: "Tăng doanh số bán hàng"
    },
  ]
  listCacHinhThucHienThiQuangCao = [
    {
      id: 1,
      urlImg: "../../../../../assets/img/display-type-1.jpg",
      title: "Quảng cáo văn bản (Text Ads)",
      description: "Quảng cáo truyền thống bằng chữ chạy trên Search Network"
    },
    {
      id: 2,
      urlImg: "../../../../../assets/img/display-type-2.jpg",
      title: "Quảng cáo hình ảnh (Banner Ads)",
      description: "Quảng cáo bằng ảnh tĩnh hoặc động để thu hút sự chú ý"
    },
    {
      id: 3,
      urlImg: "../../../../../assets/img/display-type-3.jpg",
      title: "Quảng cáo Video (Video Ads)",
      description: "Tạo được nhận thức về sản phẩm."
    },
    {
      id: 4,
      urlImg: "../../../../../assets/img/display-type-4.jpg",
      title: "Quảng cáo ứng dụng (App Ads)",
      description: "Bạn có thể quảng cáo những ứng dụng trên mobile bằng cách liên kết trực tiếp đến Google Play Store hoặc Apple App Store"
    },
    {
      id: 5,
      urlImg: "../../../../../assets/img/display-type-5.jpg",
      title: "Quảng cáo trên mobile (Mobile Ads)",
      description: "Quảng cáo trên các thiết bị di động. Kích thước mẫu quảng cáo ở đây khác so với trên desktop."
    },
  ]

  listCacHinhThucRemaketing = [
    {
      id: 1,
      urlImg: "../../../../../assets/img/remarketing-type-1.jpg",
      title: "Tiếp thị lại cho mạng hiển thị",
      description: "Hiển thị quảng cáo cho khách truy cập Website của bạn khi họ duyệt Web"
    },
    {
      id: 2,
      urlImg: "../../../../../assets/img/remarketing-type-2.jpg",
      title: "Danh sách tiếp thị lại cho quảng cáo tìm kiếm",
      description: "Hiển thị quảng cáo cho khách truy cập Website của bạn khi họ đang tìm kiếm những gì họ cần trên Google."
    },
    {
      id: 3,
      urlImg: "../../../../../assets/img/remarketing-type-3.jpg",
      title: "Tiếp thị lại động (chỉ dành cho nhà quảng cáo có tài khoản trên Google Merchant Center)",
      description: "Dựa vào danh sách khách hàng đã truy cập hoặc đã tương tác với Website, quảng cáo sẽ tiếp thị lại ở dạng động được tài khoản Google Merchant Center cung cấp."
    },
  ]

  listUuDienQuangCaoRemaketing = [
    {
      id: 1,
      urlImg: "../../../../../assets/img/benefit-remarting-1.png",
      title: "Tiết kiệm chi phí tối đa",
      description: "Giúp bạn thuyết phục khách hàng mua sản phẩm dễ dàng hơn, cũng là phương pháp hữu hiệu để giới thiệu sản phẩm mới tới khách hàng trung thành. Với phương thức này, bạn chỉ mất phí khi khách hàng CLICK CHUỘT vào quảng cáo."
    },
    {
      id: 2,
      urlImg: "../../../../../assets/img/benefit-remarting-2.png",
      title: "Tiếp cận đúng khách hàng mục tiêu",
      description: "Giúp bạn tiếp cận các điểm tiếp xúc toàn diện và kịp thời nhất để hướng khách hàng đến Website của bạn khi họ cam kết nhiều nhất. Tiếp cận khách hàng tiềm năng chính xác và trên diện rộng."
    },
    {
      id: 3,
      urlImg: "../../../../../assets/img/benefit-remarting-3.png",
      title: "Khả năng hiển thị rộng",
      description: "Hầu hết những website đều tham gia vào mạng hiển thị của Google nên kết nối với các người dùng thông qua các website rất dễ dàng. Số lượt tiếp cận không giới hạn trong ngày, tuần hoặc tháng."
    },
    {
      id: 4,
      urlImg: "https://d32q3bqti6sa3p.cloudfront.net/uploads/icon-quan-ly-chi-phi-hieu-qua-1600246098.svg",
      title: "Quản lý chi phí hiệu quả",
      description: "NEWTRUST Media tự động hóa giá thầu để đạt được mục tiêu CPA mục tiêu của bạn. NEWTRUST có thể linh hoạt điều chỉnh giá thầu cao hoặc thấp hơn để đạt được mục tiêu chiến dịch của bạn tốt nhất."
    },
  ]

  listGoogleShopingThayDoiDoangNghiep = [
    {
      id: 1,
      urlImg: "../../../../../assets/img/icon-google-shopping-1.png",
      title: "Tối ưu chi phí",
      description: "Bạn chỉ phải trả phí khi khách hàng CLICK vào. Chất lượng khách tiềm năng cao do sản phẩm được giới thiệu rất chi tiết."
    },
    {
      id: 2,
      urlImg: "../../../../../assets/img/icon-google-shopping-2.png",
      title: "Mở rộng khách hàng tiềm năng",
      description: "Hiển thị quảng cáo ở diện rộng, bạn có thể mở rộng ngay cả ở khách hàng của đối thủ."
    },
    {
      id: 3,
      urlImg: "../../../../../assets/img/icon-google-shopping-3.png",
      title: "Theo dõi, báo cáo chính xác",
      description: "Dữ liệu báo cáo rõ ràng, chi tiết về số lượt nhấp chuột và các dữ liệu để đưa ra giải pháp cải thiện kịp thời."
    },
    {
      id: 4,
      urlImg: "../../../../../assets/img/icon-google-shopping-4.png",
      title: "Mở rộng phạm vi ở địa phương và toàn cầu",
      description: "Nhắm mục tiêu quảng cáo ở phạm vi rất rộng, có thể lan tỏa ra các quốc gia khác."
    },
  ]
  listGoogleShopingThayDoiDoangNghiepHieuQua = [
    {
      id: 1,
      percent: "70%",
      description: "Khách hàng sử dụng công cụ tìm kiếm trước khi quyết định tiêu dùng sản phẩm/dịch vụ nào đó"
    },
    {
      id: 2,
      percent: "45%",
      description: "Doanh thu gia tăng khi doanh nghiệp sử dụng Google Shopping"
    },
    {
      id: 3,
      percent: "30%",
      description: "Khách hàng sẽ nhấp chuột khi thấy sản phẩm trên Google Shopping, hiệu quả hơn nhiều so với quảng cáo thuần túy"
    }
  ]
  Ytuong1 = true
  Ytuong2 = true
  ngOnInit(): void {
  }
  idActionTab = 1
  handleClickViewAction(id: number) {
    console.log(id);
    console.log(this.listFunction);
    this.listFunction.forEach(item => {
      item.active = false;
    });
    this.listFunction.find(item => item.id === id).active = true;
    this.idActionTab = id
  }
  ShowHideYTuong(num: number){
    if(num == 1){
      this.Ytuong1 = !this.Ytuong1
    }
    if(num == 2){
      this.Ytuong2 = !this.Ytuong2
    }
  }
}
