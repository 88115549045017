import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-live-stream-chuyen-nghiep',
  templateUrl: './live-stream-chuyen-nghiep.component.html',
  styleUrls: ['./live-stream-chuyen-nghiep.component.scss']
})
export class LiveStreamChuyenNghiepComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
