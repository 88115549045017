import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResponMessageModel } from '../Models/ShareModel/Share.Models';
import { RecivedInformationModel } from '../Models/ThongTinKhachHang.models';
import { HttpClient } from '@angular/common/http'

@Injectable({
    providedIn: 'root'
})
export class ThongTinKhachHangService {

    private apiUrl = environment.baseURL;

    constructor(private http: HttpClient) { }

    LuuThongTinKhachHang(input: RecivedInformationModel): Observable<ResponMessageModel> {
        return this.http.post<ResponMessageModel>(`${this.apiUrl}api/RecivedInformationController/LuuThongTinNguoiDung`, input);
    }
}
