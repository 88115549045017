import { trigger, transition, style, animate } from '@angular/animations';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-xay-dung-kenh-tiktok',
  templateUrl: './xay-dung-kenh-tiktok.component.html',
  styleUrls: ['./xay-dung-kenh-tiktok.component.scss'],
  animations: [
    trigger('expandCollapse', [
      transition(':enter', [
        style({ height: 0, opacity: 0 }),
        animate('500ms ease-out', style({ height: '*', opacity: 1 }))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ opacity: 0 }))
      ])
    ]),
    trigger('Faded', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms ease-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate('500ms ease-in', style({ opacity: 0 }))
      ])
    ]),
    trigger('expandCollapse2', [
      transition(':enter', [
        style({ height: 0, opacity: 0 }),
        animate('300ms ease-out', style({ height: '*', opacity: 1 }))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ height: 0, opacity: 0 }))
      ])
    ])
  ]
})
export class XayDungKenhTiktokComponent implements OnInit {

  constructor() { }
  listFunction = [
    {
      active: true,
      id: 1,
      title: "Xây dựng kênh tiktok",
      url: ""
    },
    // {
    //   active: false,
    //   id: 2,
    //   title: "Dịch vụ chăm sóc Fanpage",
    //   url: ""
    // },
    // {
    //   active: false,
    //   id: 3,
    //   title: "Các hình thức quảng cáo Facebook",
    //   url: ""
    // },
  ]

  lisThuHutNGuoiDung = [
    {
      id: 1,
      text: "Dễ dàng xác định được khách hàng mục tiêu của bạn thông qua việc người dùng cài đặt ứng dụng hay cần người dùng thực hiện các hành động trong ứng dụng (đặt mua sản phẩm, lên cấp 2,..).",
      bgColor: "#E1E5E9",
      padding: "mb-3"
    },
    {
      id: 2,
      text: "Nội dung quảng cáo đa dạng (hình ảnh hoặc video có kèm 4 dòng văn bản) và có thể xem trước.",
      bgColor: "#F1F5F9",
      padding: "mb-3"
    },
    {
      id: 3,
      text: "Quảng cáo ứng dụng sẽ xoay vòng quảng cáo và điều chỉnh giá thầu một cách tự động để hiển thị quảng cáo cho những người phù hợp với mức giá phù hợp của doanh nghiệp bạn.",
      bgColor: "#E1E5E9",
      padding: "mb-3"
    },
    {
      id: 4,
      text: "Số liệu báo cáo thống kê chi tiết sẽ đánh giá hiệu suất dựa trên mục tiêu của bạn.",
      bgColor: "#F1F5F9",
      padding: ""
    },
  ]

  listChienDichQuangCao = [
    {
      id: 1,
      urlImg: "../../../../../assets/img/icon-thu-hut-them-khach-hang-1600246336.svg",
      title: "Thu hút thêm khách hàng",
      description: "Để lại thông tin như link đăng ký, nút gọi, chỉ đường,... khách hàng sẽ dễ dàng truy cập và xem các thông tin về doanh nghiệp/sản phẩm, thúc đẩy doanh thu cho doanh nghiệp.",
    },
    {
      id: 2,
      urlImg: "../../../../../assets/img/icon-tiep-can-dung-khach-hang-1600238335.svg",
      title: "Tiếp cận đúng khách hàng tiềm năng",
      description: "Khi khách hàng tìm kiếm những sản phẩm/dịch vụ liên quan tới những gì mà công ty đang kinh doanh, quảng cáo sẽ được hiển thị trên máy tính hoặc thiết bị di động.",
    },
    {
      id: 3,
      urlImg: "../../../../../assets/img/icon-lua-chon-dung-pham-vi-thiet-lap-1600238334.svg",
      title: "Lựa chọn đúng phạm vi mà bạn thiết lập",
      description: "Bạn sẽ có quyền thiết lập phạm vi mà quảng cáo hiển thị, giúp tiếp cận chính xác khách hàng mục tiêu.",
    },
    {
      id: 4,
      urlImg: "../../../../../assets/img/icon-ngan-sach-it-hieu-qua-cao-1600238334.svg",
      title: "Ngân sách ít - hiệu quả cao - Chi phí minh bạch",
      description: "Với các chiến dịch trên Google Ads, bạn có thể kiểm soát ngân sách tối ưu nhất bằng cách theo dõi cách thức mà chiến dịch hoạt động cùng với ngân sách đã thiết lập trước.",
    }
  ]

  listCacBuocQuangCao = [
    {
      id: 1,
      buoc: "Bước 1",
      urlImg: "../../../../../assets/img/facebook-icon-increase-brand-awareness.svg",
      title: "Tăng mức độ nhận diện thương hiệu",
      description: "Thúc đẩy doanh thu bằng những thông tin như link đăng ký, nút gọi, chỉ đường,... khách hàng sẽ dễ dàng truy cập và xem các thông tin về doanh nghiệp/sản phẩm.",
    },
    {
      id: 2,
      buoc: "Bước 2",
      urlImg: "../../../../../assets/img/facebook-icon-find-customer.svg",
      title: "Tìm kiếm khách hàng tiềm năng",
      description: "Thu hút các khách hàng tiềm năng dựa trên các thiết lập về khách hàng mục tiêu chính xác trên quảng cáo.",
    },
    {
      id: 3,
      buoc: "Bước 3",
      urlImg: "../../../../../assets/img/facebook-icon-bring-advertising-to-life.svg",
      title: "Đưa quảng cáo vào cuộc sống",
      description: "Thu hút khách hàng thông qua những hình ảnh, video khiến họ phải nhìn lại nhiều lần.",
    },
    {
      id: 4,
      buoc: "Bước 4",
      urlImg: "../../../../../assets/img/facebook-icon-increase-sales.svg",
      title: "Tăng doanh số tại cửa hàng",
      description: "Kết nối khách hàng online đến cửa hàng mua hàng mua sắm nhiều hơn.",
    }
    ,
    {
      id: 5,
      buoc: "Bước 5",
      urlImg: "../../../../../assets/img/facebook-icon-increase-online-order.svg",
      title: "Tăng đơn hàng trực tuyến",
      description: "Biến khách hàng xem sản phẩm thành khách mua hàng thông qua quảng cáo trên facebook.",
    }
  ]

  listCacBuocGoogleShopping = [
    {
      id: 1,
      buoc: "Bước 1",
      urlImg: "../../../../../assets/img/icon-google-shopping-step-1.png",
      title: "Chọn ngành hàng",
      description: "Chúng tôi sẽ kiểm tra trang web, tìm những từ khóa liên quan để tạo ra các từ khóa thích hợp dành riêng cho bạn và đề xuất những giải pháp phù hợp với ngân sách và nhu cầu của bạn.",
    },
    {
      id: 2,
      buoc: "Bước 2",
      urlImg: "../../../../../assets/img/icon-google-shopping-step-2.png",
      title: "Chọn ngân sách",
      description: "Sau khi bạn hài lòng với từ khóa, chúng tôi sẽ tạo ngay một chiến dịch xuất hiện trên Google. Quảng cáo hiển thị theo từng khu vực, từng quốc gia mà bạn muốn hướng đến.",
    },
    {
      id: 3,
      buoc: "Bước 3",
      urlImg: "../../../../../assets/img/icon-google-shopping-step-3.png",
      title: "NEWTRUST tối ưu giá",
      description: "NEWTRUST Media luôn theo dõi quảng cáo giúp bạn từng giờ, ngay lập tức chỉnh sửa thay đổi khi có lỗi để luôn giữ cho bạn mức ngân sách thấp nhất.",
    },
    {
      id: 4,
      buoc: "Bước 4",
      urlImg: "../../../../../assets/img/icon-google-shopping-step-4.png",
      title: "Bắt đầu chiến dịch",
      description: "Bạn sẽ luôn được chúng tôi cập nhật đầy đủ thông tin về hiệu suất chiến dịch từng giai đoạn. Giúp bạn luôn luôn an tâm khi làm việc với chúng tôi.",
    }
  ]
  listBangGia = [
    {
      id: 1,
      number: "01",
      bgColor: "#3c8bd933",
      color: "#3C8BD9",
      description: "Khách hàng cung cấp cho NEWTRUST bộ từ khóa cần quảng cáo. Bộ phận báo giá bên NEWTRUST sẽ khảo sát độ cạnh tranh bộ từ khóa sau đó sẽ đưa ra một mức giá cụ thể. Trường hợp khách hàng chưa có bộ từ khóa vui lòng cung cấp cho NEWTRUST website đội ngũ nhân viên của chúng tôi sẽ tìm từ khóa phù hợp với ngân sách và ngành nghề của bạn."
    },
    {
      id: 2,
      bgColor: "#85b91533",
      color: "#34A852",
      number: "02",
      description: "Dựa vào bộ từ khóa NEWTRUST sẽ báo giá trọn gói trong 1 tháng, khách hàng không phải trả thêm bất kỳ chi phí nào.Quảng cáo của bạn sẽ luôn hiển thị vị trí top 1-4 ( Top VIP) trên công cụ tìm kiếm của Google , Hiển thị 24/24 , Không giới hạn click."
    },
    {
      id: 3,
      bgColor: "#fcaf1733",
      color: "#FCAF17",
      number: "03",
      description: "Tùy vào số lượng từ khóa, ngành nghề khác nhau, vị trí khác nhau sẽ có mức giá tại mỗi thời điểm khác nhau. Khách hàng có thể đăng ký gói 3 tháng, 6 tháng hoặc 1 năm để có mức giá Ưu Đãi nhất."
    },
  ]
  listSoBo = [
    {
      id: 1,
      urlImg: "https://d32q3bqti6sa3p.cloudfront.net/uploads/icon-budget-manage-1600306370.svg",
      title: "Đặt hạn mức ngân sách",
      description: "Bạn thoải mái đặt mức ngân sách để đảm bảo chi tiêu không vượt quá giới hạn."
    },
    {
      id: 2,
      urlImg: "https://d32q3bqti6sa3p.cloudfront.net/uploads/icon-budget-manage-1600306370.svg",
      title: "Quản lý linh hoạt",
      description: "Không cần phải cam kết sử dụng lâu dài. Bạn có thể tạm dừng bất cứ khi nào bạn muốn."
    },
    {
      id: 3,
      urlImg: "https://d32q3bqti6sa3p.cloudfront.net/uploads/icon-pay-for-result-1600306371.svg",
      title: "Trả tiền khi có kết quả",
      description: "Bạn chỉ mất tiền khi khách hàng nhấp vào quảng cáo và thực hiện các hành động xoay quanh quảng cáo."
    },
  ]
  listUuDienQuangCaoGoogle = [
    {
      id: 1,
      urlImg: "../../../../../assets/img/chat-luong-dich-vu-1.jpg",
      title: "Tiếp nhận thông tin và báo giá dịch vụ",
      description: "Tiếp nhận các thông tin và yêu cầu từ khách hàng. Tư vấn miễn phí và báo giá gói dịch vụ phù hợp",
    },
    {
      id: 2,
      urlImg: "../../../../../assets/img/chat-luong-dich-vu-2.jpg",
      title: "Thống nhất, ký hợp đồng và thanh toán",
      description: "Hai bên thống nhất dịch vụ, ký kết hợp đồng xây kênh TikTok và thanh toán"
    },
    {
      id: 3,
      urlImg: "../../../../../assets/img/chat-luong-dich-vu-3.jpg",
      title: "Lên ý tưởng, kịch bản",
      description: "Lên ý tưởng sản xuất, viết kịch bản một cách chi tiết nhất theo đúng yêu cầu của khách hàng"
    },
    {
      id: 4,
      urlImg: "../../../../../assets/img/chat-luong-dich-vu-4.jpg",
      title: "Duyệt kịch bản và tiến hành sản xuất video ",
      description: "Duyệt kịch bản với khách hàng, sau đó team media tiến hành quay dựng, đăng tải video và chăm sóc kênh"
    },
  ]
  listCacHinhThucHienThiQuangCao = [
    {
      id: 1,
      urlImg: "../../../../../assets/img/display-type-1.jpg",
      title: "Quảng cáo văn bản (Text Ads)",
      description: "Quảng cáo truyền thống bằng chữ chạy trên Search Network"
    },
    {
      id: 2,
      urlImg: "../../../../../assets/img/display-type-2.jpg",
      title: "Quảng cáo hình ảnh (Banner Ads)",
      description: "Quảng cáo bằng ảnh tĩnh hoặc động để thu hút sự chú ý"
    },
    {
      id: 3,
      urlImg: "../../../../../assets/img/display-type-3.jpg",
      title: "Quảng cáo Video (Video Ads)",
      description: "Tạo được nhận thức về sản phẩm."
    },
    {
      id: 4,
      urlImg: "../../../../../assets/img/display-type-4.jpg",
      title: "Quảng cáo ứng dụng (App Ads)",
      description: "Bạn có thể quảng cáo những ứng dụng trên mobile bằng cách liên kết trực tiếp đến Google Play Store hoặc Apple App Store"
    },
    {
      id: 5,
      urlImg: "../../../../../assets/img/display-type-5.jpg",
      title: "Quảng cáo trên mobile (Mobile Ads)",
      description: "Quảng cáo trên các thiết bị di động. Kích thước mẫu quảng cáo ở đây khác so với trên desktop."
    },
  ]

  listCacHinhThucRemaketing = [
    {
      id: 1,
      urlImg: "../../../../../assets/img/remarketing-type-1.jpg",
      title: "Tiếp thị lại cho mạng hiển thị",
      description: "Hiển thị quảng cáo cho khách truy cập Website của bạn khi họ duyệt Web"
    },
    {
      id: 2,
      urlImg: "../../../../../assets/img/remarketing-type-2.jpg",
      title: "Danh sách tiếp thị lại cho quảng cáo tìm kiếm",
      description: "Hiển thị quảng cáo cho khách truy cập Website của bạn khi họ đang tìm kiếm những gì họ cần trên Google."
    },
    {
      id: 3,
      urlImg: "../../../../../assets/img/remarketing-type-3.jpg",
      title: "Tiếp thị lại động (chỉ dành cho nhà quảng cáo có tài khoản trên Google Merchant Center)",
      description: "Dựa vào danh sách khách hàng đã truy cập hoặc đã tương tác với Website, quảng cáo sẽ tiếp thị lại ở dạng động được tài khoản Google Merchant Center cung cấp."
    },
  ]

  listUuDienQuangCaoRemaketing = [
    {
      id: 1,
      urlImg: "../../../../../assets/img/benefit-remarting-1.png",
      title: "Tiết kiệm chi phí tối đa",
      description: "Giúp bạn thuyết phục khách hàng mua sản phẩm dễ dàng hơn, cũng là phương pháp hữu hiệu để giới thiệu sản phẩm mới tới khách hàng trung thành. Với phương thức này, bạn chỉ mất phí khi khách hàng CLICK CHUỘT vào quảng cáo."
    },
    {
      id: 2,
      urlImg: "../../../../../assets/img/benefit-remarting-2.png",
      title: "Tiếp cận đúng khách hàng mục tiêu",
      description: "Giúp bạn tiếp cận các điểm tiếp xúc toàn diện và kịp thời nhất để hướng khách hàng đến Website của bạn khi họ cam kết nhiều nhất. Tiếp cận khách hàng tiềm năng chính xác và trên diện rộng."
    },
    {
      id: 3,
      urlImg: "../../../../../assets/img/benefit-remarting-3.png",
      title: "Khả năng hiển thị rộng",
      description: "Hầu hết những website đều tham gia vào mạng hiển thị của Google nên kết nối với các người dùng thông qua các website rất dễ dàng. Số lượt tiếp cận không giới hạn trong ngày, tuần hoặc tháng."
    },
    {
      id: 4,
      urlImg: "https://d32q3bqti6sa3p.cloudfront.net/uploads/icon-quan-ly-chi-phi-hieu-qua-1600246098.svg",
      title: "Quản lý chi phí hiệu quả",
      description: "NEWTRUST Media tự động hóa giá thầu để đạt được mục tiêu CPA mục tiêu của bạn. NEWTRUST có thể linh hoạt điều chỉnh giá thầu cao hoặc thấp hơn để đạt được mục tiêu chiến dịch của bạn tốt nhất."
    },
  ]

  listGoogleShopingThayDoiDoangNghiep = [
    {
      id: 1,
      urlImg: "../../../../../assets/img/icon-google-shopping-1.png",
      title: "Tối ưu chi phí",
      description: "Bạn chỉ phải trả phí khi khách hàng CLICK vào. Chất lượng khách tiềm năng cao do sản phẩm được giới thiệu rất chi tiết."
    },
    {
      id: 2,
      urlImg: "../../../../../assets/img/icon-google-shopping-2.png",
      title: "Mở rộng khách hàng tiềm năng",
      description: "Hiển thị quảng cáo ở diện rộng, bạn có thể mở rộng ngay cả ở khách hàng của đối thủ."
    },
    {
      id: 3,
      urlImg: "../../../../../assets/img/icon-google-shopping-3.png",
      title: "Theo dõi, báo cáo chính xác",
      description: "Dữ liệu báo cáo rõ ràng, chi tiết về số lượt nhấp chuột và các dữ liệu để đưa ra giải pháp cải thiện kịp thời."
    },
    {
      id: 4,
      urlImg: "../../../../../assets/img/icon-google-shopping-4.png",
      title: "Mở rộng phạm vi ở địa phương và toàn cầu",
      description: "Nhắm mục tiêu quảng cáo ở phạm vi rất rộng, có thể lan tỏa ra các quốc gia khác."
    },
  ]
  listGoogleShopingThayDoiDoangNghiepHieuQua = [
    {
      id: 1,
      percent: "70%",
      description: "Khách hàng sử dụng công cụ tìm kiếm trước khi quyết định tiêu dùng sản phẩm/dịch vụ nào đó"
    },
    {
      id: 2,
      percent: "45%",
      description: "Doanh thu gia tăng khi doanh nghiệp sử dụng Google Shopping"
    },
    {
      id: 3,
      percent: "30%",
      description: "Khách hàng sẽ nhấp chuột khi thấy sản phẩm trên Google Shopping, hiệu quả hơn nhiều so với quảng cáo thuần túy"
    }
  ]

  listQuyTrinhLamViecMinhBachUyTin = [
    {
      id: 1,
      buoc: "Bước 1",
      urlImg: "../../../../../assets/img/facebook-icon-increase-brand-awareness.svg",
      title: "Hợp tác",
      description: "Lên kế hoạch chi tiết và đề xuất định hướng nội dung và thiết kế hình ảnh",
    },
    {
      id: 2,
      buoc: "Bước 2",
      urlImg: "../../../../../assets/img/facebook-icon-find-customer.svg",
      title: "Tìm hiểu",
      description: "Tìm hiểu sản phẩm, phân tích mục tiêu và đối tượng khách hàng.",
    },
    {
      id: 3,
      buoc: "Bước 3",
      urlImg: "../../../../../assets/img/facebook-icon-bring-advertising-to-life.svg",
      title: "Đề xuất ý tưởng",
      description: "Lên kế hoạch chi tiết và đề xuất định hướng nội dung và thiết kế hình ảnh",
    },
    {
      id: 4,
      buoc: "Bước 4",
      urlImg: "../../../../../assets/img/facebook-icon-increase-sales.svg",
      title: "Kiểm duyệt, đánh giá",
      description: "Gửi demo nội dung + hình ảnh, duyệt trước khi đăng bài.",
    }
    ,
    {
      id: 5,
      buoc: "Bước 5",
      urlImg: "../../../../../assets/img/facebook-icon-increase-online-order.svg",
      title: "Kết quả",
      description: "Nghiệm thu, báo cáo và đánh giá dịch vụ.",
    }
  ]

  listKhichThichKhachHangChuyenDoi = [
    {
      id: 1,
      buoc: "Bước 1",
      urlImg: "../../../../../assets/img/facebook-icon-conversion-target.svg",
      title: "Mực tiêu chuyển đổi",
      description: "Không có team media để sản xuất video chất lượn",
    },
    {
      id: 2,
      buoc: "Bước 2",
      urlImg: "../../../../../assets/img/facebook-icon-increase-sales-2.svg",
      title: "Tăng doanh số",
      description: "Chưa có kịch bản và ý tưởng phát triển kênh TikTok",
    },
    {
      id: 3,
      buoc: "Bước 3",
      urlImg: "../../../../../assets/img/facebook-icon-increase-customer.svg",
      title: "Quảng cáo các địa điểm kinh doanh thực ngoài đời của bạn với những người ở gần đó.",
      description: "Ngân sách chưa quá nhiều để đầu tư",
    },
    {
      id: 4,
      buoc: "Bước 3",
      urlImg: "../../../../../assets/img/icon-target-theo-dia-diem.svg",
      title: "Quảng cáo các địa điểm kinh doanh thực ngoài đời của bạn với những người ở gần đó.",
      description: "Muốn viral nội dung, tăng view nhanh chóng",
    }
  ]
  listCanNhacThuongHieu = [
    {
      title: "Mục tiêu tăng lượng truy cập",
      description: "Nếu bạn mới lập website, fanpage, bạn muốn khách hàng truy cập vào fanpage hoặc website để giới thiệu sản phẩm, dịch vụ tới khách hàng thì đây là mục tiêu phù hợp cho bạn. Nó giúp tăng traffic cho website, fanpage và cũng là một cách gián tiếp tăng like cho fanpage. Bạn chỉ cần trả chi phí theo CPC (tức là khi khách hàng click vào quảng cáo mới phải trả tiền)."
    },
    {
      title: "Mục tiêu tương tác",
      description: "Bạn muốn quảng cáo sản phẩm tới khách hàng mục tiêu qua thông qua bài viết. Tương tác bao gồm lượt thích, lượt bình luận và lượt chia sẻ nhưng cũng có thể là lượt ưu đãi đã nhận từ trang của bạn. Bạn chỉ cần trả chi phí theo CPC (tức là khi khách hàng click vào quảng cáo mới phải trả tiền)."
    },
    {
      title: "Tăng lượt cài đặt ứng dụng",
      description: "Chuyển mọi người tới cửa hàng nơi họ có thể tải ứng dụng của doanh nghiệp bạn. Thu hút người dùng qua các hành động cụ thể (cài đặt, sử dụng ứng dụng, chơi ngay). Chỉ phải chi trả phí theo CPC, CPI."
    },
    {
      title: "Tăng lượt xem video",
      description: "Sử dụng khi bạn muốn quảng cáo sản phẩm hoặc thương hiệu thông qua video, bạn muốn khách hàng tương tác, xem video đó nhiều hơn. Chi phí của hành động này sẽ tính theo CPV."
    },
    {
      title: "Tìm kiếm khách hàng tiềm năng",
      description: "Tìm kiếm khách hàng tiềm năng cho doanh nghiệp của bạn. Tạo quảng cáo để thu thập thông tin từ những người quan tâm tới sản phẩm của bạn, chẳng hạn như các lượt đăng ký nhận bản tin. Tính phí theo CPC (cost per click), CPL (cost per lead)"
    },
    {
      title: "Tăng tin nhắn",
      description: "Kết nối với mọi người trên Facebook, giao tiếp với khách hàng sẵn có hoặc khách hàng tiềm năng để khuyến khích họ quan tâm tới doanh nghiệp của bạn."
    }
  ]

  listViTriXuatHienQuangCao = [
    {
      id: 1,
      buoc: "Bước 1",
      urlImg: "../../../../../assets/img/facebook-adver-position-1.png",
      title: "Quảng cáo tăng like",
      description: "Khuyến khích mọi người thực hiện hành động nhất định trên trang web của doanh nghiệp bạn, chẳng hạn như thêm sản phẩm vào giỏ hàng, tải ứng dụng của bạn xuống, đăng ký trang web của bạn hoặc thực hiện giao dịch mua.",
    },
    {
      id: 2,
      buoc: "Bước 2",
      urlImg: "../../../../../assets/img/facebook-adver-position-2.png",
      title: "Quảng cáo click web trên bản tin",
      description: "Hiển thị các sản phẩm có trong danh mục trên cửa hàng thương mại điện tử của bạn để tạo doanh thu.",
    },
    {
      id: 3,
      buoc: "Bước 3",
      urlImg: "../../../../../assets/img/facebook-adver-position-3.png",
      title: "Quảng cáo link vào website",
      description: "Lên kế hoạch chi tiết và đề xuất định hướng nội dung và thiết kế hình ảnh",
    }
    ,
    {
      id: 4,
      buoc: "Bước 3",
      urlImg: "../../../../../assets/img/facebook-adver-position-4.png",
      title: "Quảng cáo ở bản tin",
      description: "Lên kế hoạch chi tiết và đề xuất định hướng nội dung và thiết kế hình ảnh",
    }
    ,
    {
      id: 5,
      buoc: "Bước 3",
      urlImg: "../../../../../assets/img/facebook-adver-position-5.png",
      title: "Quảng cáo bài viết",
      description: "Lên kế hoạch chi tiết và đề xuất định hướng nội dung và thiết kế hình ảnh",
    }
    ,
    {
      id: 6,
      buoc: "Bước 3",
      urlImg: "../../../../../assets/img/facebook-adver-position-6.png",
      title: "Quảng cáo lead Ads",
      description: "Lên kế hoạch chi tiết và đề xuất định hướng nội dung và thiết kế hình ảnh",
    }
  ]
  listTaiSaoDoanhNghiepCanQuangCaoTiktok = [
    {
      title: "+500",
      des: "Dự án Digital Marketing"
    },
    {
      title: "100%",
      des: "Hài lòng về chất lượng"
    },
    {
      title: "100%",
      des: "Đảm bảo uy tín chất lượng"
    },
    {
      title: "24/7",
      des: "Tư vấn và hỗ trợ mọi lúc mọi nơi"
    }
  ]
  listHopTac = [
    {
      url:"../../../../assets/img/chi-trang-20231005124526-qxef_.jpg",
      name: "Khánh Ly Nguyễn",
      comment: "Cám ơn Newtrust và đội ngũ đã hỗ trợ tận tình. Trang web của công ty mình được biết đến nhiều hơn và có nhiều khách hàng hơn. Chúc NEWTRUST luôn thành công"
    },
    {
      url:"../../../../assets/img/anh-dao-20231005124525-uuq1a.png",
      name: "Đạt Phạm",
      comment: "Nhân viên tư vấn và hỗ trợ rất nhiệt tình. Mình dùng dịch vụ chăm sóc Fanpage, sau 3 tháng nhiều lượt tương tác từ khách hàng quá trời, đã từ hình ảnh đến nội dung viết nhìn chuyên nghiệp cực hà hà"
    },
    {
      url:"../../../../assets/img/uong-ca-phe-2239-1668872516-20230908181550-znikv.png",
      name: "Trần Lê Minh Thư",
      comment: "Đã từng chạy quảng cáo fb qua rất nhiều dịch vụ nhưng cảm thấy NEWTRUST là nơi không chỉ chạy quảng cáo mà còn tư vấn khách cụ thể cần làm gì trong suốt quá trình hợp tác, mình học hỏi đc rất nhiều bài học ở đây."
    },
    {
      url:"../../../../assets/img/anh-giang-20231005124526-lnax8.jpg",
      name: "Giang Alexander",
      comment: "Newtrust SEO uy tín, thực hiện đúng những điều đã cam kết. Thứ hạng sau khi SEO bền vững ít bị ảnh hưởng bởi thuật toán của Google, thứ hạng từ khóa cam kết top 10 nhưng nhiều từ khóa đã thăng hạng đến top 1."
    },
    {
      url:"../../../../assets/img/56-20231019133642-zwamz.jpg",
      name: "Lê Bobbylee",
      comment: "Chất lượng tốt và giá cả hợp lý. Ban đầu định làm 1 project bång Wordpress nhưng sau thấy dịch vụ tốt nên mình thuê luôn 4 cái. Hi vọng NEWTRUST cố gắng duy trì"
    }
  ]
  Ytuong1 = true
  Ytuong2 = true
  hover: boolean = false;
  
  ngOnInit(): void {
  }
  TieuChi1 = false
  TieuChi2 = false
  TieuChi6 = false
  TieuChi7 = false
  ToggleTieuChi(TieuChi: string) {
    this[TieuChi] = !this[TieuChi];
  }
  idActionTab = 1
  handleClickViewAction(id: number) {
    console.log(id);
    console.log(this.listFunction);
    this.listFunction.forEach(item => {
      item.active = false;
    });
    this.listFunction.find(item => item.id === id).active = true;
    this.idActionTab = id
  }
  ShowHideYTuong(num: number) {
    if (num == 1) {
      this.Ytuong1 = !this.Ytuong1
    }
    if (num == 2) {
      this.Ytuong2 = !this.Ytuong2
    }
  }
  hoverTitle(id) {
    console.log(id)
  }
  popUpimgShow = false
  URLimgPopup = '';
  hidePopUpImg() {
    this.popUpimgShow = false
    this.URLimgPopup = ''
  }
  ShowPopupImg(url: string) {
    this.popUpimgShow = true
    this.URLimgPopup = url
  }
}
