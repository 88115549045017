import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-thiet-ke-bien-quang-cao',
  templateUrl: './thiet-ke-bien-quang-cao.component.html',
  styleUrls: ['./thiet-ke-bien-quang-cao.component.scss']
})
export class ThietKeBienQuangCaoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
