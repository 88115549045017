<!-- <div class="carousel-container">
    <div class="row flex-nowrap">
        <div class="col-md-6">
            <div class="carousel-img">
                <img src="https://mala.ae/wp-content/uploads/2023/02/Adventure5.png" alt="">
            </div>
            <div class="carousel-content">
                <div class="carousel-title">
                    <h3>
                        title 1
                    </h3>
                </div>
                <div class="carousel-description">
                    <p>
                        description 1
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="carousel-img">
                <img src="https://mala.ae/wp-content/uploads/2023/02/Adventure5.png" alt="">
            </div>
            <div class="carousel-content">
                <div class="carousel-title">
                    <h3>
                        title 2
                    </h3>
                </div>
                <div class="carousel-description">
                    <p>
                        description 2
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="carousel-img">
                <img src="https://mala.ae/wp-content/uploads/2023/02/Adventure5.png" alt="">
            </div>
            <div class="carousel-content">
                <div class="carousel-title">
                    <h3>
                        title 3
                    </h3>
                </div>
                <div class="carousel-description">
                    <p>
                        description 3
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="carousel-img">
                <img src="" alt="">
            </div>
            <div class="carousel-content">
                <div class="carousel-title">
                    <h3>
                        title 4
                    </h3>
                </div>
                <div class="carousel-description">
                    <p>
                        description 4
                    </p>
                </div>
            </div>
        </div>
    </div>
    <button>Next</button>
    <button>Previous</button>
</div> -->

<div class="custom-carousel-container">
  <div class="custom-carousel" (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()">
    <div class="custom-carousel-inner" [style.transform]="'translateX(' + (-currentIndex * 100) + '%)'">
      <div *ngFor="let slide of slides; let i = index" class="custom-carousel-item">
        <div class="custom-carousel-content" [class.mobile]="isMobile">
          <div class="custom-item-container" *ngFor="let item of slide" [style.flex-basis]="(100 / (isMobile ? itemsPerSlideMobile : itemsPerSlide)) + '%'">
            <div class="custom-image-container">
              <img [src]="item.image" [alt]="item.title">
            </div>
            <div class="custom-text-container">
              <div class="custom-carousel-title" *ngIf="isShowTitle" [innerHTML]="sanitizeHtml(item.title)"></div>
              <div class="custom-carousel-description" *ngIf="isShowDescription" [innerHTML]="sanitizeHtml(item.description)"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <button *ngIf="isShowButtonNextPrev" class="custom-carousel-control custom-prev" (click)="prev()">&#10094;</button>
    <button *ngIf="isShowButtonNextPrev" class="custom-carousel-control custom-next" (click)="next()">&#10095;</button>
  </div>
  
  <div class="custom-carousel-indicators" *ngIf="showIndicators">
    <button *ngFor="let slide of slides; let i = index" 
            [class.custom-active]="i === currentIndex"
            (click)="goToSlide(i)"></button>
  </div>
</div>