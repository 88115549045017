import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tiktok-ads',
  templateUrl: './tiktok-ads.component.html',
  styleUrls: ['./tiktok-ads.component.scss']
})
export class TiktokAdsComponent implements OnInit {

  constructor() { }
  listFunction = [
    {
      active: true,
      id: 1,
      title: "Tổng quan",
      url: ""
    },
    {
      active: false,
      id: 2,
      title: "Các hình thức quảng cáo Tiktok",
      url: ""
    }
  ]
  listTaiSaoDoanhNghiepCanQuangCaoTiktok=[
    {
      title: "150",
      des: "Có mặt tại 150 quốc gia và vùng lãnh thổ với 75 ngôn ngữ"
    },
    {
      title: "800 triệu",
      des: "Hơn 800 triệu người dùng tích cực"
    },
    {
      title: "2 tỷ",
      des: "2 tỷ lượt tải ứng dụng ở cả 2 hệ điều hành (iOS & Android)"
    },
    {
      title: "1 tỷ",
      des: "1 tỷ lượt xem video hằng ngày"
    },
    {
      title: "18 - 34 tuổi",
      des: "41% người dùng TikTok có độ từ 18 - 34 tuổi"
    },
    {
      title: "Giới trẻ",
      des: "Sân chơi của giới trẻ toàn cầu"
    }
  ]
  listQunagCaoTikTokgiupgi = [
    {
      id: 1,
      urlImg: "../../../../../assets/img/icon-brand-1627531217.svg",
      title: "Tăng nhận thức thương hiệu",
      description: "Xây dựng và thúc đẩy mạnh mẽ nhận thức thương hiệu thông qua chiến dịch quảng cáo TikTok nhắm ĐÚNG đối tượng mục tiêu."
    },
    {
      id: 2,
      urlImg: "../../../../../assets/img/icon-eye-1627531216.svg",
      title: "Hấp dẫn thị giác, đưa câu chuyện thương hiệu vào cuộc sống",
      description: "Xem video chắc chắn sẽ sinh động và thú vị hơn so với nhìn hình ảnh. Do đó, những video truyền tải thông điệp thú vị, độc đáo, bổ ích dễ thu hút khách hàng và khiến họ phải xem lại nhiều lần."
    },
    {
      id: 3,
      urlImg: "../../../../../assets/img/icon-spead-1627531215.svg",
      title: "Khả năng lan tỏa thông điệp mạnh mẽ",
      description: "Phạm vi quảng cáo TikTok rất rộng, có thể được truyền xa tới những quốc gia khác mà bạn không ngờ tới."
    },
    {
      id: 4,
      urlImg: "../../../../../assets/img/icon-money-1627531215.svg",
      title: "Tăng cảm hứng mua hàng",
      description: "Các video độc đáo dễ dàng thu hút khách hàng mục tiêu, kết hợp với lời kêu gọi đặc biệt (CTA) sẽ thúc đẩy người dùng theo dõi kênh TikTok của bạn, vừa truy cập website chính và thực hiện chuyển đổi theo mong muốn của bạn."
    },
  ]
  listQunagCaoTikTokgiupgiDemLaiGi = [
    {
      id: 1,
      urlImg: "../../../../../assets/img/icon-gear-1627533511.svg",
      title: "Dịch vụ nhanh chóng, thuận tiện, hiệu quả",
    },
    {
      id: 2,
      urlImg: "../../../../../assets/img/icon-tech-1627534051.svg",
      title: "Kết hợp con người & công nghệ",
    },
    {
      id: 3,
      urlImg: "../../../../../assets/img/icon-financial-1627534051.svg",
      title: "Minh bạch về tài chính",
    },
    {
      id: 4,
      urlImg: "../../../../../assets/img/icon-report-1627534050.svg",
      title: "Báo cáo định kỳ rõ ràng",
    },
  ]
  idActionTab = 1
  ngOnInit(): void {
  }
  handleClickViewAction(id: number) {
    console.log(id);
    console.log(this.listFunction);
    this.listFunction.forEach(item => {
      item.active = false;
    });
    this.listFunction.find(item => item.id === id).active = true;
    this.idActionTab = id
  }
}
