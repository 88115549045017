<div class="blog-title blog-title-bg bg-2 bg-google-ads">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="blog-title-text">
                    <h2>Facebook Ads</h2>
                    <ul>
                        <li>
                            <a routerLink="/index">Home</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            <a routerLink="/facebook-ads">Facebook Ads</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container">
    <div class="row">
        <div class="col-lg-12">
            <div class="google-ads-content d-flex justify-content-start align-items-center flex-wrap">
                <div class="google-ads-content-item" *ngFor="let item of listFunction"
                    [ngClass]="{'active': item.active}" (click)="handleClickViewAction(item.id)">
                    <div class="item-background"></div>
                    <div class="item-content">{{item.title}}</div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="idActionTab == 1" @expandCollapse>
    <div class="container-fluid wrapper-quang-cao-google bg-google-ads-color">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <h2 class="line-before text-md-32 mb-20 text-white">Tại sao lại chọn Facebook?</h2>
                    <div class="text-white text-justify text-quang-cao-google">
                        <p>Hàng triệu doanh nghiệp lớn nhỏ đều kết nối với mọi người trên Facebook.</p>
                        <p>Bạn có biết, hơn 2 tỷ người sử dụng Facebook mỗi ngày để kết nối với bạn bè, gia đình và kết
                            nối với mọi người. Độ tuổi trung bình của người dùng vào khoảng 18 - 34 tuổi, độ tuổi có
                            hành vi mua sắm cao nhất. Do đó, Facebook là một nơi thích hợp để tìm khách hàng và xây dựng
                            mối quan hệ lâu dài.</p>
                        <p>
                            Với quảng cáo của Facebook, bạn sẽ thu hút được sự chú ý và kích thích mọi người hành động,
                            tương tác với quảng cáo của bạn.
                        </p>
                        <a class="btn-lien-he-google  mb-3" routerLink="/lien-he">Liên hệ ngay</a>
                    </div>
                </div>
                <div class="col-lg-6">
                    <img src="../../../../../assets/img/facebooke-ads.png" width="550px" height="320px" alt="" srcset=""
                        class="w-100">
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid  wrap-google-sosanh bg-grey">
        <div class="container">
            <div class="row text-center title-google-tien-hanh">
                <h2>Các bước tiến hành quảng cáo Google với NEWTRUST Media</h2>
            </div>
            <div class="row">
                <div class="custom-col-5" *ngFor="let item of listCacBuocQuangCao">
                    <div class="wrapper-google-ads-help-item-2-item-content pl-0 pr-0 bg-grey ">
                        <img src="{{item.urlImg}}" alt="">
                        <!-- <h2 style=" color: #fc2b4ead;">{{item.buoc}}</h2> -->
                        <h4>{{item.title}}</h4>
                        <div>{{item.description}}</div>
                    </div>
                </div>
                <!-- <div *ngFor="let item of listCacBuocQuangCao" class="col-lg-3 wrapper-google-ads-help-item-2-item">
                    <div class="wrapper-google-ads-help-item-2-item-content pl-0 pr-0"
                        [ngClass]="{'bg-grey':item.id % 2}">
                        <img src="{{item.urlImg}}" alt="">
                        <h2 style=" color: #fc2b4ead;">{{item.buoc}}</h2>
                        <h4>{{item.title}}</h4>
                        <div>{{item.description}}</div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>

    <div class="container mt-5">
        <div class="row text-center title-google-tien-hanh">
            <h2>Các bước cơ bản cần có để triển khai chiến dịch Facebook hiệu quả</h2>
            <p>Để tạo được một quảng cáo Facebook hiệu quả, bạn cần tuân thủ theo 4 bước dưới đây</p>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="row mb-5">
                    <div class="col-lg-6 d-flex align-items-center">
                        <div>
                            <div class="row">
                                <div class="col-2 text-center buoc-co-ban-can-co">Bước 1</div>
                                <div class="col-10 buoc-co-ban-can-co-title">
                                    <div>Phải biết được mục tiêu chạy quảng cáo</div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 text-justify">
                                    <p>Đây được coi là bước gần như quan trọng nhất khi bạn thiết lập quảng cáo. Nếu bạn
                                        không
                                        rõ được mục tiêu mình muốn làm gì thì việc chạy quảng cáo cũng không có ý nghĩa
                                        gì
                                        mà
                                        chỉ là tiêu tiền vô bổ.</p>
                                    <p>
                                        Để chọn được mục tiêu quảng cáo phù hợp, ban phải tự trả lời được câu hỏi "Kết
                                        quả
                                        quan
                                        trọng nhất mà bạn muốn đạt được sau khi làm quảng cáo là gì?". Kết quả đó có thể
                                        là
                                        tăng
                                        nhận diện thương hiệu, muốn có thêm lượt thích trang hay là tăng thêm doanh số.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 d-flex justify-content-center">
                        <img src="../../../../../assets/img/facebook-banner-target-1.png" alt="">
                    </div>
                </div>

                <div class="row mb-5">
                    <div class="col-lg-6 d-flex justify-content-center">
                        <img src="../../../../../assets/img/facebook-banner-target-2.png" alt="">
                    </div>
                    <div class="col-lg-6 d-flex align-items-center">
                        <div>
                            <div class="row">
                                <div class="col-2 text-center buoc-co-ban-can-co">Bước 2</div>
                                <div class="col-10 buoc-co-ban-can-co-title">
                                    <div>Chọn đúng tệp đối tượng</div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 text-justify">
                                    <p>Facebook sẽ cho bạn được quyền tùy chọn tệp đối tượng dựa vào độ tuổi, vị trí,
                                        hành
                                        vi,... cho quảng cáo của bạn. Bạn phải xác định được tệp khách hàng phù hợp với
                                        sản
                                        phẩm mà bạn đang có, sau đó thiết lập cho tệp khách hàng này thấy quảng cáo. Như
                                        vậy
                                        quảng cáo của bạn mới hiệu quả được.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="row mb-5">
                    <div class="col-lg-6 d-flex align-items-center">
                        <div>
                            <div class="row">
                                <div class="col-2 text-center buoc-co-ban-can-co">Bước 3</div>
                                <div class="col-10 buoc-co-ban-can-co-title">
                                    <div>Thiết lập ngân sách</div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 text-justify">
                                    <p>Bạn nên thiết lập ngân sách để tránh làm lãng phí ngân sách cũng như để ngân sách
                                        vượt quá tầm kiểm soát.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 d-flex justify-content-center">
                        <img src="../../../../../assets/img/thiet-lap-ngan-sach-1600250480.png" alt="">
                    </div>
                </div>

                <div class="row mb-5">
                    <div class="col-lg-6 d-flex justify-content-center">
                        <img src="../../../../../assets/img/danh-gia-va-quan-ly-quang-cao-1600250479.png" alt="">
                    </div>
                    <div class="col-lg-6 d-flex align-items-center">
                        <div>
                            <div class="row">
                                <div class="col-2 text-center buoc-co-ban-can-co">Bước 4</div>
                                <div class="col-10 buoc-co-ban-can-co-title">
                                    <div>Đánh giá và quản lý quảng cáo</div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 text-justify">
                                    <p>Sau khi đã thiết lập được quảng cáo, bạn phải thường xuyên đánh giá kiểm tra để
                                        xem
                                        quảng cáo có hoạt động hiệu quả không để kịp thời chỉnh lại tệp đối tượng cho
                                        quảng
                                        cáo hiệu quả hơn.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <div class="padding-block bg-image-mobile-none bg-light1 lazyloaded"
        style="background-repeat: no-repeat; background-position: 100% bottom; background-size: contain; background-image: url(../../../../../assets/img/facebook-banner-2-lg-1600331742.png);">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <h2 class="mb-30">NEWTRUST sẽ giúp bạn thực hiện</h2>
                    <ul class="list-group-dot theme-facebook">
                        <li>Thiết kế và chỉnh sửa Fanpage</li>
                        <li>Đăng bài viết đúng chuẩn và tối ưu trên Fanpage, tối ưu nội dung (content), video.</li>
                        <li>Nhắm đúng đối tượng mục tiêu quảng cáo <p class="text-black">Xây dựng và đo lường, phân loại
                                khách hàng - tương tác trên Fanpage - Website - Đưa ra các thông điệp quảng cáo chính
                                xác đến từng đối tượng, hành vi khác nhau.</p>
                            <p class="mt-20 bg-blue-ocean text-white p-3">Xây dựng và tối ưu Quảng cáo Facebook Tự Động,
                                THÔNG MINH - Từ lúc khách vào Fanpgae, Web đến ra đơn hàng - doanh thu.</p>
                        </li>
                        <li>Báo cáo trực tiếp từ tài khoản Facebook.</li>
                    </ul>
                </div>
            </div>
        </div>
        <picture style="display: none;">
            <source data-srcset="https://d32q3bqti6sa3p.cloudfront.net/uploads/facebook-banner-2-lg-1600331742.png"
                sizes="1412px"
                srcset="https://d32q3bqti6sa3p.cloudfront.net/uploads/facebook-banner-2-lg-1600331742.png"><img alt=""
                class="lazyautosizes lazyloaded ls-is-cached" data-sizes="auto" data-parent-fit="contain"
                sizes="1412px">
        </picture>
    </div>

    <div class="container-fluid wrapper-quang-cao-google ">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <h2 class="line-before text-md-32 mb-20 text-black">Bạn tốn nhiều tiền cho quảng cáo Facebook nhưng
                        hiệu quả chưa mong đợi?</h2>
                    <div class="text-black text-justify text-quang-cao-google">
                        <p style="color: black !important;">NEWTRUST Media với 10+ năm kinh nghiệm thực hiện quảng cáo
                            trực tuyến Facebook, chúng tôi
                            đang thực hiện rất nhiều chiến dịch quảng cáo</p>
                        <p style="color: black !important;">Facebook thành công cho các doanh nghiệp, chúng tôi tự tin
                            sẽ giúp BẠN thành công khi triển
                            khai quảng cáo trên Facebook.</p>
                        <a class="btn-lien-he-google bg-main text-white mb-3" routerLink="/lien-he">Liên hệ ngay</a>
                    </div>
                </div>
                <div class="col-lg-6">
                    <img src="../../../../../assets/img/facebooke-ads.png" width="550px" height="320px" alt="" srcset=""
                        class="w-100">
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="idActionTab == 2" @expandCollapse>
    <div class="container-fluid wrapper-quang-cao-google bg-google-ads-color">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <h2 class="line-before text-md-32 mb-20 text-white">Tại sao cần sáng tạo nội dung cho Fanpage?</h2>
                    <div class="text-white text-justify text-quang-cao-google">
                        <p>Theo kết quả nghiên cứu từ Facebook, content "<strong>hay</strong>" và "<strong>sáng
                                tạo</strong>" sẽ giúp chiến dịch quảng cáo hiệu quả lên đến <strong>73%</strong>.</p>
                        <p>Việc đầu tư vào phát triển những nội dung thật chất lượng luôn là sự lựa chọn đúng đắn. Cùng
                            với sự đồng hành hỗ trợ của đội ngũ chuyên gia NEWTRUST Media, chúng tôi sẽ giúp fanpage của bạn
                            "lột xác" thật nổi bật và quảng cáo hiệu quả.</p>
                        <a class="btn-lien-he-google  mb-3" routerLink="/lien-he">Liên hệ ngay</a>
                    </div>
                </div>
                <div class="col-lg-6">
                    <img src="../../../../../assets/img/content.gif" width="550px" height="320px" alt="" srcset=""
                        class="w-100">
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid  wrap-google-sosanh bg-grey">
        <div class="container">
            <div class="row text-center title-google-tien-hanh">
                <h2 style="margin-bottom: 25px;">Fanpage chất lượng sẽ tạo dấu ấn thương hiệu và thu hút khách hàng hiệu
                    quả</h2>
            </div>
            <div class="row">
                <div class="col-lg-3 wrap-item-bang-gia" *ngFor="let item of listUuDienQuangCaoGoogle">
                    <div class="">
                        <div class="pl-0 pr-0" style="margin-right: 0px;">
                            <div class="img-thu-hut-khach-hang mb-5">
                                <img height="500px" class="w-100" src="{{item.urlImg}}" alt="">
                                <div class="title-thu-hut-khach-hang" (mouseover)="hoverTitle(item.id)">
                                    <h4>{{item.title}}</h4>
                                    <p class="text-white d-none">{{item.description}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid  wrap-google-sosanh bg-giai-phap-daonh-nghiep">
        <div class="container">
            <div class="row text-center title-google-tien-hanh text-white">
                <h2 style="margin-bottom: 25px;">Giải pháp tối ưu cho doanh nghiệp vừa và nhỏ</h2>
            </div>
            <div class="row">
                <div class="col-lg-4 mb-5">
                    <div class="bg-giai-phap-daonh-nghiep-item d-flex align-items-center flex-column">
                        <img class="mb-3" src="../../../../../assets/img/icon-check.svg" alt="">
                        <p class="text-center text-black">
                            Bạn "bí" ý tưởng nội, dung; Fanpage lộn xộn, chưa định hướng nội dung cụ thể, chuyên nghiệp?
                        </p>
                    </div>
                </div>

                <div class="col-lg-4 mb-5">
                    <div class="bg-giai-phap-daonh-nghiep-item d-flex align-items-center flex-column">
                        <img class="mb-3" src="../../../../../assets/img/icon-check.svg" alt="">
                        <p class="text-center text-black">
                            Doanh nghiệp bạn đang "lao đao" khi chi phí hoạt động tăng cao?
                        </p>
                    </div>
                </div>

                <div class="col-lg-4 mb-5">
                    <div class="bg-giai-phap-daonh-nghiep-item d-flex align-items-center flex-column">
                        <img class="mb-3" src="../../../../../assets/img/icon-check.svg" alt="">
                        <p class="text-center text-black">
                            Bạn muốn thu hút khách hàng, tăng sức cạnh tranh với đối thủ?
                        </p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 d-flex justify-content-center">
                    <a class="btn-lien-he-google  mb-3" routerLink="/lien-he">Liên hệ ngay</a>

                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid  wrap-google-sosanh bg-grey">
        <div class="container">
            <div class="row text-center title-google-tien-hanh">
                <h2>Quy trình làm việc minh bạch, uy tín</h2>
            </div>
            <div class="row">
                <div class="custom-col-5 mb-5" *ngFor="let item of listQuyTrinhLamViecMinhBachUyTin">
                    <div class="wrapper-quy-trinh-lam-viec-minh-bach pl-0 pr-0 bg-grey ">
                        <img src="{{item.urlImg}}" alt="">
                        <!-- <h2 style=" color: #fc2b4ead;">{{item.buoc}}</h2> -->
                        <h4>{{item.title}}</h4>
                        <div>{{item.description}}</div>
                    </div>
                </div>
                <!-- <div *ngFor="let item of listCacBuocQuangCao" class="col-lg-3 wrapper-google-ads-help-item-2-item">
                    <div class="wrapper-google-ads-help-item-2-item-content pl-0 pr-0"
                        [ngClass]="{'bg-grey':item.id % 2}">
                        <img src="{{item.urlImg}}" alt="">
                        <h2 style=" color: #fc2b4ead;">{{item.buoc}}</h2>
                        <h4>{{item.title}}</h4>
                        <div>{{item.description}}</div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>

    <div class=" lazyloaded"
        style="background-repeat: no-repeat; background-size: cover; background-position: center center; background-color: rgb(241, 245, 249); background-image: url(&quot;https://d32q3bqti6sa3p.cloudfront.net/uploads/service-fanpage/bg-brand-mark.png&quot;);"
        data-bgset="https://d32q3bqti6sa3p.cloudfront.net/uploads/service-fanpage/bg-brand-mark.png [(min-width: 992px)] | https://d32q3bqti6sa3p.cloudfront.net/uploads/service-fanpage/bg-brand-mark-mb.png">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 d-flex justify-content-center">
                    <img class="img-brand-mark ls-is-cached lazyloaded"
                        src="https://d32q3bqti6sa3p.cloudfront.net/uploads/img-brand-mark-1665393037.gif"
                        data-src="https://d32q3bqti6sa3p.cloudfront.net/uploads/img-brand-mark-1665393037.gif"
                        alt="Good content is good business" title="Good content is good business">
                </div>
                <div class="col-lg-6 d-flex justify-content-center align-items-center">
                    <div>
                        <h3 class="text-center">"Good content is good business."</h3>
                        <h2 class="text-center">Tạo dấu ấn thương hiệu trong tâm trí khách hàng</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="idActionTab == 3" @expandCollapse>
    <div class="container-fluid wrapper-quang-cao-google bg-google-ads-color">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <h2 class="line-before text-md-32 mb-20 text-white">Tăng mức độ nhận biết thương hiệu</h2>
                    <div class="text-white text-justify text-quang-cao-google">
                        <p>Thông thường khi doanh nghiệp mới tạo lập, mục tiêu hàng đầu chính là đưa thương hiệu quảng
                            bá tới cho khách hàng.</p>
                        <p>Mức độ nhận biết: Bao gồm các mục tiêu tạo dựng mối quan tâm tới sản phẩm hoặc dịch vụ của
                            bạn. Nâng cao nhận thức về thương hiệu là giới thiệu với mọi người điều tạo nên giá trị cho
                            doanh nghiệp của bạn.</p>
                        <p>Thông thường, để tăng mức độ nhận biết thì bạn thiết lập mục tiêu theo hai hướng sau đây:</p>
                        <p>- Mục tiêu nhận biết thương hiệu: Tăng mức độ nhận biết cho doanh nghiệp, dịch vụ.</p>
                        <p>- Mục tiêu số người tiếp cận: Hiển thị quảng cáo cho nhóm đối tượng mục tiêu của bạn.</p>
                        <a class="btn-lien-he-google  mb-3" routerLink="/lien-he">Liên hệ ngay</a>
                    </div>
                </div>
                <div class="col-lg-6">
                    <img src="../../../../../assets/img/tang-muc-do-nhan-biet-1602057628.jpg" width="550px"
                        height="320px" alt="" srcset="" class="w-100">
                </div>
            </div>
        </div>
    </div>

    <div class="container mt-5">
        <div class="row text-center title-google-tien-hanh">
            <h2>Tăng cân nhắc với thương hiệu</h2>
            <p>Các mục tiêu cân nhắc thương hiệu bao gồm các mục tiêu làm cho mọi người nghĩ tới và tìm kiếm nhiều thông
                tin hơn về doanh nghiệp của bạn.</p>
            <h3>Khi bạn muốn khách hàng cân nhắc, tương tác với thương hiệu thì bạn có thể thiết lập mục tiêu sau đây:
            </h3>
        </div>
        <div class="row">
            <div class="col-lg-4" *ngFor="let item of listCanNhacThuongHieu">
                <div class="dot-before theme-facebook mb-20">
                    <div class="dot-before-text"><strong>{{item.title}}</strong></div>
                </div>
                <p class="ml-40">{{item.description}}</p>
            </div>
        </div>
    </div>

    <div class="container-fluid  wrap-google-sosanh bg-grey">
        <div class="container">
            <div class="row text-center title-google-tien-hanh">
                <h2>Kích thích khách hàng chuyển đổi hành động</h2>
                <p>Gồm các mục tiêu khuyến khích những người quan tâm đến doanh nghiệp bạn mua hoặc sử dụng sản
                    phẩm/dịch vụ của bạn:</p>
            </div>
            <div class="row">
                <div class="col-lg-4 mb-5" *ngFor="let item of listKhichThichKhachHangChuyenDoi">
                    <div class="wrapper-quy-trinh-lam-viec-minh-bach pl-0 pr-0 bg-grey ">
                        <img src="{{item.urlImg}}" alt="">
                        <!-- <h2 style=" color: #fc2b4ead;">{{item.buoc}}</h2> -->
                        <h4>{{item.title}}</h4>
                        <div>{{item.description}}</div>
                    </div>
                </div>
                <!-- <div *ngFor="let item of listCacBuocQuangCao" class="col-lg-3 wrapper-google-ads-help-item-2-item">
                    <div class="wrapper-google-ads-help-item-2-item-content pl-0 pr-0"
                        [ngClass]="{'bg-grey':item.id % 2}">
                        <img src="{{item.urlImg}}" alt="">
                        <h2 style=" color: #fc2b4ead;">{{item.buoc}}</h2>
                        <h4>{{item.title}}</h4>
                        <div>{{item.description}}</div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>

    <div class="padding-block bg-image-mobile-none bg-white lazyloaded "
        style="background-repeat: no-repeat; background-position: 100% bottom; background-size: contain; background-image: url(../../../../../assets/img/facebook-banner-2-lg-1600331742.png);">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <h2 class="mb-30 mt-5">NEWTRUST sẽ giúp bạn thực hiện</h2>
                    <ul class="list-group-dot theme-facebook">
                        <li>Thiết kế và chỉnh sửa Fanpage</li>
                        <li>Đăng bài viết đúng chuẩn và tối ưu trên Fanpage, tối ưu nội dung (content), video.</li>
                        <li>Nhắm đúng đối tượng mục tiêu quảng cáo <p class="text-black">Xây dựng và đo lường, phân
                                loại
                                khách hàng - tương tác trên Fanpage - Website - Đưa ra các thông điệp quảng cáo
                                chính
                                xác đến từng đối tượng, hành vi khác nhau.</p>
                            <p class="mt-20 bg-blue-ocean text-white p-3">Xây dựng và tối ưu Quảng cáo Facebook Tự
                                Động,
                                THÔNG MINH - Từ lúc khách vào Fanpgae, Web đến ra đơn hàng - doanh thu.</p>
                        </li>
                        <li>Báo cáo trực tiếp từ tài khoản Facebook.</li>
                    </ul>
                </div>
            </div>
        </div>
        <picture style="display: none;">
            <source data-srcset="https://d32q3bqti6sa3p.cloudfront.net/uploads/facebook-banner-2-lg-1600331742.png"
                sizes="1412px"
                srcset="https://d32q3bqti6sa3p.cloudfront.net/uploads/facebook-banner-2-lg-1600331742.png"><img alt=""
                class="lazyautosizes lazyloaded ls-is-cached" data-sizes="auto" data-parent-fit="contain"
                sizes="1412px">
        </picture>
    </div>


    <div class="container-fluid wrapper-quang-cao-google bg-google-ads-color">
        <div class="container">
            <div class="row">


                <div class="col-lg-6">
                    <img src="../../../../../assets/img/Awareness-la-gi-1.jpg" width="550px" height="320px" alt=""
                        srcset="" class="w-100">
                </div>
                <div class="col-lg-6">
                    <h2 class="line-before text-md-32 mb-20 text-white">Tăng mức độ nhận biết thương hiệu</h2>
                    <div class="text-black text-justify text-quang-cao-google">
                        <p>Hiện nay, Quảng cáo Facebook rất dễ dàng, nhưng để Quảng cáo Facebook hiệu quả thì rất ít
                            người thực hiện được, nhiều doanh nghiệp,chủ cửa hàng mất tiền trên Facebook nhưng hiệu quả
                            mang lại chưa được như mong đợi.</p>
                        <p><strong> NEWTRUST Media tư vấn và triển khai một GIẢI PHÁP BÁN
                                HÀNG HOÀN THIỆN trên Facebook: <span> Từ quảng cáo bài post, website,
                                    video tăng like và tiếp thị lại. Điều chỉnh chiến thuật quảng cáo Facebook linh
                                    động, mạng lại hiệu quả bán hàng và thương hiệu cao nhất. </span> </strong></p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid">
        <div class="container">
            <div class="row mt-4 mb-5">
                <div class="col-lg-8">
                    <h2 class="line-before text-md-32 mb-20 text-white">Tăng mức độ nhận biết thương hiệu</h2>
                    <div class="text-black text-justify text-quang-cao-google">
                        <h3 style="color: black !important;">Chúng tôi hỗ trợ và đồng hành cùng khách hàng xây dựng
                            thương hiệu, bán hàng trên kênh online một cách hiệu quả , mang tới lợi ích cho khách hàng
                            lâu dài.</h3>
                        <p style="color: black !important;"><strong> NEWTRUST với kinh nghiệm thực tiễn khi thực hiện hàng
                                trăm chiến dịch trên Facebook, với đủ mọi ngành nghề,Chúng tôi sẽ tư vấn và thiết lập
                                chiến dịch QUẢNG CÁO FACEBOOK phù hợp với từng ngành nghề,đối tượng khách hàng để mang
                                lại hiệu quả nhất cho khách hàng. </strong></p>
                    </div>
                </div>

                <div class="col-lg-4 d-flex align-items-center justify-content-center">
                    <div class="bg-grey p-4">
                        <h3 class="mb-20">Đặc biệt: NEWTRUST Media sẽ hỗ thêm các doanh nghiệp Booking KOLs theo yêu cầu.
                        </h3> <a routerLink="/lien-he" class="font-weight-bold next-link" title="Tìm hiểu ngay">Tìm hiểu
                            ngay</a>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="container-fluid  wrap-google-sosanh bg-grey">
        <div class="container">
            <div class="row text-center title-google-tien-hanh">
                <h2>Các vị trí xuất hiện quảng cáo trên Facebook</h2>
            </div>
            <div class="row">
                <div class="col-lg-4 mb-5" *ngFor="let item of listViTriXuatHienQuangCao">
                    <div class="h-100 wrap-cac-vi-tri-xuat-hien-quang-cao d-flex flex-column justify-content-between"
                        (click)="ShowPopupImg(item.urlImg)">
                        <h3 class="dot-square-before theme-primary">{{item.title}}</h3>
                        <div style="position: relative;">
                            <img src="{{item.urlImg}}" alt="">
                            <div class="leyer-cac-vi-tri-xuat-hien-quang-cao-item-img">
                                <i class="icofont-search-1"></i>

                            </div>
                        </div>
                        <!-- <h2 style=" color: #fc2b4ead;">{{item.buoc}}</h2> -->
                    </div>
                </div>
                <!-- <div *ngFor="let item of listCacBuocQuangCao" class="col-lg-3 wrapper-google-ads-help-item-2-item">
                    <div class="wrapper-google-ads-help-item-2-item-content pl-0 pr-0"
                        [ngClass]="{'bg-grey':item.id % 2}">
                        <img src="{{item.urlImg}}" alt="">
                        <h2 style=" color: #fc2b4ead;">{{item.buoc}}</h2>
                        <h4>{{item.title}}</h4>
                        <div>{{item.description}}</div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>

<div class="container-fluid wraper-doi-ngu-tu-van">
    <div class="container">
        <div class="row">
            <div class="col-12 wrap-content-doi-ngu-tu-van">
                <h3>Đội ngũ tư vấn của NEWTRUST</h3>
                <div>Luôn sẵn sàng hỗ trợ bạn mọi lúc mọi nơi</div>
                <a class="btn-lien-he-google" href="tel:+84968630869">Hotline: 0968.630.869</a>
            </div>
        </div>
    </div>
</div>
<div class="pop-up-img" *ngIf="popUpimgShow" @Faded>
    <button class="btn-x" (click)="hidePopUpImg()">X</button>
    <img src="{{URLimgPopup}}" alt="">
</div>