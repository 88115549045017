import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { RecivedInformationModel } from 'src/app/Models/ThongTinKhachHang.models';
import { ThongTinKhachHangService } from 'src/app/services/ThongTinKhachHang.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(private _thongTinKhachHangService: ThongTinKhachHangService, private _toastr: ToastrService) { }
  ThongTinKhachHang: RecivedInformationModel = new RecivedInformationModel();
  LodingLuuThongTinKhachHang = false
  EmailKhachHang: string = '';
  ngOnInit(): void {

  }
  LuuThongTinKhachHang() {
    if (!this.ValidateThognTinKhachHang()) return;

    this.LodingLuuThongTinKhachHang = true;
    this._thongTinKhachHangService.LuuThongTinKhachHang(this.ThongTinKhachHang)
      .subscribe({
        next: (res) => {
          res.Flag
            ? this._toastr.success(res.Message, 'Thành công')
            : this._toastr.warning(res.Message, 'Lỗi');
        },
        error: (err) => {
          this._toastr.error('Có lỗi xảy ra, vui lòng thử lại sau', 'Lỗi');
          console.error(err);
        },
        complete: () => {
          this.LodingLuuThongTinKhachHang = false;
          this.ThongTinKhachHang = new RecivedInformationModel();
        }
      });
  }
  ValidateThognTinKhachHang(): boolean {
    if (!this.ThongTinKhachHang.FullName || !this.ThongTinKhachHang.PhoneNumber) {
      const message = !this.ThongTinKhachHang.FullName ? 'Tên của bạn không thể để trống' : 'Vui lòng nhập số điện thoại của bạn, để chúng tôi dễ dàng liên hệ !';
      const elementId = !this.ThongTinKhachHang.FullName ? 'name' : 'phone';
      this._toastr.warning(message, 'Thông báo');
      document.getElementById(elementId).focus();
      return false;
    }
    return true;
  }

  LuuThongTinKhachHangEmail() {
    if (!this.ValidateThognTinKhachHangEmail()) return;

    let thongTinKhachHang = new RecivedInformationModel();
    thongTinKhachHang.PhoneNumber = this.EmailKhachHang;
    this._thongTinKhachHangService.LuuThongTinKhachHang(thongTinKhachHang)
      .subscribe({
        next: (res) => {
          res.Flag
            ? this._toastr.success(res.Message, 'Thành công')
            : this._toastr.warning(res.Message, 'Lỗi');
        },
        error: (err) => {
          this._toastr.error('Có lỗi xảy ra, vui lòng thử lại sau', 'Lỗi');
          console.error(err);
        },
        complete: () => {
          this.EmailKhachHang = '';
        }
      });
  }
  ValidateThognTinKhachHangEmail(): boolean {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!emailRegex.test(this.EmailKhachHang)) {
      this._toastr.warning('Vui lòng nhập một địa chỉ email hợp lệ!', 'Thông báo');
      document.getElementById('email').focus();
      return false;
    }
    return true;
  }
}
