import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { CarouselItem } from 'src/app/Share/carousel/carousel.models';

@Component({
  selector: 'app-thiet-ke-website',
  templateUrl: './thiet-ke-website.component.html',
  styleUrls: ['./thiet-ke-website.component.scss'],
  animations: [
    trigger('expandCollapse', [
      transition(':enter', [
        style({ height: 0, opacity: 0 }),
        animate('300ms ease-out', style({ height: '*', opacity: 1 }))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ height: 0, opacity: 0 }))
      ])
    ])
  ]
})
export class ThietKeWebsiteComponent implements OnInit {
  items: CarouselItem[] = [
    {
      image: '../../../../assets/img/module-website-ban-hang-tkwr-9.png',
      title: '<h4 class="custom-title">1. Trang chủ</h4>',
      description: '<p style="text-align: center; font-weight: 400; font-size: 16px;">Thanh Menu điều hướng (Menu chính + Menu nhóm con); Box sản phẩm/Dịch vụ nổi bật; Dự án nổi bật; Bài viết mới</p>'
    },
    {
      image: '../../../../assets/img/module-bo-loc-web-ban-hang-1024x722.png',
      title: '<h4 class="custom-title">2. Danh mục sản phẩm/Dịch vụ</h4>',
      description: '<p style="text-align: center; font-weight: 400; font-size: 16px;">Thể hiện đầy đủ các sản phẩm/dịch vụ cung cấp. Nếu là web bán hàng cần có bộ lọc thông minh giúp khách hàng dễ dàng tìm kiếm được sản phẩm họ cần.</p>'
    },
    {
      image: '../../../../assets/img/module-website-ban-hang-tkwr-2.png',
      title: '<h4 class="custom-title">3. Chi tiết sản phẩm/dịch vụ</h4>',
      description: '<p style="text-align: center; font-weight: 400; font-size: 16px;">Mô tả chi tiết sản phẩm; Thông số kĩ thuật sản phẩm; Hình ảnh, video sản phẩm; Giỏ hàng; Thêm – Xóa – Sửa số lượng sản phẩm; Tính chi phí</p>'
    },
    {
      image: '../../../../assets/img/module-website-ban-hang-tkwr-8.png',
      title: '<h4 class="custom-title">4. Bài viết/tin tức</h4>',
      description: '<p style="text-align: center; font-weight: 400; font-size: 16px;">Nơi cập nhật, tổng hợp, chia sẻ, cung cấp các thông tin hữu ích cho người dùng liên quan đến ngành nghề/lĩnh vực kinh doanh của đơn vị.</p>'
    },
    {
      image: '../../../../assets/img/module-website-ban-hang-tkwr-5.png',
      title: '<h4 class="custom-title">5. Giới thiệu</h4>',
      description: '<p style="text-align: center; font-weight: 400; font-size: 16px;">Câu chuyện thương hiệu; Tầm nhìn – sứ mệnh – giá trị cốt lõi; Thành tựu nổi bật; Đội ngũ nhân sự; Hình ảnh, video có liên quan</p>'
    },
    {
      image: '../../../../assets/img/module-website-ban-hang-tkwr-4.png',
      title: '<h4 class="custom-title">6. Liên hệ</h4>',
      description: '<p style="text-align: center; font-weight: 400; font-size: 16px;">Cung cấp các nút liên hệ để khách hàng có thể liên mua hàng hoặc nhận tư vấn: Hotline, Zalo, Tawkto, tích hợp live chat hoặc biểu mẫu để.</p>'
    },
    {
      image: '../../../../assets/img/module-website-ban-hang-tkwr-7.png',
      title: '<h4 class="custom-title">7. Tìm kiếm</h4>',
      description: '<p style="text-align: center; font-weight: 400; font-size: 16px;">Công cụ tìm kiếm tiện dụng giúp khách hàng dễ dàng tra cứu các sản phẩm, dịch vụ, dự án. Chức năng này đặc biệt cần có ở web bán hàng</p>'
    },
    {
      image: '../../../../assets/img/module-website-ban-hang-tkwr-3.png',
      title: '<h4 class="custom-title">8. Giỏ hàng – Thanh toán</h4>',
      description: '<p style="text-align: center; font-weight: 400; font-size: 16px;">Cần có ở website bán hàng. Cung cấp tính năng tạo giỏ hàng, thiết lập đơn hàng, lựa chọn được sản phẩm/dịch vụ, thêm bớt, đặt hàng và theo dõi tình trạng đơn hàng trực tuyến của mình. Module này có thể được tích hợp các cổng thanh toán trực tuyến.</p>'
    },
  ];
  TieuChi1 = false;
  TieuChi2 = false;
  TieuChi3 = false;
  TieuChi4 = false;
  TieuChi5 = false;
  TieuChi6 = false;
  TieuChi7 = false;
  TieuChi8 = false;
  TieuChi9 = false;
  TieuChi10 = false;
  TieuChi11 = false;
  TieuChi12 = false;
  TieuChi13 = false;
  TieuChi14 = false;
  TieuChi15 = false;
  TieuChi16 = false;
  constructor() { }

  ngOnInit(): void {
    this.sliderFunction();
  }
  ToggleTieuChi(TieuChi: string) {
    this[TieuChi] = !this[TieuChi];
  }
  sliderFunction() {
    const slider = document.querySelector('.slider-blog-details-full-1-slider') as HTMLElement;
    const dots = document.querySelectorAll('.dot') as NodeListOf<HTMLElement>;
    let isDragging = false;
    let startPos = 0;
    let currentTranslate = 0;
    let prevTranslate = 0;
    let animationID;
    let currentIndex = 0;

    // Mouse and touch events
    slider.addEventListener('mousedown', startDrag);
    slider.addEventListener('touchstart', startDrag);
    window.addEventListener('mouseup', endDrag);
    window.addEventListener('touchend', endDrag);
    window.addEventListener('mousemove', drag);
    window.addEventListener('touchmove', drag);

    function startDrag(event) {
      isDragging = true;
      startPos = getPositionX(event);
      animationID = requestAnimationFrame(animation);
      slider.style.transition = 'none';
    }

    function endDrag() {
      isDragging = false;
      cancelAnimationFrame(animationID);

      const movedBy = currentTranslate - prevTranslate;

      if (movedBy < -100 && currentIndex < slider.children.length - 1) {
        currentIndex += 1;
      }

      if (movedBy > 100 && currentIndex > 0) {
        currentIndex -= 1;
      }

      setPositionByIndex();
    }

    function drag(event) {
      if (isDragging) {
        const currentPosition = getPositionX(event);
        currentTranslate = prevTranslate + currentPosition - startPos;
      }
    }

    function getPositionX(event) {
      return event.type.includes('mouse') ? event.pageX : event.touches[0].clientX;
    }

    function animation() {
      setSliderPosition();
      if (isDragging) requestAnimationFrame(animation);
    }

    function setSliderPosition() {
      slider.style.transform = `translateX(${currentTranslate}px)`;
    }

    function setPositionByIndex() {
      currentTranslate = currentIndex * -slider.clientWidth;
      prevTranslate = currentTranslate;
      slider.style.transition = 'transform 0.3s ease-in-out';
      setSliderPosition();
      updateDots();
    }

    // Update the dots (indicators) based on currentIndex
    function updateDots() {
      dots.forEach(dot => dot.classList.remove('active'));
      dots[currentIndex].classList.add('active');
    }

    // Handle clicks on dots
    dots.forEach(dot => {
      dot.addEventListener('click', () => {
        currentIndex = parseInt(dot.dataset.index);
        setPositionByIndex();
      });
    });

    // Resize event to ensure the slider adjusts on window resize
    window.addEventListener('resize', setPositionByIndex);
  }

}
