<div class="blog-title blog-title-bg bg-2 bg-google-ads">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="blog-title-text">
                    <h2>Xây dựng kênh Tiktok</h2>
                    <ul>
                        <li>
                            <a routerLink="/index">Home</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            <a routerLink="/xay-dung-kenh-tiktok">Xây dựng kênh Tiktok</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container">
    <div class="row">
        <div class="col-lg-12">
            <div class="google-ads-content d-flex justify-content-start align-items-center flex-wrap">
                <div class="google-ads-content-item" *ngFor="let item of listFunction"
                    [ngClass]="{'active': item.active}" (click)="handleClickViewAction(item.id)">
                    <div class="item-background"></div>
                    <div class="item-content">{{item.title}}</div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="idActionTab == 1" @expandCollapse>
    <div class="container-fluid wrapper-quang-cao-google bg-google-ads-color">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <h2 class="line-before text-md-32 mb-20 text-white">Tại Sao Nên Xây Dựng Kênh TikTok?</h2>
                    <div class="text-white text-justify text-quang-cao-google">
                        <p>✔ Kinh Doanh Bán Hàng <br>
                            Nền tảng TikTok với lượng người dùng cực lớn và tăng nhanh một cách chóng mặt. Thị trường
                            người dùng tập trung vào giới trẻ, đây là nhóm khách hàng có nhu cầu mua sắm cao. </p>
                        <p>
                            ✔ Xây Dựng Thương Hiệu <br>
                            Bất kỳ nền tảng nào có được lượng người dùng lớn, bạn cần đến đó và xây dựng thương hiệu.
                            TikTok là nơi lý tưởng để bạn thực hiện mục tiêu này.
                        </p>
                        <p>
                            ✔ Kiếm Tiền Trên TikTok <br>
                            Xu hướng TikTok sẽ hỗ trợ chức năng kiếm tiền cho người làm nội dung video trên nền tảng của
                            mình. Vì vậy phát triển kênh TikTok là cần thiết.
                        </p>
                        <a class="btn-lien-he-google  mb-3" routerLink="/lien-he">Liên hệ ngay</a>
                    </div>
                </div>
                <div class="col-lg-6">
                    <img src="../../../../../assets/img/xay-kenh-tiktok.jpg" width="550px" height="320px" alt=""
                        srcset="" class="w-100">
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid  wrap-google-sosanh bg-grey">
        <div class="container">
            <div class="row text-center title-google-tien-hanh">
                <h2>BẠN ĐANG MUỐN XÂY DỰNG KÊNH TIKTOK NHƯNG...</h2>
            </div>
            <div class="row">
                <div class="col-lg-3 mb-5" *ngFor="let item of listKhichThichKhachHangChuyenDoi">
                    <div
                        class="wrapper-quy-trinh-lam-viec-minh-bach pl-0 pr-0 bg-grey d-flex flex-column align-items-center">
                        <div style="position: relative;">
                            <img src="{{item.urlImg}}" alt="">
                            <div style=" position: absolute; bottom: 0; right: 0;"><img height="30px"
                                    src="../../../../../assets/img/cancel_8532369.png" alt=""></div>
                        </div>

                        <!-- <h2 style=" color: #fc2b4ead;">{{item.buoc}}</h2> -->
                        <!-- <h4>{{item.title}}</h4> -->
                        <div style="color: red !important; ">{{item.description}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="container mt-5">
        <div class="row">
            <div class="col-12 d-flex justify-content-center flex-column align-items-center ">
                <h2 class="mb-2 mb-5">Tại sao doanh nghiệp nên chạy quảng cáo TikTok?</h2>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-3" style="margin-bottom: 24px;"
                *ngFor="let item of listTaiSaoDoanhNghiepCanQuangCaoTiktok">
                <div class="wrap-tai-sao-can-tiktok d-flex flex-column align-items-center p-5 ">
                    <div class="title-tai-sao-can-tik-tok text-center">
                        {{item.title}}
                    </div>
                    <div class="des-tai-sao-can-tik-tok text-center  ">
                        {{item.des}}
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="padding-block bg-image-mobile-none bg-light1 lazyloaded">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <h2 class="mb-30 mt-5">DỊCH VỤ XÂY KÊNH TIKTOK
                        TRỌN GÓI TẠI NEWTRUST BAO GỒM</h2>
                    <ul class="list-group-dot theme-facebook d-flex flex-column ">
                        <li>Khởi tạo và chăm sóc kênh TikTok</li>
                        <li>Lên kịch bản và xây dựng nội dung kênh TikTok</li>
                        <li>Cung cấp diễn viên và địa điểm quay phù hợp</li>
                        <li>Đạo diễn và quay dựng video chuyên nghiệp</li>
                        <li>Hỗ trợ truyền thông: đẩy mạnh view & follower cho kênh</li>
                        <li>Tư vấn chiến lược phát triển kênh TikTok</li>
                    </ul>
                </div>
                <div class="col-lg-6 d-flex align-items-center justify-content-center">
                    <img src="../../../../../assets/img/tron goi tiktok.jpg" alt="">
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid  wrap-google-sosanh bg-grey">
        <div class="container">
            <div class="row text-center title-google-tien-hanh">
                <h2 style="margin-bottom: 25px;">QUY TRÌNH XÂY DỰNG KÊNH TIKTOK TẠI NEWTRUST</h2>
            </div>
            <div class="row">
                <div class="col-lg-3 wrap-item-bang-gia" *ngFor="let item of listUuDienQuangCaoGoogle">
                    <div class="">
                        <div class="pl-0 pr-0" style="margin-right: 0px;">
                            <div class="img-thu-hut-khach-hang mb-5">
                                <img height="500px" class="w-100" src="{{item.urlImg}}" alt="">
                                <div class="title-thu-hut-khach-hang" (mouseover)="hoverTitle(item.id)">
                                    <h4>{{item.title}}</h4>
                                    <p class="text-white d-none">{{item.description}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="container-fluid  wrap-google-sosanh bg-grey">
        <div class="container">
            <div class="row text-center title-google-tien-hanh">
                <h2 style="margin-bottom: 25px;">PHÒNG MARKETING THUÊ NGOÀI CỦA NEWTRUST</h2>
                <p>Phòng Marketing Thuê Ngoài NEWTRUST là giải pháp toàn diện giúp bạn tối ưu hoá chi phí, tiết kiệm thời
                    gian và đạt hiệu suất cao trong chiến dịch marketing của mình. Với đội ngũ chuyên nghiệp và đa dạng
                    các vị trí chuyên môn, chúng tôi cam kết mang đến cho bạn sự chuyên nghiệp, hiệu quả và giúp bạn tập
                    trung vào việc phát triển kinh doanh của mình. </p>
            </div>
            <div class="row">
                <div class="col-12 d-flex align-items-center justify-content-center ">
                    <img src="../../../../../assets/img/vi-tri-phong-marketing-1024x723.webp" alt="">
                </div>
            </div>
        </div>
    </div>
    <div class="carousel-home container mb-5">
        <div class="row text-center title-google-tien-hanh">
            <h2 style="margin-bottom: 25px; margin-top: 5rem;">ĐÁNH GIÁ CỦA KHÁCH HÀNG</h2>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="service-style-three bg-white">
                    <div class="container-fluid">
                        <div class="service-slider-5item owl-carousel owl-theme">
                            <div class="service-item img-slide-home d-flex align-items-center justify-content-center flex-column "
                                *ngFor="let item of listHopTac">
                                <div class="img-khach-hang">
                                    <img src="{{item.url}}" alt="Development" class="center-img " />
                                </div>
                                <div> {{item.name}}</div>
                                <div><img src="../../../../../assets/img/pngwing.com (1).png" height="70px" alt="">
                                </div>
                                <div class="text-justify">{{item.comment}} </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="tieu-chi-web-content" id="tieuchuan">
        <div class="tc-list-box container">
            <div class="tc-list1 row ul">
                <div class="col-lg-6">
                    <div class="row">
                        <div class="col-lg-12 li">
                            <div class="tc-qs" (click)="ToggleTieuChi('TieuChi1')">
                                <label class="tc-text">Ngân sách tối thiểu cho một một gói dịch vụ xây dựng kênh
                                    TikTok?</label><br />
                                <span class="tc-number">1</span>
                            </div>
                            <div class="tc-aw TieuChi1" *ngIf="TieuChi1" @expandCollapse2>
                                <p>
                                    Hiện NEWTRUST đang triển khai hợp đồng với dịch vụ phát triển kênh TikTok với ngân
                                    sách tối thiểu 20.000.000 đ. Với khoản ngân sách này khách hàng sẽ được hỗ trợ build
                                    kênh TikTok lên mức 10.000 followers và 10 - 15 video viral.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-12 li">
                            <div class="tc-qs" (click)="ToggleTieuChi('TieuChi2')">
                                <label class="tc-text">Số lượng video được xây dựng trên kênh là bao
                                    nhiêu?</label><br />
                                <span class="tc-number">2</span>
                            </div>
                            <div class="tc-aw TieuChi2" *ngIf="TieuChi2" @expandCollapse2>
                                <p>
                                    Với mục tiêu về số lượng follower cũng như mức độ nhận diện thương hiệu trên TikTok,
                                    chúng tôi sẽ cân nhắc lên kế hoạch xây dựng video để hoàn thành mục tiêu trên. Sẽ
                                    tùy vào gói, nhưng thường với mỗi kênh TikTok nhận xây dựng và phát triển như vậy
                                    chúng tôi sẽ xây dựng tối thiểu 10 video viral.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="row">
                        <div class="col-lg-12 li">
                            <div class="tc-qs" (click)="ToggleTieuChi('TieuChi6')">
                                <label class="tc-text">Thời gian triển khai dịch vụ phát triển kênh TikTok bao
                                    lâu?</label><br />
                                <span class="tc-number">3</span>
                            </div>
                            <div class="tc-aw TieuChi6" *ngIf="TieuChi6" @expandCollapse2>
                                <p>
                                    Tùy vào yêu cầu khách hàng về kênh TikTok mà chúng tôi sẽ đưa ra mốc thời gian cam
                                    kết. Ví dụ với mục tiêu đưa kênh TikTok chạm mốc 100.000 follower thì thời gian
                                    thường rơi vào khoảng 3 tháng.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-12 li">
                            <div class="tc-qs" (click)="ToggleTieuChi('TieuChi7')">
                                <label class="tc-text">Tôi được lựa chọn chủ đề nội dung video trên kênh
                                    không?</label><br />
                                <span class="tc-number">4</span>
                            </div>
                            <div class="tc-aw TieuChi7" *ngIf="TieuChi7" @expandCollapse2>
                                <p>Có. Khách hàng được phép yêu cầu chúng tôi xây dựng video trên kênh theo chủ đề mong
                                    muốn. Tuy nhiên yếu tố này có thể tác động đến giá dịch vụ phát triển kênh TikTok.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <div class="container-fluid wraper-doi-ngu-tu-van">
        <div class="container">
            <div class="row">
                <div class="col-12 wrap-content-doi-ngu-tu-van">
                    <h3>Đội ngũ tư vấn của NEWTRUST</h3>
                    <div>Luôn sẵn sàng hỗ trợ bạn mọi lúc mọi nơi</div>
                    <a class="btn-lien-he-google" href="tel:+84968630869">Hotline: 0968.630.869</a>
                </div>
            </div>
        </div>
    </div>
    <div class="pop-up-img" *ngIf="popUpimgShow" @Faded>
        <button class="btn-x" (click)="hidePopUpImg()">X</button>
        <img src="{{URLimgPopup}}" alt="">
    </div>