import { Directive, ElementRef, Input, Renderer2, OnChanges, SimpleChanges, OnInit } from '@angular/core';

@Directive({
    selector: '[appLoading]'
})
export class LoadingDirective implements OnChanges, OnInit {

    @Input() appLoading: boolean = false; // Biến điều kiện hiển thị spinner
    private spinnerElement: HTMLElement | null = null;

    constructor(private el: ElementRef, private renderer: Renderer2) { }
    private spinnerHtml = `
    /* From Uiverse.io by Li-Deheng */ 
        <div class="loader">
        <div class="circle">
            <div class="dot"></div>
            <div class="outline"></div>
        </div>
        <div class="circle">
            <div class="dot"></div>
            <div class="outline"></div>
        </div>
        <div class="circle">
            <div class="dot"></div>
            <div class="outline"></div>
        </div>
        <div class="circle">
            <div class="dot"></div>
            <div class="outline"></div>
        </div>
        </div>
  `;
    private spinnerElementHTML: HTMLElement | null = null;
    ngOnInit(): void {
        console.log('direc')
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['appLoading']) {
            console.log('direc change')
            this.toggleLoading();
        }
    }


    private toggleLoading() {
        if (this.appLoading) {
            if (!this.spinnerElement) {
                this.spinnerElement = this.renderer.createElement('div');
                this.spinnerElementHTML.innerHTML = this.spinnerHtml;
                this.el.nativeElement.appendChild(this.spinnerElementHTML);
            }
        } else {
            if (this.spinnerElement) {
                this.renderer.removeChild(this.el.nativeElement, this.spinnerElement);
                this.spinnerElement = null;
            }
        }
    }
}
