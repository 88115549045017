<div class="blog-title blog-title-bg bg-2">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="blog-title-text">
          <h2>Thiết kế Ứng dụng di động</h2>
          <ul>
            <li>
              <a routerLink="/index">Trang chủ</a>
            </li>
            <li>
              <i class="icofont-rounded-double-right"></i>
              <a routerLink="/tin-tuc">Giải pháp công nghệ</a>
            </li>
            <li>
              <i class="icofont-rounded-double-right"></i>
              Thiết kế Ứng dụng di động
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="page-warper">
  <div class="inner-warper lazyloaded">
    <section class="nft-main-banner-sec lazyloaded">
      <div class="container lazyloaded">
        <div class="row lazyloaded">
          <div class="col-md-6 lazyloaded">
            <div class="nft-banner-head-info lazyloaded">
              <div class="nft-banner-text-in lazyloaded">
                <h1 class="lazyloaded">Dịch vụ Thiết kế Ứng dụng Di động</h1>
                <p class="lazyloaded">
                  Tại Newtrust, đội ngũ thiết kế ứng dụng di động của chúng tôi thổi hồn 
                  vào ý tưởng ứng dụng của bạn, đảm bảo rằng nó phù hợp và thu hút đối tượng khách hàng mà bạn nhắm đến. 
                  Từ giao diện ứng dụng tinh tế đến trải nghiệm mượt mà, chúng tôi kết hợp hài hòa giữa 
                  giao diện và tính năng để mang đến trải nghiệm tối ưu cho người dùng
                </p>
                <p class="lazyloaded">
                  Hãy liên hệ ngay với chúng tôi và tận dụng các dịch vụ thiết kế và phát triển ứng dụng di động hàng đầu với mức giá hợp lý. 
                  Đã đến lúc nói lời tạm biệt với những thiết kế lỗi thời và chuyển sang một ứng dụng di động hiện đại với Newtrust ngay hôm nay!
                </p>
              </div>
              <div class="price-card-area active-price">
              <div class="plan-btn">
                <a routerLink="/lien-he">Tư vấn</a>
              </div>
            </div>

            </div>
          </div>
          <div class="col-md-5 ml-atuo lazyloaded">
            <div class="banner-inner-1 lazyloaded">
              <img
                width="444"
                height="532"
                alt="Dev Technosys"
                class="ls-is-cached lazyloaded"
                src="https://devtechnosys.com/images/2023/services/mobile-app-design-services/nfi-top-img.webp"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="connect-with-sec lazyloaded">
      <div class="container lazyloaded">
        <div class="connect-with lazyloaded">
          <ul class="partners-list">
            <li class="lazyloaded">
              <a href="javascript:;" class="lazyloaded">
                <img
                  class="ls-is-cached lazyloaded"
                  data-src="https://devtechnosys.com/images/2021-new/logo01.webp"
                  alt="Dev Technosys"
                  width="115"
                  height="115"
                  src="https://devtechnosys.com/images/2021-new/logo01.webp"
                />
              </a>
            </li>
            <li class="lazyloaded">
              <a href="javascript:;" class="lazyloaded">
                <img
                  class="ls-is-cached lazyloaded"
                  data-src="https://devtechnosys.com/images/2021-new/logo02.webp"
                  alt="Dev Technosys"
                  width="115"
                  height="115"
                  src="https://devtechnosys.com/images/2021-new/logo02.webp"
                />
              </a>
            </li>
            <li class="lazyloaded">
              <a href="javascript:;" class="lazyloaded">
                <img
                  class="ls-is-cached lazyloaded"
                  data-src="https://devtechnosys.com/images/2021-new/logo03.webp"
                  alt="Dev Technosys"
                  width="115"
                  height="115"
                  src="https://devtechnosys.com/images/2021-new/logo03.webp"
                />
              </a>
            </li>
            <li class="lazyloaded">
              <a href="javascript:;" class="lazyloaded">
                <img
                  class="ls-is-cached lazyloaded"
                  data-src="https://devtechnosys.com/images/2021-new/logo04.webp"
                  alt="Dev Technosys"
                  width="115"
                  height="115"
                  src="https://devtechnosys.com/images/2021-new/logo04.webp"
                />
              </a>
            </li>
            <li class="lazyloaded">
              <a href="javascript:;" class="lazyloaded">
                <img
                  class="ls-is-cached lazyloaded"
                  data-src="https://devtechnosys.com/images/2021-new/logo05.webp"
                  alt="Dev Technosys"
                  width="115"
                  height="115"
                  src="https://devtechnosys.com/images/2021-new/logo05.webp"
                />
              </a>
            </li>
            <li class="lazyloaded">
              <a href="javascript:;" class="lazyloaded">
                <img
                  class="ls-is-cached lazyloaded"
                  data-src="https://devtechnosys.com/images/2021-new/logo06.webp"
                  alt="Dev Technosys"
                  width="115"
                  height="115"
                  src="https://devtechnosys.com/images/2021-new/logo06.webp"
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
    <section class="section-padding launch-marketplace-main lazyloaded">
      <div class="container lazyloaded">
        <div class="main-heading-box main-heading-box-inner lazyloaded">
          <h2 class="lazyloaded">
            Get Unique Mobile App UI UX Design Services for Android &amp; iOS
          </h2>
        </div>
        <div class="row launch-marketplace-box align-items-center lazyloaded">
          <div class="col-md-5 lazyloaded">
            <article class="lazyloaded">
              <img
                class="ls-is-cached lazyloaded"
                data-src="https://devtechnosys.com/images/2023/services/mobile-app-design-services/nft_memes.webp"
                alt="Dev Technosys"
                width="463"
                height="463"
                src="https://devtechnosys.com/images/2023/services/mobile-app-design-services/nft_memes.webp"
              />
            </article>
          </div>
          <div class="col-md-6 ml-auto lazyloaded">
            <article class="lazyloaded">
              <p class="lazyloaded">
                Elevate your mobile app experience with our user-friendly mobile
                app UI UX design services. Are you ready to transformyour mobile
                app's potential? Look no further than Dev Technosys!
              </p>
              <p>
                You should connect with our iOS app designers, available for
                hire. They creates stunning user interfaces and experiences
                (UI/UX) for both Android and iOS devices. Our app design agency
                creates unique apps that captivate your target audience. Let's
                craft something amazing together.
              </p>
              <p>
                Our ios app design agency take your figma app design to the
                pinnacle of success and beyond. Seek our app design services for
                iOS and Android now!
              </p>
              <div class="btn-mar lazyloaded">
                <a
                  href="javascript:;"
                  class="btn btn-purple get-consultant lazyloaded"
                  >Let’s Connect!</a
                >
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
    <section class="looking-for lazyloaded">
      <div class="container lazyloaded">
        <div class="row lazyloaded">
          <div class="col-md-5 looking-for-L lazyloaded">
            <img
              class="ls-is-cached lazyloaded"
              data-src="https://devtechnosys.com/images/2022/nft-marketplace-development/app-dev-girl.webp"
              alt="Dev Technosys"
              width="468"
              height="468"
              src="https://devtechnosys.com/images/2022/nft-marketplace-development/app-dev-girl.webp"
            />
          </div>
          <div class="col-md-6 looking-for-R lazyloaded">
            <div class="text-left lazyloaded">
              <h2 class="lazyloaded">
                Transform Your App Vision Into Reality With Our Mobile App
                Design Services!
              </h2>
            </div>
            <a
              href="javascript:;"
              class="btn black-bg btn-purple get-consultant lazyloaded"
              >Hire Mobile App Designers</a
            >
          </div>
        </div>
      </div>
    </section>
    <section class="section-padding nft-services lazyloaded">
      <div class="container lazyloaded">
        <div class="main-heading-box main-heading-box-inner lazyloaded">
          <h2 class="lazyloaded">
            Best Mobile App Design Services by Creative Heads
          </h2>
          <p class="lazyloaded">
            Do you want a unique mobile app UX UI design that attracts more and
            more users? Seek top-notch mobile application design services from
            Dev Technosys. Our mobile app design company hold expertise in
            creating innovative and user-centric mobile applications. Here’s a
            glimpse of the services offered by our mobile app design company!
          </p>
        </div>
        <div class="row lazyloaded">
          <div class="col-md-4 lazyloaded">
            <a href="javascript:;" class="lazyloaded">
              <span class="services-shape purple-shape lazyloaded"></span>
              <div class="nft-services-box lazyloaded">
                <figure class="purple-bg lazyloaded">
                  <img
                    class="ls-is-cached lazyloaded"
                    data-src="https://devtechnosys.com/images/2023/services/mobile-app-design-services/NFT-Marketplace-1.svg"
                    alt="Android App Development Consultation"
                    width="27"
                    height="30"
                    src="https://devtechnosys.com/images/2023/services/mobile-app-design-services/NFT-Marketplace-1.svg"
                  />
                </figure>
                <figcaption class="lazyloaded">
                  <h3 class="lazyloaded">Mobile App Design Consultation</h3>
                  <p class="lazyloaded">
                    Do you want to design an attractive business application and
                    need professional assistance? Avail mobile app design
                    consultation services from Dev Technosys. Our mobile app
                    design agency offer the best guidance to enhance your app's
                    UX UI. From concept evaluation to design strategy, we'll
                    ensure your app captivates users with an exclusive
                    interface.
                  </p>
                </figcaption>
              </div>
            </a>
          </div>
          <div class="col-md-4 lazyloaded">
            <a href="javascript:;" class="lazyloaded">
              <span class="services-shape blue-shape lazyloaded"></span>
              <div class="nft-services-box lazyloaded">
                <figure class="blue-bg lazyloaded">
                  <img
                    class="ls-is-cached lazyloaded"
                    data-src="https://devtechnosys.com/images/2023/services/mobile-app-design-services/NFT-Marketplace-2.svg"
                    alt="Custom Android App Development"
                    width="30"
                    height="30"
                    src="https://devtechnosys.com/images/2023/services/mobile-app-design-services/NFT-Marketplace-2.svg"
                  />
                </figure>
                <figcaption class="lazyloaded">
                  <h3 class="lazyloaded">Custom Mobile App UX UI Design</h3>
                  <p class="lazyloaded">
                    Get a completely customized mobile app design within a
                    reasonable budget. Our custom mobile app design services for
                    iOS and Android offer a seamless and intuitive user
                    experience. Our mobile app design agency focus on building
                    engaging, user-centric designs aligning with brand identity.
                    Discuss your requirements for mobile app design and leave
                    the rest to our team.
                  </p>
                </figcaption>
              </div>
            </a>
          </div>
          <div class="col-md-4 lazyloaded">
            <a href="javascript:;" class="lazyloaded">
              <span class="services-shape orange-shape lazyloaded"></span>
              <div class="nft-services-box lazyloaded">
                <figure class="orange-bg lazyloaded">
                  <img
                    class="ls-is-cached lazyloaded"
                    data-src="https://devtechnosys.com/images/2023/services/mobile-app-design-services/NFT-Marketplace-3.svg"
                    alt="Android Game App Development"
                    width="30"
                    height="30"
                    src="https://devtechnosys.com/images/2023/services/mobile-app-design-services/NFT-Marketplace-3.svg"
                  />
                </figure>
                <figcaption class="lazyloaded">
                  <h3 class="lazyloaded">Android App Design</h3>
                  <p class="lazyloaded">
                    Our Android app design services cater to an audience's
                    specific needs, ensuring a user-friendly and pleasing
                    interface. Our skilled designers for hire create Figma app
                    design which is visually striking layouts adhering to
                    Android design guidelines. Let us transform your app idea
                    into a beautiful Android app design. Connect with our mobile
                    app design company.
                  </p>
                </figcaption>
              </div>
            </a>
          </div>
          <div class="col-md-4 lazyloaded">
            <a href="javascript:;" class="lazyloaded">
              <span class="services-shape green-shape lazyloaded"></span>
              <div class="nft-services-box lazyloaded">
                <figure class="green-bg lazyloaded">
                  <img
                    class="ls-is-cached lazyloaded"
                    data-src="https://devtechnosys.com/images/2023/services/mobile-app-design-services/NFT-Marketplace-4.svg"
                    alt="Android App Designing Services"
                    width="30"
                    height="30"
                    src="https://devtechnosys.com/images/2023/services/mobile-app-design-services/NFT-Marketplace-4.svg"
                  />
                </figure>
                <figcaption class="lazyloaded">
                  <h3 class="lazyloaded">iOS App Design</h3>
                  <p class="lazyloaded">
                    Looking for result-oriented iOS app design services? You are
                    at the right place. We offer a seamless and visually
                    appealing user experience, ensuring your app stands out. Our
                    experts follow a streamlined mobile app design process to
                    create intuitive interfaces and a user-centric approach to
                    enhance engagement and drive success.
                  </p>
                </figcaption>
              </div>
            </a>
          </div>
          <div class="col-md-4 lazyloaded">
            <a href="javascript:;" class="lazyloaded">
              <span class="services-shape lightblue-shape lazyloaded"></span>
              <div class="nft-services-box lazyloaded">
                <figure class="lightblue-bg lazyloaded">
                  <img
                    class="ls-is-cached lazyloaded"
                    data-src="https://devtechnosys.com/images/2023/services/mobile-app-design-services/NFT-Marketplace-5.svg"
                    alt="Android Software Testing"
                    width="31"
                    height="31"
                    src="https://devtechnosys.com/images/2023/services/mobile-app-design-services/NFT-Marketplace-5.svg"
                  />
                </figure>
                <figcaption class="lazyloaded">
                  <h3 class="lazyloaded">UX Design Services</h3>
                  <p class="lazyloaded">
                    Our UX design services offer a comprehensive approach to
                    enhancing user experiences. We employ user research,
                    wireframing, prototyping, and usability testing to create
                    intuitive and engaging interfaces. Our expert mobile app
                    design agency focus on user-centric design principles to
                    deliver products driving customer satisfaction and boosting
                    business success.
                  </p>
                </figcaption>
              </div>
            </a>
          </div>
          <div class="col-md-4 lazyloaded">
            <a href="javascript:;" class="lazyloaded">
              <span class="services-shape pink-shape lazyloaded"></span>
              <div class="nft-services-box lazyloaded">
                <figure class="pink-bg lazyloaded">
                  <img
                    class="ls-is-cached lazyloaded"
                    data-src="https://devtechnosys.com/images/2023/services/mobile-app-design-services/NFT-Marketplace-6.svg"
                    alt="Android App Support &amp; Maintenance Services"
                    width="31"
                    height="31"
                    src="https://devtechnosys.com/images/2023/services/mobile-app-design-services/NFT-Marketplace-6.svg"
                  />
                </figure>
                <figcaption class="lazyload">
                  <h3 class="lazyload">App Wireframing &amp; Prototyping</h3>
                  <p class="lazyload">
                    At Dev Technosys, we provide a solid foundation for the
                    app's development. Our mobile app designers meticulously
                    craft detailed wireframes and interactive prototypes mapping
                    the app's layout and functionality. This iterative process
                    lets you visualize the user flow, identify potential issues
                    early on, and make informed design decisions.
                  </p>
                </figcaption>
              </div>
            </a>
          </div>
          <div class="col-md-4 lazyload">
            <a href="javascript:;" class="lazyload">
              <span class="services-shape orange-shape lazyload"></span>
              <div class="nft-services-box lazyload">
                <figure class="orange-bg lazyload">
                  <img
                    class="lazyload"
                    data-src="https://devtechnosys.com/images/2023/services/mobile-app-design-services/NFT-Marketplace-7.svg"
                    alt="Hire Android App Developers"
                    width="31"
                    height="31"
                  />
                </figure>
                <figcaption class="lazyload">
                  <h3 class="lazyload">Mobile App Design Testing</h3>
                  <p class="lazyload">
                    A carefully tested mobile app design can make or break your
                    application’s success. Our skilled testers conduct
                    comprehensive tests to identify potential issues and gather
                    user feedback. Our mobile app design testing services
                    guarantee a seamless user experience, leading to a polished
                    and successful mobile app. Get in touch with us today!
                  </p>
                </figcaption>
              </div>
            </a>
          </div>
          <div class="col-md-4 lazyload">
            <a href="javascript:;" class="lazyload">
              <span class="services-shape purple-shape lazyload"></span>
              <div class="nft-services-box lazyload">
                <figure class="purple-bg lazyload">
                  <img
                    class="lazyload"
                    data-src="https://devtechnosys.com/images/2023/services/mobile-app-design-services/NFT-Marketplace-8.svg"
                    alt="Android App Porting &amp; Upgradation Services"
                    width="31"
                    height="31"
                  />
                </figure>
                <figcaption class="lazyload">
                  <h3 class="lazyload">Hire Mobile App Designers</h3>
                  <p class="lazyload">
                    Do you want to hire mobile app designers who can create an
                    attention-grabbing design for your business app? Connect
                    with Dev Technosys, a mobile app design and development
                    services provider. We have a pool of mobile app designers
                    for hire who can be your business saviour. Now, let your
                    mobile app design rule every user’s mind at just a glance.
                  </p>
                </figcaption>
              </div>
            </a>
          </div>
          <div class="col-md-4 lazyload">
            <a href="javascript:;" class="lazyload">
              <span class="services-shape blue-shape lazyload"></span>
              <div class="nft-services-box lazyload">
                <figure class="blue-bg lazyload">
                  <img
                    class="lazyload"
                    data-src="https://devtechnosys.com/images/2023/services/mobile-app-design-services/NFT-Marketplace-9.svg"
                    alt="Multi-Platform Deployment"
                    width="31"
                    height="31"
                  />
                </figure>
                <figcaption class="lazyload">
                  <h3 class="lazyload">Mobile App Support &amp; Maintenance</h3>
                  <p class="lazyload">
                    Besides mobile app design and development, maintenance also
                    matters. Our mobile app design team ensures to update mobile
                    app design as per the latest market standards and trends.
                    Drop your mobile app support and maintenance services
                    requirements and get a perfectly updated mobile app. Book a
                    consultation with our mobile app design company today!
                  </p>
                </figcaption>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
    <section class="range-devlopment-bg section-padding light-pink-bg">
      <div class="container">
        <div class="main-heading-box main-heading-box-inner">
          <h2>Benefits of Appealing Mobile App UI UX Design Services</h2>
          <p>
            Appealing mobile app design enhances user engagement and increases
            retention rates. A feature-rich mobile app design fosters a positive
            brand perception and boosts app downloads. Here’s a glimpse of the
            benefits of mobile app UI UX design services.
          </p>
        </div>
        <div class="parts-include-bg">
          <div class="row parts-include-list">
            <div class="col-md-4">
              <div class="parts-include-box">
                <h4>Enhanced User Experience</h4>
                <p>
                  Intuitive and user-friendly mobile app interfaces result in
                  positive experiences, increasing customer satisfaction and
                  loyalty.
                </p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="parts-include-box">
                <h4>Higher Conversion Rates</h4>
                <p>
                  A well-optimized mobile app UI UX leads to higher conversions
                  and better business outcomes. An attractive figma app design
                  can double business conversions.
                </p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="parts-include-box">
                <h4>Increased User Engagement</h4>
                <p>
                  A visually attractive Figma app design encourages users to
                  spend more time on the application. It resulting exploring its
                  features and content.
                </p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="parts-include-box">
                <h4>Competitive Advantage</h4>
                <p>
                  An app with eye-catching design help businesses gain a
                  competitive edge over businesses with overly complicated
                  mobile applications.
                </p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="parts-include-box">
                <h4>Improved Brand Perception</h4>
                <p>
                  A polished and aesthetically pleasing mobile app UX UI
                  reflects positivity on the brand and its credibility. It
                  further improves brand perception.
                </p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="parts-include-box">
                <h4>Increased Revenue</h4>
                <p>
                  A better user experience leads to higher in-app purchases,
                  subscriptions, and ad revenue. Seek the best mobile app UI UX
                  design services from us.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section-padding addon-sec">
      <div class="container">
        <div class="main-heading-box main-heading-box-inner">
          <h2>Discover The Cost Of Our Budget-Friendly Web Design Services</h2>
        </div>
        <div class="row launch-marketplace-box align-items-center">
          <div class="col-md-12 ml-auto">
            <article>
              <p>
                Our web design services range from $8,000 to $25,000. Our
                flexible pricing lets you achieve ambitious goals and find the
                perfect fit for your budget. You might also get top-class
                solutions customized to your desires at affordable cost.
              </p>
              <p>
                We design powerful mobile apps at affordable prices! It lets
                businesses of all sizes, from startups to big companies, build
                their dream app without stretching their budget. Connect with
                our experts to maximize the possibilities of your online
                presence at affordable rates.
              </p>
              <div class="btn-mar">
                <a href="javascript:;" class="btn btn-purple get-consultant"
                  >Connect With Experts!</a
                >
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
    <section class="home-industries section-padding lazyload">
      <div class="container lazyload">
        <div class="main-heading-box main-heading-box-inner lazyload">
          <h2 class="lazyload">
            Mobile App Design Services for All Business Verticals
          </h2>
          <p class="lazyload">
            Irrespective of the business domain, Dev Technosys offers
            industry-best mobile app design services for iOS and Android. Our
            Figma app design services ensure that your website truly reflects
            your business while also elevating your online presence. Here is the
            wide range of business verticals we serve!
          </p>
        </div>
        <div class="industries-images lazyload">
          <div class="row lazyload">
            <div class="col-md-3 col lazyload">
              <a
                class="lazyload"
                href="https://devtechnosys.com/education-app-development.php"
              >
                <figure class="lazyload">
                  <img
                    class="lazyload"
                    alt="Dev Technosys"
                    data-src="https://devtechnosys.com/images/2021-new/industries1.jpg"
                  />
                </figure>
                <figcaption class="lazyload">Education</figcaption>
              </a>
            </div>
            <div class="col-md-4 col lazyload">
              <a
                class="lazyload"
                href="https://devtechnosys.com/food-delivery-app-development.php"
              >
                <figure class="lazyload">
                  <img
                    class="lazyload"
                    alt="Dev Technosys"
                    data-src="https://devtechnosys.com/images/2021-new/industries2.jpg"
                  />
                </figure>
                <figcaption class="lazyload">Food &amp; Restaurant</figcaption>
              </a>
            </div>
            <div class="col-md-2 col lazyload">
              <a
                class="lazyload"
                href="https://devtechnosys.com/travel-app-development.php"
              >
                <figure class="lazyload">
                  <img
                    class="lazyload"
                    alt="Dev Technosys"
                    data-src="https://devtechnosys.com/images/2021-new/industries3.jpg"
                  />
                </figure>
                <figcaption class="lazyload">Travel &amp; Tours</figcaption>
              </a>
            </div>
            <div class="col-md-3 col lazyload">
              <a
                class="lazyload"
                href="https://devtechnosys.com/healthcare-app-development.php"
              >
                <figure class="lazyload">
                  <img
                    class="lazyload"
                    alt="Dev Technosys"
                    data-src="https://devtechnosys.com/images/2021-new/industries4.jpg"
                  />
                </figure>
                <figcaption class="lazyload">Healthcare</figcaption>
              </a>
            </div>
            <div class="col-md-3 col lazyload">
              <a
                class="lazyload"
                href="https://devtechnosys.com/industry-game.php"
              >
                <figure class="lazyload">
                  <img
                    class="lazyload"
                    alt="Dev Technosys"
                    data-src="https://devtechnosys.com/images/2021-new/industries5.jpg"
                  />
                </figure>
                <figcaption class="lazyload">Gaming</figcaption>
              </a>
            </div>
            <div class="col-md-6 col lazyload">
              <a
                class="lazyload"
                href="https://devtechnosys.com/ecommerce-development-company.php"
              >
                <figure class="lazyload">
                  <img
                    class="lazyload"
                    alt="Dev Technosys"
                    data-src="https://devtechnosys.com/images/2021-new/industries6.jpg"
                  />
                </figure>
                <figcaption class="lazyload">Ecommerce</figcaption>
              </a>
            </div>
            <div class="col-md-3 col lazyload">
              <a
                class="lazyload"
                href="https://devtechnosys.com/marketplace-development.php"
              >
                <figure class="lazyload">
                  <img
                    class="lazyload"
                    alt="Dev Technosys"
                    data-src="https://devtechnosys.com/images/2021-new/industries7.jpg"
                  />
                </figure>
                <figcaption class="lazyload">Service Marketplace</figcaption>
              </a>
            </div>
            <div class="col-md-3 col lazyload">
              <a
                class="lazyload"
                href="https://devtechnosys.com/industry-automotives-solutions.php"
              >
                <figure class="lazyload">
                  <img
                    class="lazyload"
                    alt="Dev Technosys"
                    data-src="https://devtechnosys.com/images/2021-new/industries8.jpg"
                  />
                </figure>
                <figcaption class="lazyload">Automotives</figcaption>
              </a>
            </div>
            <div class="col-md-2 col lazyload">
              <a
                class="lazyload"
                href="https://devtechnosys.com/mobile-banking-app-development.php"
              >
                <figure class="lazyload">
                  <img
                    class="lazyload"
                    alt="Dev Technosys"
                    data-src="https://devtechnosys.com/images/2021-new/industries9.jpg"
                  />
                </figure>
                <figcaption class="lazyload">Banking</figcaption>
              </a>
            </div>
            <div class="col-md-4 col lazyload">
              <a
                class="lazyload"
                href="https://devtechnosys.com/real-estate-app-development.php"
              >
                <figure class="lazyload">
                  <img
                    class="lazyload"
                    alt="Dev Technosys"
                    data-src="https://devtechnosys.com/images/2021-new/industries10.jpg"
                  />
                </figure>
                <figcaption class="lazyload">Real Estate</figcaption>
              </a>
            </div>
            <div class="col-md-3 col lazyload">
              <a
                class="lazyload"
                href="https://devtechnosys.com/industry-on-demand.php"
              >
                <figure class="lazyload">
                  <img
                    class="lazyload"
                    alt="Dev Technosys"
                    data-src="https://devtechnosys.com/images/2021-new/industries11.jpg"
                  />
                </figure>
                <figcaption class="lazyload">On-demand</figcaption>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="cta-style-4 lazyload">
      <div class="container lazyload">
        <div class="row lazyload">
          <div class="col-md-7 lazyload">
            <div class="section-padding lazyload">
              <div class="main-heading-box mb-0 lazyload">
                <h2 class="lazyload">
                  Want to Hire Mobile App Designers to Build High Quality App UI
                  UX Design?
                </h2>
                <a
                  href="https://devtechnosys.com/request-a-quote.php"
                  class="btn btn-white lazyload"
                  >Talk to Experts</a
                >
              </div>
            </div>
          </div>
          <div class="col-md-5 lazyload">
            <div class="cta-style-2-imgbox lazyload">
              <img
                class="lazyload"
                alt="Dev Technosys"
                data-src="https://devtechnosys.com/images/2022/blockchain-development-company/man-sitting-in-front-of-laptop.png"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="case-home section-padding lazyload">
      <div class="container lazyload">
        <div class="main-heading-box main-heading-box-inner lazyload">
          <h2 class="lazyload">Our Portfolio</h2>
          <p class="lazyload">
            For years Dev Technosys has delivered Websites and Applications that
            have worked as the text books for other to follow in the tech
            domain. Some of these highly precious products- the fruits of our
            exceptional acumen in technology and breakthrough solutions are:
          </p>
        </div>
        <div class="row service-portfolio-blk lazyload">
          <div class="col-md-4 lazyload">
            <article class="lazyload">
              <figcaption class="lazyload">
                <h4 class="lazyload">Car Rental Near Me APP</h4>
                <span class="lazyload"
                  >The Rental24H.com car rental app helps you find the best car
                  rental deals from a wide range of companies around the
                  world....</span
                >
                <div class="btn-link lazyload">
                  <a
                    href="javascript:;"
                    class="btn-link-arrow lazyload"
                    data-toggle="modal"
                    data-target="#Wall169"
                    >Learn more<img
                      class="lazyload"
                      alt="Dev Technosys"
                      data-src="https://devtechnosys.com/images/2021-new/arrow-btn.svg"
                      width="30"
                      height="30"
                  /></a>
                </div>
              </figcaption>
              <figure class="lazyload">
                <img
                  class="lazyload"
                  data-src="images/portfolioImg/listing-portfolio%20247%20(1).webp"
                  alt="Dev Technosys"
                />
              </figure>
            </article>
          </div>
          <div class="FullScreenPop lazyload">
            <div
              class="modal fade lazyload"
              id="Wall169"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
              style="display: none"
            >
              <div class="modal-dialog lazyload">
                <div class="modal-content lazyload">
                  <div class="modal-header lazyload">
                    <button
                      aria-label="Close"
                      data-dismiss="modal"
                      class="lazyload close"
                    >
                      <span>×</span>
                    </button>
                  </div>
                  <div
                    class="modal-body tea-bg PortBgSection PortBgStyle1 lazyload"
                    style="
                      background: url(images/portfolioImg/Gogo247.webp)
                        no-repeat;
                      background-size: cover;
                    "
                  >
                    <div class="row lazyload">
                      <div class="col-md-6 Por-md-images lazyload">
                        <article class="lazyload">
                          <img
                            class="lazyload"
                            data-src="images/portfolioImg/port-inner-images.webp"
                            alt="Dev Technosys"
                          />
                        </article>
                      </div>
                      <div class="col-md-6 Por-md-contain lazyload">
                        <article class="lazyload">
                          <h6 class="lazyload">
                            <b class="lazyload">RENTAL24H.com</b> Car Rental
                          </h6>
                          <p class="lazyload">
                            The Rental24H.com car rental app helps you find the
                            best car rental deals from a wide range of companies
                            around the world.
                          </p>
                          <div class="TechnologyTag lazyload">
                            <span>Android</span>
                            <span>Node js</span>
                          </div>
                          <div class="PortLinks lazyload">
                            <a
                              rel="noreferrer noopener nofollow"
                              href="https://play.google.com/store/apps/details?id=com.rental24h.rental24h&amp;hl=en"
                              target="_blank"
                              class="lazyload"
                              ><i
                                class="lazyload fa fa-android"
                                aria-hidden="true"
                              ></i>
                              Android</a
                            >
                          </div>
                        </article>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 lazyload">
            <article class="lazyload">
              <figcaption class="lazyload">
                <h4 class="lazyload">
                  Fleet Management, Find Truck Loads - Load Board
                </h4>
                <span class="lazyload"
                  >Looking for truck loads and great trucking tools to help you
                  succeed? 123Loadboard.comâ€™s load board app helps
                  professional truck drivers find loads and freight to haul
                  throughout the U.S. and Canada. ...</span
                >
                <div class="btn-link lazyload">
                  <a
                    href="javascript:;"
                    class="btn-link-arrow lazyload"
                    data-toggle="modal"
                    data-target="#Wall136"
                    >Learn more<img
                      class="lazyload"
                      alt="Dev Technosys"
                      data-src="https://devtechnosys.com/images/2021-new/arrow-btn.svg"
                      width="30"
                      height="30"
                  /></a>
                </div>
              </figcaption>
              <figure class="lazyload">
                <img
                  class="lazyload"
                  data-src="images/portfolioImg/123-list.webp"
                  alt="Dev Technosys"
                />
              </figure>
            </article>
          </div>
          <div class="FullScreenPop lazyload">
            <div
              class="modal fade lazyload"
              id="Wall136"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
              style="display: none"
            >
              <div class="modal-dialog lazyload">
                <div class="modal-content lazyload">
                  <div class="modal-header lazyload">
                    <button
                      aria-label="Close"
                      data-dismiss="modal"
                      class="lazyload close"
                    >
                      <span>×</span>
                    </button>
                  </div>
                  <div
                    class="modal-body tea-bg PortBgSection PortBgStyle1 lazyload"
                    style="
                      background: url(images/portfolioImg/123-bg.webp) no-repeat;
                      background-size: cover;
                    "
                  >
                    <div class="row lazyload">
                      <div class="col-md-6 Por-md-images lazyload">
                        <article class="lazyload">
                          <img
                            class="lazyload"
                            data-src="images/portfolioImg/123-img.webp"
                            alt="Dev Technosys"
                          />
                        </article>
                      </div>
                      <div class="col-md-6 Por-md-contain lazyload">
                        <article class="lazyload">
                          <h6 class="lazyload">
                            <b class="lazyload"
                              >Find Truck Loads - Load Board</b
                            >
                            Fleet Management - Load Board
                          </h6>
                          <p class="lazyload">
                            Looking for truck loads and great trucking tools to
                            help you succeed? 123Loadboard.comâ€™s load board
                            app helps professional truck drivers find loads and
                            freight to haul throughout the U.S. and Canada.
                          </p>
                          <div class="TechnologyTag lazyload">
                            <span>Android</span>
                            <span>iOS</span>
                            <span>Swift</span>
                            <span>Objective C</span>
                          </div>
                          <div class="PortLinks lazyload">
                            <a
                              rel="noreferrer noopener nofollow"
                              href="https://play.google.com/store/apps/details?id=com.app.loadboard&amp;hl=en"
                              target="_blank"
                              class="lazyload"
                              ><i
                                class="lazyload fa fa-android"
                                aria-hidden="true"
                              ></i>
                              Android</a
                            >
                            <a
                              rel="noreferrer noopener nofollow"
                              href="https://apps.apple.com/mx/app/123loadboard/id827794122"
                              target="_blank"
                              class="lazyload"
                              ><i
                                class="lazyload fa fa-apple"
                                aria-hidden="true"
                              ></i>
                              iphone</a
                            >
                          </div>
                        </article>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 lazyload">
            <article class="lazyload">
              <figcaption class="lazyload">
                <h4 class="lazyload">Wongnai: Restaurants &amp; Reviews</h4>
                <span class="lazyload"
                  >Wongnai helps you find excellent restaurants, spas, and
                  various beauty services across Thailand....</span
                >
                <div class="btn-link lazyload">
                  <a
                    href="javascript:;"
                    class="btn-link-arrow lazyload"
                    data-toggle="modal"
                    data-target="#Wall121"
                    >Learn more<img
                      class="lazyload"
                      alt="Dev Technosys"
                      data-src="https://devtechnosys.com/images/2021-new/arrow-btn.svg"
                      width="30"
                      height="30"
                  /></a>
                </div>
              </figcaption>
              <figure class="lazyload">
                <img
                  class="lazyload"
                  data-src="images/portfolioImg/list-wongnai-app.webp"
                  alt="Dev Technosys"
                />
              </figure>
            </article>
          </div>
          <div class="FullScreenPop lazyload">
            <div
              class="modal fade lazyload"
              id="Wall121"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
              style="display: none"
            >
              <div class="modal-dialog lazyload">
                <div class="modal-content lazyload">
                  <div class="modal-header lazyload">
                    <button
                      aria-label="Close"
                      data-dismiss="modal"
                      class="lazyload close"
                    >
                      <span>×</span>
                    </button>
                  </div>
                  <div
                    class="modal-body tea-bg PortBgSection PortBgStyle1 lazyload"
                    style="
                      background: url(images/portfolioImg/bg-StarQuik.webp)
                        no-repeat;
                      background-size: cover;
                    "
                  >
                    <div class="row lazyload">
                      <div class="col-md-6 Por-md-images lazyload">
                        <article class="lazyload">
                          <img
                            class="lazyload"
                            data-src="images/portfolioImg/img-wongnai-app.webp"
                            alt="Dev Technosys"
                          />
                        </article>
                      </div>
                      <div class="col-md-6 Por-md-contain lazyload">
                        <article class="lazyload">
                          <h6 class="lazyload">
                            <b class="lazyload">Wongnai</b> Restaurants &amp;
                            Reviews
                          </h6>
                          <p class="lazyload">
                            Wongnai helps you find excellent restaurants, spas,
                            and various beauty services across Thailand.
                          </p>
                          <div class="TechnologyTag lazyload">
                            <span>jQuery</span>
                            <span>React.js</span>
                            <span>JavaScript</span>
                            <span>CSS</span>
                          </div>
                          <div class="PortLinks lazyload">
                            <a
                              rel="noreferrer noopener nofollow"
                              href="https://www.wongnai.com"
                              target="_blank"
                              class="lazyload"
                              ><i
                                class="lazyload fa fa-globe"
                                aria-hidden="true"
                              ></i>
                              Web</a
                            >
                            <a
                              rel="noreferrer noopener nofollow"
                              href="https://play.google.com/store/apps/details?id=com.wongnai.android"
                              target="_blank"
                              class="lazyload"
                              ><i
                                class="lazyload fa fa-android"
                                aria-hidden="true"
                              ></i>
                              Android</a
                            >
                            <a
                              rel="noreferrer noopener nofollow"
                              href="https://apps.apple.com/th/app/wongnai/id403745899"
                              target="_blank"
                              class="lazyload"
                              ><i
                                class="lazyload fa fa-apple"
                                aria-hidden="true"
                              ></i>
                              iphone</a
                            >
                          </div>
                        </article>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 lazyload">
            <article class="lazyload">
              <figcaption class="lazyload">
                <h4 class="lazyload">Live Movie Streaming app</h4>
                <span class="lazyload"
                  >STARZ PLAY offers a streaming service with Hollywood movies,
                  original TV shows, and children's programs. Available in 19
                  Middle Eastern and North African countries....</span
                >
                <div class="btn-link lazyload">
                  <a
                    href="javascript:;"
                    class="btn-link-arrow lazyload"
                    data-toggle="modal"
                    data-target="#Wall113"
                    >Learn more<img
                      class="lazyload"
                      alt="Dev Technosys"
                      data-src="https://devtechnosys.com/images/2021-new/arrow-btn.svg"
                      width="30"
                      height="30"
                  /></a>
                </div>
              </figcaption>
              <figure class="lazyload">
                <img
                  class="lazyload"
                  data-src="images/portfolioImg/STARZPLAY-li.webp"
                  alt="Dev Technosys"
                />
              </figure>
            </article>
          </div>
          <div class="FullScreenPop lazyload">
            <div
              class="modal fade lazyload"
              id="Wall113"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
              style="display: none"
            >
              <div class="modal-dialog lazyload">
                <div class="modal-content lazyload">
                  <div class="modal-header lazyload">
                    <button
                      aria-label="Close"
                      data-dismiss="modal"
                      class="lazyload close"
                    >
                      <span>×</span>
                    </button>
                  </div>
                  <div
                    class="modal-body tea-bg PortBgSection PortBgStyle1 lazyload"
                    style="
                      background: url(images/portfolioImg/STARZPLAY-bg.webp)
                        no-repeat;
                      background-size: cover;
                    "
                  >
                    <div class="row lazyload">
                      <div class="col-md-6 Por-md-images lazyload">
                        <article class="lazyload">
                          <img
                            class="lazyload"
                            data-src="images/portfolioImg/STARZPLAY-img.webp"
                            alt="Dev Technosys"
                          />
                        </article>
                      </div>
                      <div class="col-md-6 Por-md-contain lazyload">
                        <article class="lazyload">
                          <h6 class="lazyload">
                            <b class="lazyload">STARZPLAY</b> Live Movie
                            Streaming app
                          </h6>
                          <p class="lazyload">
                            STARZ PLAY offers a streaming service with Hollywood
                            movies, original TV shows, and children's programs.
                            Available in 19 Middle Eastern and North African
                            countries.
                          </p>
                          <div class="TechnologyTag lazyload">
                            <span>Java</span>
                            <span>Swift</span>
                            <span>Apache</span>
                            <span>Bootstrap</span>
                            <span>RequireJS</span>
                          </div>
                          <div class="PortLinks lazyload">
                            <a
                              rel="noreferrer noopener nofollow"
                              href="https://starzplay.com/"
                              target="_blank"
                              class="lazyload"
                              ><i
                                class="lazyload fa fa-globe"
                                aria-hidden="true"
                              ></i>
                              Web</a
                            >
                            <a
                              rel="noreferrer noopener nofollow"
                              href="https://play.google.com/store/apps/details?id=com.parsifal.starz"
                              target="_blank"
                              class="lazyload"
                              ><i
                                class="lazyload fa fa-android"
                                aria-hidden="true"
                              ></i>
                              Android</a
                            >
                            <a
                              rel="noreferrer noopener nofollow"
                              href="https://apps.apple.com/ae/app/starzplay-arabia/id962284784"
                              target="_blank"
                              class="lazyload"
                              ><i
                                class="lazyload fa fa-apple"
                                aria-hidden="true"
                              ></i>
                              iphone</a
                            >
                          </div>
                        </article>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 lazyload">
            <article class="lazyload">
              <figcaption class="lazyload">
                <h4 class="lazyload">Travel SMART Pfizer</h4>
                <span class="lazyload"
                  >Travel SMART is the app that makes choices to maximize
                  productivity, improve convenience and reduce costs. It manages
                  costs to optimize value with security and duty of
                  care....</span
                >
                <div class="btn-link lazyload">
                  <a
                    href="javascript:;"
                    class="btn-link-arrow lazyload"
                    data-toggle="modal"
                    data-target="#Wall73"
                    >Learn more<img
                      class="lazyload"
                      alt="Dev Technosys"
                      data-src="https://devtechnosys.com/images/2021-new/arrow-btn.svg"
                      width="30"
                      height="30"
                  /></a>
                </div>
              </figcaption>
              <figure class="lazyload">
                <img
                  class="lazyload"
                  data-src="images/portfolioImg/travel-listing-portfolio-min.webp"
                  alt="Dev Technosys"
                />
              </figure>
            </article>
          </div>
          <div class="FullScreenPop lazyload">
            <div
              class="modal fade lazyload"
              id="Wall73"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
              style="display: none"
            >
              <div class="modal-dialog lazyload">
                <div class="modal-content lazyload">
                  <div class="modal-header lazyload">
                    <button
                      aria-label="Close"
                      data-dismiss="modal"
                      class="lazyload close"
                    >
                      <span>×</span>
                    </button>
                  </div>
                  <div
                    class="modal-body tea-bg PortBgSection PortBgStyle1 lazyload"
                    style="
                      background: url(images/portfolioImg/travelsmart-bg.webp)
                        no-repeat;
                      background-size: cover;
                    "
                  >
                    <div class="row lazyload">
                      <div class="col-md-6 Por-md-images lazyload">
                        <article class="lazyload">
                          <img
                            class="lazyload"
                            data-src="images/portfolioImg/travel-Inner.webp"
                            alt="Dev Technosys"
                          />
                        </article>
                      </div>
                      <div class="col-md-6 Por-md-contain lazyload">
                        <article class="lazyload">
                          <h6 class="lazyload">
                            <b class="lazyload">Travel SMART Pfizer</b>
                          </h6>
                          <p class="lazyload">
                            Travel SMART is the app that makes choices to
                            maximize productivity, improve convenience and
                            reduce costs. It manages costs to optimize value
                            with security and duty of care.
                          </p>
                          <div class="TechnologyTag lazyload">
                            <span>Android SDK</span>
                            <span>BootStrap</span>
                            <span>Jquery</span>
                            <span>iOS</span>
                          </div>
                          <div class="PortLinks lazyload">
                            <a
                              rel="noreferrer noopener nofollow"
                              href="https://play.google.com/store/apps/details?id=com.getroadmap.travel.pfizer&amp;hl=en "
                              target="_blank"
                              class="lazyload"
                              ><i
                                class="lazyload fa fa-android"
                                aria-hidden="true"
                              ></i>
                              Android</a
                            >
                            <a
                              rel="noreferrer noopener nofollow"
                              href="https://itunes.apple.com/us/app/travel-smart-pfizer-travel/id1146917076?mt=8"
                              target="_blank"
                              class="lazyload"
                              ><i
                                class="lazyload fa fa-apple"
                                aria-hidden="true"
                              ></i>
                              iphone</a
                            >
                          </div>
                        </article>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 lazyload">
            <article class="lazyload">
              <figcaption class="lazyload">
                <h4 class="lazyload">On Demand House Needs Apps</h4>
                <span class="lazyload"
                  >Handy is the leading platform for connecting individuals
                  looking for household services with top-quality, pre-screened
                  independent service professionals. From home cleaning to
                  handyman services, Handy instantly matches thousands of
                  customers ev...</span
                >
                <div class="btn-link lazyload">
                  <a
                    href="javascript:;"
                    class="btn-link-arrow lazyload"
                    data-toggle="modal"
                    data-target="#Wall17"
                    >Learn more<img
                      class="lazyload"
                      alt="Dev Technosys"
                      data-src="https://devtechnosys.com/images/2021-new/arrow-btn.svg"
                      width="30"
                      height="30"
                  /></a>
                </div>
              </figcaption>
              <figure class="lazyload">
                <img
                  class="lazyload"
                  data-src="images/portfolioImg/handy-li.webp"
                  alt="Dev Technosys"
                />
              </figure>
            </article>
          </div>
          <div class="FullScreenPop lazyload">
            <div
              class="modal fade lazyload"
              id="Wall17"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
              style="display: none"
            >
              <div class="modal-dialog lazyload">
                <div class="modal-content lazyload">
                  <div class="modal-header lazyload">
                    <button
                      aria-label="Close"
                      data-dismiss="modal"
                      class="lazyload close"
                    >
                      <span>×</span>
                    </button>
                  </div>
                  <div
                    class="modal-body tea-bg PortBgSection PortBgStyle1 lazyload"
                    style="
                      background: url(images/portfolioImg/handy-bg.webp)
                        no-repeat;
                      background-size: cover;
                    "
                  >
                    <div class="row lazyload">
                      <div class="col-md-6 Por-md-images lazyload">
                        <article class="lazyload">
                          <img
                            class="lazyload"
                            data-src="images/portfolioImg/handy-img.webp"
                            alt="Dev Technosys"
                          />
                        </article>
                      </div>
                      <div class="col-md-6 Por-md-contain lazyload">
                        <article class="lazyload">
                          <h6 class="lazyload">
                            <b class="lazyload">Handy</b> On Demand House Needs
                            Apps
                          </h6>
                          <p class="lazyload">
                            Handy is the leading platform for connecting
                            individuals looking for household services with
                            top-quality, pre-screened independent service
                            professionals. From home cleaning to handyman
                            services, Handy instantly matches thousands of
                            customers every week with top-rated professionals in
                            cities all around the world. With a seamless
                            60-second booking process, secure payment, and
                            backed by the Handy Happiness Guarantee, Handy is
                            the easiest, most convenient way to book home
                            services.
                          </p>
                          <div class="TechnologyTag lazyload">
                            <span>AngularJS</span>
                            <span>MongoDB</span>
                            <span>On-Demand</span>
                            <span>React Native</span>
                            <span>GPS</span>
                          </div>
                          <div class="PortLinks lazyload">
                            <a
                              rel="noreferrer noopener nofollow"
                              href="https://www.handy.com/"
                              target="_blank"
                              class="lazyload"
                              ><i
                                class="lazyload fa fa-globe"
                                aria-hidden="true"
                              ></i>
                              Web</a
                            >
                            <a
                              rel="noreferrer noopener nofollow"
                              href="https://play.google.com/store/apps/details?id=com.handy.handy.prod&amp;hl=en"
                              target="_blank"
                              class="lazyload"
                              ><i
                                class="lazyload fa fa-android"
                                aria-hidden="true"
                              ></i>
                              Android</a
                            >
                            <a
                              rel="noreferrer noopener nofollow"
                              href="https://itunes.apple.com/us/app/handy-com/id604419063?mt=8"
                              target="_blank"
                              class="lazyload"
                              ><i
                                class="lazyload fa fa-apple"
                                aria-hidden="true"
                              ></i>
                              iphone</a
                            >
                          </div>
                        </article>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section-padding why-hire-section lazyload">
      <div class="container lazyload">
        <div class="row lazyload">
          <div class="col-md-7 lazyload">
            <div class="why-hire-content lazyload">
              <div class="main-heading-box main-heading-box-inner lazyload">
                <h2 class="lazyload">Hire Mobile App Designers</h2>
                <p class="lazyload">
                  Looking to hire creative mobile app designers? Dev Technosys
                  boasts a team of skilled designers known for crafting
                  beautiful and easy-to-use mobile apps.
                </p>
                <p class="lazyload">
                  We craft user-friendly, eye-catching designs to make your app
                  shine in a crowded market. Stand out from the competition –
                  contact us for top-notch mobile app design and watch your app
                  capture hearts. Hire mobile app designers at an affordable
                  cost!
                </p>
              </div>
              <div class="why-hire-steps lazyload">
                <ul class="lazyload">
                  <li class="why-hire-item lazyload">
                    <img
                      class="lazyload"
                      data-src="https://devtechnosys.com/images/2021-new/hire-angularjs-developers/why-2.svg"
                      alt="Dev Technosys"
                      width="50"
                      height="49"
                    />
                    <h4 class="lazyload">Non-disclosure Agreement</h4>
                  </li>
                  <li class="why-hire-item lazyload">
                    <img
                      class="lazyload"
                      data-src="https://devtechnosys.com/images/2021-new/hire-angularjs-developers/why-1.svg"
                      alt="Dev Technosys"
                      width="50"
                      height="50"
                    />
                    <h4 class="lazyload">24*7 Support</h4>
                  </li>
                  <li class="why-hire-item lazyload">
                    <img
                      class="lazyload"
                      data-src="https://devtechnosys.com/images/2021-new/hire-angularjs-developers/why-5.svg"
                      alt="Dev Technosys"
                      width="50"
                      height="51"
                    />
                    <h4 class="lazyload">Transparent Communication</h4>
                  </li>
                  <li class="why-hire-item lazyload">
                    <img
                      class="lazyload"
                      data-src="https://devtechnosys.com/images/2021-new/hire-angularjs-developers/why-3.svg"
                      alt="Dev Technosys"
                      width="50"
                      height="51"
                    />
                    <h4 class="lazyload">Flexible Engagement</h4>
                  </li>
                  <li class="why-hire-item lazyload">
                    <img
                      class="lazyload"
                      data-src="https://devtechnosys.com/images/2021-new/hire-angularjs-developers/why-4.svg"
                      alt="Dev Technosys"
                      width="50"
                      height="50"
                    />
                    <h4 class="lazyload">Years Of Industry Experience</h4>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-5 lazyload">
            <div class="why-hire-img-blk lazyload">
              <figure class="lazyload">
                <img
                  class="lazyload"
                  data-src="https://devtechnosys.com/images/2021-new/hire-angularjs-developers/why-hire-img.png"
                  alt="Dev Technosys"
                  width="427"
                  height="399"
                />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="technologystack-nft-sec lazyload">
      <div class="container lazyload">
        <div class="main-heading-box text-center lazyload">
          <h2 class="lazyload">
            Tech Stacks for Mobile App Designing&amp; Development Services
          </h2>
        </div>
        <div class="row lazyload">
          <div class="col-md-4 lazyload">
            <div class="technologystack-box lazyload">
              <h4 class="lazyload">Frontend</h4>
              <ul class="technologystack-box-list lazyload">
                <li class="lazyload">
                  <figure class="lazyload">
                    <img
                      alt="Java"
                      class="lazyload"
                      data-src="https://devtechnosys.com/images/2023/services/mobile-app-design-services/bp-tech-img-1.png"
                      width="62"
                      height="62"
                    />
                  </figure>
                  <figcaption class="lazyload">
                    <h6 class="lazyload">React Native</h6>
                  </figcaption>
                </li>
                <li class="lazyload">
                  <figure class="lazyload">
                    <img
                      alt="Kotlin"
                      class="lazyload"
                      data-src="https://devtechnosys.com/images/2023/services/mobile-app-design-services/bp-tech-img-2.png"
                      width="62"
                      height="62"
                    />
                  </figure>
                  <figcaption class="lazyload">
                    <h6 class="lazyload">Angular</h6>
                  </figcaption>
                </li>
                <li class="lazyload">
                  <figure class="lazyload">
                    <img
                      alt="Android Studio"
                      class="lazyload"
                      data-src="https://devtechnosys.com/images/2023/services/mobile-app-design-services/bp-tech-img-3.png"
                      width="62"
                      height="62"
                    />
                  </figure>
                  <figcaption class="lazyload">
                    <h6 class="lazyload">Vue.js</h6>
                  </figcaption>
                </li>
                <li class="lazyload">
                  <figure class="lazyload">
                    <img
                      alt="Android NDK"
                      class="lazyload"
                      data-src="https://devtechnosys.com/images/2023/services/mobile-app-design-services/bp-tech-img-4.png"
                      width="62"
                      height="62"
                    />
                  </figure>
                  <figcaption class="lazyload">
                    <h6 class="lazyload">Electron</h6>
                  </figcaption>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-4 lazyload">
            <div class="technologystack-box lazyload">
              <h4 class="lazyload">Infrastructure Services</h4>
              <ul class="technologystack-box-list lazyload">
                <li class="lazyload">
                  <figure class="lazyload">
                    <img
                      alt="Retrofit"
                      class="lazyload"
                      data-src="https://devtechnosys.com/images/2023/services/mobile-app-design-services/ff-tech-img-1.png"
                      width="62"
                      height="62"
                    />
                  </figure>
                  <figcaption class="lazyload">
                    <h6 class="lazyload">AWS</h6>
                  </figcaption>
                </li>
                <li class="lazyload">
                  <figure class="lazyload">
                    <img
                      alt="OkHttp"
                      class="lazyload"
                      data-src="https://devtechnosys.com/images/2023/services/mobile-app-design-services/ff-tech-img-2.png"
                      width="62"
                      height="62"
                    />
                  </figure>
                  <figcaption class="lazyload">
                    <h6 class="lazyload">Circle ci</h6>
                  </figcaption>
                </li>
                <li class="lazyload">
                  <figure class="lazyload">
                    <img
                      alt="Glide"
                      class="lazyload"
                      data-src="https://devtechnosys.com/images/2023/services/mobile-app-design-services/ff-tech-img-3.png"
                      width="62"
                      height="62"
                    />
                  </figure>
                  <figcaption class="lazyload">
                    <h6 class="lazyload">Google Cloud</h6>
                  </figcaption>
                </li>
                <li class="lazyload">
                  <figure class="lazyload">
                    <img
                      alt="Picasso"
                      class="lazyload"
                      data-src="https://devtechnosys.com/images/2023/services/mobile-app-design-services/ff-tech-img-4.png"
                      width="62"
                      height="62"
                    />
                  </figure>
                  <figcaption class="lazyload">
                    <h6 class="lazyload">Github</h6>
                  </figcaption>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-4 lazyload">
            <div class="technologystack-box lazyload">
              <h4 class="lazyload">Database</h4>
              <ul class="technologystack-box-list lazyload">
                <li class="lazyload">
                  <figure class="lazyload">
                    <img
                      alt="Firebase"
                      class="lazyload"
                      data-src="https://devtechnosys.com/images/2023/services/mobile-app-design-services/pl-tech-img-1.png"
                      width="62"
                      height="62"
                    />
                  </figure>
                  <figcaption class="lazyload">
                    <h6 class="lazyload">MySQL</h6>
                  </figcaption>
                </li>
                <li class="lazyload">
                  <figure class="lazyload">
                    <img
                      alt="Google Cloud"
                      class="lazyload"
                      data-src="https://devtechnosys.com/images/2023/services/mobile-app-design-services/pl-tech-img-2.png"
                      width="62"
                      height="62"
                    />
                  </figure>
                  <figcaption class="lazyload">
                    <h6 class="lazyload">PostgreSQL</h6>
                  </figcaption>
                </li>
                <li class="lazyload">
                  <figure class="lazyload">
                    <img
                      alt="Node.js"
                      class="lazyload"
                      data-src="https://devtechnosys.com/images/2023/services/mobile-app-design-services/pl-tech-img-3.png"
                      width="62"
                      height="62"
                    />
                  </figure>
                  <figcaption class="lazyload">
                    <h6 class="lazyload">ORACLE</h6>
                  </figcaption>
                </li>
                <li class="lazyload">
                  <figure class="lazyload">
                    <img
                      alt="AWS"
                      class="lazyload"
                      data-src="https://devtechnosys.com/images/2023/services/mobile-app-design-services/pl-tech-img-4.png"
                      width="62"
                      height="62"
                    />
                  </figure>
                  <figcaption class="lazyload">
                    <h6 class="lazyload">MongoDB</h6>
                  </figcaption>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-4 lazyload">
            <div class="technologystack-box lazyload">
              <h4 class="lazyload">Server Side Technologies</h4>
              <ul class="technologystack-box-list lazyload">
                <li class="lazyload">
                  <figure class="lazyload">
                    <img
                      alt="Android Native"
                      class="lazyload"
                      data-src="https://devtechnosys.com/images/2023/services/mobile-app-design-services/ns-tech-img-1.png"
                      width="62"
                      height="62"
                    />
                  </figure>
                  <figcaption class="lazyload">
                    <h6 class="lazyload">Ruby</h6>
                  </figcaption>
                </li>
                <li class="lazyload">
                  <figure class="lazyload">
                    <img
                      alt="Flutter"
                      class="lazyload"
                      data-src="https://devtechnosys.com/images/2023/services/mobile-app-design-services/ns-tech-img-2.png"
                      width="62"
                      height="62"
                    />
                  </figure>
                  <figcaption class="lazyload">
                    <h6 class="lazyload">Wordpress</h6>
                  </figcaption>
                </li>
                <li class="lazyload">
                  <figure class="lazyload">
                    <img
                      alt="XML"
                      class="lazyload"
                      data-src="https://devtechnosys.com/images/2023/services/mobile-app-design-services/ns-tech-img-3.png"
                      width="62"
                      height="62"
                    />
                  </figure>
                  <figcaption class="lazyload">
                    <h6 class="lazyload">Python</h6>
                  </figcaption>
                </li>
                <li class="lazyload">
                  <figure class="lazyload">
                    <img
                      alt="Material Design"
                      class="lazyload"
                      data-src="https://devtechnosys.com/images/2023/services/mobile-app-design-services/ns-tech-img-4.png"
                      width="62"
                      height="62"
                    />
                  </figure>
                  <figcaption class="lazyload">
                    <h6 class="lazyload">PHP</h6>
                  </figcaption>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-4 lazyload">
            <div class="technologystack-box lazyload">
              <h4 class="lazyload">User Interface</h4>
              <ul class="technologystack-box-list lazyload">
                <li class="lazyload">
                  <figure class="lazyload">
                    <img
                      alt="SQLite"
                      class="lazyload"
                      data-src="https://devtechnosys.com/images/2023/services/mobile-app-design-services/cp-tech-img-1.png"
                      width="62"
                      height="62"
                    />
                  </figure>
                  <figcaption class="lazyload">
                    <h6 class="lazyload">Android Native</h6>
                  </figcaption>
                </li>
                <li class="lazyload">
                  <figure class="lazyload">
                    <img
                      alt="Realm"
                      class="lazyload"
                      data-src="https://devtechnosys.com/images/2023/services/mobile-app-design-services/cp-tech-img-2.png"
                      width="62"
                      height="62"
                    />
                  </figure>
                  <figcaption class="lazyload">
                    <h6 class="lazyload">Flutter</h6>
                  </figcaption>
                </li>
                <li class="lazyload">
                  <figure class="lazyload">
                    <img
                      alt="Firebase"
                      class="lazyload"
                      data-src="https://devtechnosys.com/images/2023/services/mobile-app-design-services/cp-tech-img-3.png"
                      width="62"
                      height="62"
                    />
                  </figure>
                  <figcaption class="lazyload">
                    <h6 class="lazyload">XML</h6>
                  </figcaption>
                </li>
                <li class="lazyload">
                  <figure class="lazyload">
                    <img
                      alt="PostgreSQL"
                      class="lazyload"
                      data-src="https://devtechnosys.com/images/2023/services/mobile-app-design-services/cp-tech-img-4.png"
                      width="62"
                      height="62"
                    />
                  </figure>
                  <figcaption class="lazyload">
                    <h6 class="lazyload">Material Design</h6>
                  </figcaption>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-4 lazyload">
            <div class="technologystack-box lazyload">
              <h4 class="lazyload">ERP Platforms</h4>
              <ul class="technologystack-box-list lazyload">
                <li class="lazyload">
                  <figure class="lazyload">
                    <img
                      alt="Xamarian"
                      class="lazyload"
                      data-src="https://devtechnosys.com/images/2023/services/mobile-app-design-services/sp-tech-img-1.png"
                      width="62"
                      height="62"
                    />
                  </figure>
                  <figcaption class="lazyload">
                    <h6 class="lazyload">Netsuite</h6>
                  </figcaption>
                </li>
                <li class="lazyload">
                  <figure class="lazyload">
                    <img
                      alt="Ionic"
                      class="lazyload"
                      data-src="https://devtechnosys.com/images/2023/services/mobile-app-design-services/sp-tech-img-2.png"
                      width="62"
                      height="62"
                    />
                  </figure>
                  <figcaption class="lazyload">
                    <h6 class="lazyload">Dynamics</h6>
                  </figcaption>
                </li>
                <li class="lazyload">
                  <figure class="lazyload">
                    <img
                      alt="ARCore"
                      class="lazyload"
                      data-src="https://devtechnosys.com/images/2023/services/mobile-app-design-services/sp-tech-img-3.png"
                      width="62"
                      height="62"
                    />
                  </figure>
                  <figcaption class="lazyload">
                    <h6 class="lazyload">Salesforce</h6>
                  </figcaption>
                </li>
                <li class="lazyload">
                  <figure class="lazyload">
                    <img
                      alt="PhoneGap"
                      class="lazyload"
                      data-src="https://devtechnosys.com/images/2023/services/mobile-app-design-services/sp-tech-img-4.png"
                      width="62"
                      height="62"
                    />
                  </figure>
                  <figcaption class="lazyload">
                    <h6 class="lazyload">Tableau</h6>
                  </figcaption>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section-padding why-choose-nft-nft-sec lazyload">
      <div class="container lazyload">
        <div class="main-heading-box lazyload">
          <h2 class="lazyload">
            Why Choose Dev Technosys Mobile App Design Services?
          </h2>
          <p class="lazyload">
            Established in 2010, Dev Technosys delivers impeccable mobile app
            designing services besides development solutions. We can be your
            perfect partner if you plan to build a unique mobile app with a
            user-friendly and attractive design. Here are the key factors that
            make us the industry's top Mobile App Design Company.
          </p>
        </div>
        <div class="row why-choose-nft-nft-row lazyload">
          <div class="col-md-4 lazyload">
            <div class="why-choose-ratio-box purple-gredient lazyload">
              <span class="drop-shadow-box lazyload"></span>
              <h5 class="lazyload">Years of Experience</h5>
              <span class="lazyload">13<sup class="lazyload">+</sup></span>
            </div>
          </div>
          <div class="col-md-4 lazyload">
            <div class="why-choose-ratio-box red-gredient lazyload">
              <h5 class="lazyload">Error-Free Work</h5>
              <span class="lazyload">100<sub class="lazyload">%</sub></span>
            </div>
          </div>
          <div class="col-md-4 lazyload">
            <div class="why-choose-ratio-box green-gredient lazyload">
              <h5 class="lazyload">Happy Clients</h5>
              <span class="lazyload">92<sup class="lazyload">%</sup></span>
            </div>
          </div>
          <div class="col-md-4 lazyload">
            <div class="why-choose-ratio-box blue-gredient lazyload">
              <h5 class="lazyload">Projects Delivered</h5>
              <span class="lazyload">1000<sub class="lazyload">+</sub></span>
            </div>
          </div>
          <div class="col-md-4 lazyload">
            <div class="why-choose-ratio-box pink-gredient lazyload">
              <span class="drop-shadow-box lazyload"></span>
              <h5 class="lazyload">Mobile App Designers</h5>
              <span class="lazyload">100<sup class="lazyload">+</sup></span>
            </div>
          </div>
          <div class="col-md-4 lazyload">
            <div class="why-choose-ratio-box sky-blue-gredient lazyload">
              <h5 class="lazyload">Customer Support</h5>
              <span class="lazyload">24*7<sub class="lazyload"></sub></span>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section-padding build-nft-sec paddTop0 lazyload">
      <div class="container lazyload">
        <div class="main-heading-box lazyload">
          <h2 class="lazyload">Our Streamlined Mobile App Design Process</h2>
          <p class="lazyload">
            At Dev Technosys, we're passionate about creating exceptional mobile
            app designs. We achieve this through a streamlined process, crafting
            business-boosting apps and websites. Our team seamlessly handles
            development, design, and optimization to meet your specific needs
            and propel your market reach. Here’s the mobile app design and
            development services approach we follow.
          </p>
        </div>
        <ul class="build-nft-list row lazyload">
          <li class="col-md-2 lazyload">
            <figure class="blue-bg lazyload">
              <img
                alt="Project Analysis"
                class="lazyload"
                data-src="https://devtechnosys.com/images/2023/services/android-app-development/development-Process-1.svg"
                width="30"
                height="30"
              />
            </figure>
            <figcaption class="lazyload">
              <h4 class="lazyload">Project Analysis</h4>
            </figcaption>
          </li>
          <li class="col-md-2 lazyload">
            <figure class="orange-bg lazyload">
              <img
                alt="Strategy &amp; Planning"
                class="lazyload"
                data-src="https://devtechnosys.com/images/2023/services/android-app-development/development-Process-2.svg"
                width="30"
                height="30"
              />
            </figure>
            <figcaption class="lazyload">
              <h4 class="lazyload">Strategy &amp; Planning</h4>
            </figcaption>
          </li>
          <li class="col-md-2 lazyload">
            <figure class="purple-bg lazyload">
              <img
                alt="UX/UI Designing"
                class="lazyload"
                data-src="https://devtechnosys.com/images/2023/services/android-app-development/development-Process-3.svg"
                width="30"
                height="30"
              />
            </figure>
            <figcaption class="lazyload">
              <h4>UX/UI Designing</h4>
            </figcaption>
          </li>
          <li class="col-md-2 lazyload">
            <figure class="green-bg lazyload">
              <img
                alt="Android App Development"
                class="lazyload"
                data-src="https://devtechnosys.com/images/2023/services/android-app-development/development-Process-4.svg"
                width="30"
                height="30"
              />
            </figure>
            <figcaption class="lazyload">
              <h4 class="lazyload">Android App Development</h4>
            </figcaption>
          </li>
          <li class="col-md-2 lazyload">
            <figure class="lightblue-bg lazyload">
              <img
                alt="Dev Technosys"
                class="lazyload"
                data-src="https://devtechnosys.com/images/2023/services/android-app-development/development-Process-5.svg"
                width="30"
                height="30"
              />
            </figure>
            <figcaption class="lazyload">
              <h4 class="lazyload">App Testing &amp; Launch</h4>
            </figcaption>
          </li>
          <li class="col-md-2 lazyload">
            <figure class="pink-bg lazyload">
              <span
                ><img
                  class="lazyload"
                  alt="Dev Technosys"
                  data-src="https://devtechnosys.com/images/2023/services/android-app-development/development-Process-6.svg"
                  width="30"
                  height="30"
                />
              </span>
            </figure>

            <figcaption class="lazyload">
              <h4 class="lazyload">Support &amp; Maintenance</h4>
            </figcaption>
          </li>
        </ul>
      </div>
    </section>
    <section class="faq-inner lazyload">
      <div class="container lazyload">
        <div class="main-heading-box main-heading-box-inner lazyload">
          <h2 class="lazyload">FAQs on Mobile App Designing</h2>
        </div>
        <div class="faq-box lazyload">
          <ul class="accordion-list lazyload">
            <li class="panel lazyload active">
              <h3 class="panel-title lazyload">
                <span class="lazyload">What is Mobile App Design?</span>
                <svg
                  id="acc-arrow"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    id="Path_117"
                    data-name="Path 117"
                    d="M0,24H24V0H0Z"
                    fill="none"
                  ></path>
                  <path
                    id="Path_118"
                    data-name="Path 118"
                    d="M12,11.05,16.95,16l1.414-1.414L12,8.222,5.636,14.586,7.05,16Z"
                    transform="translate(0 -0.222)"
                  ></path>
                </svg>
              </h3>
              <div class="answer lazyload" style="">
                <div class="panel-body lazyload">
                  <p class="lazyload">
                    Ever wonder how mobile apps look and work so well? That's
                    mobile app design! We create the visual (UI) and interactive
                    (UX) elements of your app.
                  </p>
                  <p class="lazyload">
                    We focus on clear layouts, attractive colors, easy
                    navigation, and well-designed icons to make your app a joy
                    to use. Basically, we turn your app idea into a
                    user-friendly reality. Let's chat about your mobile app
                    design!
                  </p>
                </div>
              </div>
            </li>
            <li class="panel lazyload">
              <h3 class="panel-title lazyload">
                <span class="lazyload"
                  >Which is the Best Mobile App Design Agency?</span
                >
                <svg
                  id="acc-arrow"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    id="Path_117"
                    data-name="Path 117"
                    d="M0,24H24V0H0Z"
                    fill="none"
                  ></path>
                  <path
                    id="Path_118"
                    data-name="Path 118"
                    d="M12,11.05,16.95,16l1.414-1.414L12,8.222,5.636,14.586,7.05,16Z"
                    transform="translate(0 -0.222)"
                  ></path>
                </svg>
              </h3>
              <div class="answer lazyload" style="display: none">
                <div class="panel-body lazyload">
                  <p class="lazyload">
                    There are several mobile app design agencies available. Dev
                    Technosys tops the list. We have a team of creative mobile
                    app design company for hire who can craft attention-grabbing
                    mobile apps.
                  </p>
                  <p class="lazyload">
                    From basic app design to feature-packed mobile applications,
                    we can design it. Discuss your requirements with our mobile
                    app design services provider and get a perfectly designed
                    app.
                  </p>
                </div>
              </div>
            </li>
            <li class="panel lazyload">
              <h3 class="panel-title lazyload">
                <span class="lazyload"
                  >What is the Mobile App Design Process?</span
                >
                <svg
                  id="acc-arrow"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    id="Path_117"
                    data-name="Path 117"
                    d="M0,24H24V0H0Z"
                    fill="none"
                  ></path>
                  <path
                    id="Path_118"
                    data-name="Path 118"
                    d="M12,11.05,16.95,16l1.414-1.414L12,8.222,5.636,14.586,7.05,16Z"
                    transform="translate(0 -0.222)"
                  ></path>
                </svg>
              </h3>
              <div class="answer lazyload" style="display: none">
                <div class="panel-body lazyload">
                  <p class="lazyload">
                    Designing a mobile app requires immense expertise and hard
                    work. Here is a simple mobile app design process you can
                    follow.
                  </p>
                  <br />
                  <ul class="lazyload">
                    <li class="lazyload">UX Analysis</li>
                    <li class="lazyload">Wireframing &amp; Prototyping</li>
                    <li class="lazyload">UX UI Designing</li>
                    <li class="lazyload">Rapid Prototyping</li>
                    <li class="lazyload">
                      Design Specs &amp; Assets For Developers
                    </li>
                    <li class="lazyload">
                      Designing Graphics For The App Markets
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li class="panel lazyload">
              <h3 class="panel-title lazyload">
                <span class="lazyload"
                  >How Long Does It Takes to Design A Mobile App?</span
                >
                <svg
                  id="acc-arrow"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    id="Path_117"
                    data-name="Path 117"
                    d="M0,24H24V0H0Z"
                    fill="none"
                  ></path>
                  <path
                    id="Path_118"
                    data-name="Path 118"
                    d="M12,11.05,16.95,16l1.414-1.414L12,8.222,5.636,14.586,7.05,16Z"
                    transform="translate(0 -0.222)"
                  ></path>
                </svg>
              </h3>
              <div class="answer lazyload" style="display: none">
                <div class="panel-body lazyload">
                  <p class="lazyload">
                    Mobile app design time depends on how complex it is and how
                    many screens it needs. It can take anywhere from 20 to 140
                    hours. Talk to a mobile app design agency to get a more
                    accurate estimate for your specific app.
                  </p>
                </div>
              </div>
            </li>
            <li class="panel lazyload">
              <h3 class="panel-title lazyload">
                <span class="lazyload"
                  >What is the Mobile App Design Cost?</span
                >
                <svg
                  id="acc-arrow"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    id="Path_117"
                    data-name="Path 117"
                    d="M0,24H24V0H0Z"
                    fill="none"
                  ></path>
                  <path
                    id="Path_118"
                    data-name="Path 118"
                    d="M12,11.05,16.95,16l1.414-1.414L12,8.222,5.636,14.586,7.05,16Z"
                    transform="translate(0 -0.222)"
                  ></path>
                </svg>
              </h3>
              <div class="answer lazyload" style="display: none">
                <div class="panel-body lazyload">
                  <p class="lazyload">
                    Pinpointing a mobile app design cost is tricky because
                    several things come into play. Some of them are –
                  </p>
                  <ul class="lazyload">
                    <li class="lazyload">Work scope</li>
                    <li class="lazyload">App designer experience</li>
                    <li class="lazyload">Mobile app agency location</li>
                    <li class="lazyload">Mobile app designers skills</li>
                    <li class="lazyload">Features and functionalities</li>
                    <li class="lazyload">Project complexity</li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
  </div>
  <div class="quick-content-box">
    <div class="quick-content">
      <button class="close-box">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path
            d="M11.9997 10.5865L16.9495 5.63672L18.3637 7.05093L13.4139 12.0007L18.3637 16.9504L16.9495 18.3646L11.9997 13.4149L7.04996 18.3646L5.63574 16.9504L10.5855 12.0007L5.63574 7.05093L7.04996 5.63672L11.9997 10.5865Z"
            fill="currentColor"
          ></path>
        </svg>
      </button>
      <div class="quick-link-head">
        <figure>
          <img
            class="lazyload"
            data-src="https://devtechnosys.com/images/2023/link-modal/ql-assistant.svg"
            width="36"
            height="40"
          />
        </figure>
        <h3>Quick Links</h3>
      </div>
      <ul class="quick-link-list">
        <li>
          <a href="https://devtechnosys.com/mobile-app-development.php"
            >Mobile App Development</a
          >
        </li>
        <li>
          <a href="https://devtechnosys.com/iphone-app-development.php"
            >iPhone App Development</a
          >
        </li>
        <li>
          <a href="https://devtechnosys.com/hire-developers.php"
            >Hire Dedicated Developers</a
          >
        </li>
        <li>
          <a
            href="https://devtechnosys.com/application-maintenance-and-support-services.php"
            >Mobile App Maintenance and Support</a
          >
        </li>
      </ul>
      <div class="price-btn">
        <a
          href="https://devtechnosys.com/calculate-it-project-cost.php"
          class="btn btn-orange"
          >Pricing</a
        >
      </div>
    </div>
    <button class="quick-btn">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="20"
        height="20"
        x="0"
        y="0"
        viewBox="0 0 24 24"
        style="enable-background: new 0 0 512 512"
        xml:space="preserve"
        class=""
      >
        <g>
          <path
            d="M6.739 21.95a5.749 5.749 0 0 1-4.065-9.816l8.485-8.486a.75.75 0 1 1 1.061 1.061l-8.486 8.485A4.25 4.25 0 1 0 9.745 19.2l10.96-10.96a2.751 2.751 0 0 0-3.89-3.89l-8.838 8.844a1.25 1.25 0 1 0 1.768 1.768L15.4 9.306a.75.75 0 1 1 1.061 1.06l-5.656 5.657a2.75 2.75 0 0 1-3.89-3.889l8.84-8.834a4.25 4.25 0 1 1 6.011 6.01l-10.96 10.956a5.715 5.715 0 0 1-4.067 1.684z"
            data-name="Layer 2"
            fill="#000000"
            opacity="1"
            data-original="#000000"
            class=""
          ></path>
        </g>
      </svg>
      Quicks-links
    </button>
  </div>
</div>
