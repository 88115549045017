import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-thiet-ke-poster-to-roi',
  templateUrl: './thiet-ke-poster-to-roi.component.html',
  styleUrls: ['./thiet-ke-poster-to-roi.component.scss']
})
export class ThietKePosterToRoiComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
