export class RecivedInformationModel {
    constructor() {
        this.FullName = '';
        this.PhoneNumber = '';
        this.Note = '';
    }
    FullName: string;
    PhoneNumber: string;
    Note: string;
}


