<div class="contact-title contact-title-bg">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="contact-title-text">
                    <h2>Liên hệ</h2>
                    <ul>
                        <li>
                            <a routerLink="/">Trang chủ</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            Liên hệ
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="pt-100"></div>