<div class="home-style-three">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container-fluid">
        <div class="row align-items-center">
          <img src="assets/img/home/banner.gif" id="bg" alt="" />
        </div>

        <div class="home-three-shapes">
          <img src="assets/img/process-pattern-3.png" alt="shape" />
          <img src="assets/img/process-pattern-4.png" alt="shape" />
          <img src="assets/img/process-pattern-5.png" alt="shape" />
          <img src="assets/img/process-pattern-6.png" alt="shape" />
          <img src="assets/img/process-pattern-7.png" alt="shape" />
        </div>
      </div>
    </div>
  </div>
</div>

<section class="about-style-two pt-100">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12">
        <div class="about-image">
          <img src="assets/img/about.svg" alt="about image" />

          <div class="counter-section">
            <div class="counter-area">
              <div class="row">
                <div class="col-lg-4 col-md-6 col-6 offset-lg-2 col-xl-5 offset-xl-1">
                  <div class="counter-text">
                    <h2><span class="counter">55</span></h2>
                    <p>Dự án hoàn thành</p>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 col-6 col-xl-5">
                  <div class="counter-text">
                    <h2><span class="counter">>500</span></h2>
                    <p>Khách hàng hài lòng</p>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 col-6 offset-lg-2 col-xl-5 offset-xl-1">
                  <div class="counter-text">
                    <h2><span class="counter">>20</span></h2>
                    <p>Chuyên gia</p>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 col-6 col-xl-5">
                  <div class="counter-text">
                    <h2><span class="counter">>6</span></h2>
                    <p>Năm kinh nghiệm</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12 pl-35">
        <div class="section-head">
          <div class="heading">
            Công Ty TNHH Truyền thông và Công Nghệ Newtrust
          </div>
          <div class="highlight-text">
            ĐƠN VỊ HÀNG ĐẦU TRONG LĨNH VỰC GIẢI PHÁP CÔNG NGHỆ VÀ TRUYỀN THÔNG
          </div>
        </div>
        <div class="about-text">
          <p>
            Với đội ngũ chuyên gia giàu kinh nghiệm và sáng tạo, công ty cam kết
            mang đến các dịch vụ tiên tiến, từ phát triển phần mềm, thiết kế hệ
            thống thông tin, đến tư vấn chiến lược truyền thông và marketing kỹ
            thuật số. Hỗ trợ doanh nghiệp bắt kịp xu hướng, áp dụng công nghệ để
            nâng cao chất lượng công việc, thúc đẩy gia tăng doanh số.
          </p>

          <ul>
            <li><i class="icofont-check-circled"></i> Bảo mật 100%</li>
            <li><i class="icofont-check-circled"></i> Dịch vụ 24/7</li>
            <li><i class="icofont-check-circled"></i> Chuyên nghiệp</li>
            <li><i class="icofont-check-circled"></i> Xuất hóa đơn VAT</li>
          </ul>

          <div class="theme-button">
            <a routerLink="/pricing" class="default-btn active-btn">Nhận tư vấn ngay</a>
            <a routerLink="/gioi-thieu" class="default-btn">Chi tiết</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="service-style-three pt-100 pb-70">
  <div class="container">
    <div class="section-head text-center">
      <span class="heading">Chúng tôi cung cấp</span>
      <h2 class="highlight-text">GIẢI PHÁP TOÀN DIỆN</h2>
      <p>
        NEWTRUST cung cấp dịch vụ quảng cáo hàng đầu trên Google, Facebook,
        TikTok cùng thiết kế website và ứng dụng di động chuyên nghiệp. Với sự
        kết hợp sáng tạo và hiệu quả, chúng tôi giúp doanh nghiệp thu hút khách
        hàng, tăng doanh thu và khẳng định vị thế.
      </p>
    </div>

    <div class="service-slider owl-carousel owl-theme">
      <div class="service-item">
        <img src="assets/img/social-network/development.png" alt="Development" width="auto" height="150"
          class="center-img" />

        <h3>Thiết kế website</h3>
        <p>
          Chuyển tải thông tin hiệu quả, xây dựng thương hiệu vững mạnh, thúc
          đẩy doanh thu và tối ưu hóa quy trình kinh doanh
        </p>

        <div class="theme-button">
          <a routerLink="/thiet-ke-website" class="default-btn">Chi tiết</a>
        </div>

        <div class="service-shape">
          <img src="assets/img/service/shapes/service-pattern-6.png" alt="shape" />
          <img src="assets/img/service/shapes/service-pattern-7.png" alt="shape" />
          <img src="assets/img/service/shapes/service-pattern-8.png" alt="shape" />
          <img src="assets/img/service/shapes/service-pattern-9.png" alt="shape" />
          <img src="assets/img/service/shapes/service-pattern-10.png" alt="shape" />
        </div>
      </div>

      <div class="service-item">
        <img src="assets/img/social-network/facebook.png" alt="Facebook" width="auto" height="150" class="center-img" />
        <h3>Quảng cáo Facebook</h3>
        <p>
          Quảng cáo trên Facebook giúp bạn tiếp cận người dùng mục tiêu thông
          qua video, hình ảnh, dựa trên độ tuổi, vị trí địa lý và sở thích cụ
          thể.
        </p>

        <div class="theme-button">
          <a routerLink="/facebook-ads" class="default-btn">Chi tiết</a>
        </div>

        <div class="service-shape">
          <img src="assets/img/service/shapes/service-pattern-6.png" alt="shape" />
          <img src="assets/img/service/shapes/service-pattern-7.png" alt="shape" />
          <img src="assets/img/service/shapes/service-pattern-8.png" alt="shape" />
          <img src="assets/img/service/shapes/service-pattern-9.png" alt="shape" />
          <img src="assets/img/service/shapes/service-pattern-10.png" alt="shape" />
        </div>
      </div>

      <div class="service-item">
        <img src="assets/img/social-network/google.png" alt="Google" width="auto" height="150" class="center-img" />
        <h3>Quảng cáo Google</h3>
        <p>
          Quảng cáo Google giúp đưa website và thương hiệu của bạn đến với khách
          hàng tiềm năng đang tìm kiếm sản phẩm hoặc dịch vụ trên nền tảng
          Google.
        </p>

        <div class="theme-button">
          <a routerLink="/google-ads" class="default-btn">Chi tiết</a>
        </div>

        <div class="service-shape">
          <img src="assets/img/service/shapes/service-pattern-6.png" alt="shape" />
          <img src="assets/img/service/shapes/service-pattern-7.png" alt="shape" />
          <img src="assets/img/service/shapes/service-pattern-8.png" alt="shape" />
          <img src="assets/img/service/shapes/service-pattern-9.png" alt="shape" />
          <img src="assets/img/service/shapes/service-pattern-10.png" alt="shape" />
        </div>
      </div>

      <div class="service-item">
        <img src="assets/img/social-network/tiktok.png" alt="Tiktok" width="auto" height="150" class="center-img" />
        <h3>Quảng cáo Tiktok</h3>
        <p>
          Quảng cáo TikTok thu hút giới trẻ nhờ thuật toán đề xuất thông minh,
          dễ dàng tạo trend và lan tỏa xu hướng cho sản phẩm của bạn.
        </p>

        <div class="theme-button">
          <a routerLink="/tiktok-ads" class="default-btn">Chi tiết</a>
        </div>

        <div class="service-shape">
          <img src="assets/img/service/shapes/service-pattern-6.png" alt="shape" />
          <img src="assets/img/service/shapes/service-pattern-7.png" alt="shape" />
          <img src="assets/img/service/shapes/service-pattern-8.png" alt="shape" />
          <img src="assets/img/service/shapes/service-pattern-9.png" alt="shape" />
          <img src="assets/img/service/shapes/service-pattern-10.png" alt="shape" />
        </div>
      </div>

      <div class="service-item">
        <img src="assets/img/social-network/design-2.png" alt="Design" width="auto" height="150" class="center-img" />
        <h3>Thiết kế nhận diện thương hiệu</h3>
        <p>
          Hỗ trợ xây dựng thương hiệu cá nhân, thiết kế logo, banner, và hệ
          thống nhận diện thương hiệu trên các nền tảng số.
        </p>

        <div class="theme-button">
          <a routerLink="/thiet-ke-bo-nhan-dien-thuong-hieu" class="default-btn">Chi tiết</a>
        </div>

        <div class="service-shape">
          <img src="assets/img/service/shapes/service-pattern-6.png" alt="shape" />
          <img src="assets/img/service/shapes/service-pattern-7.png" alt="shape" />
          <img src="assets/img/service/shapes/service-pattern-8.png" alt="shape" />
          <img src="assets/img/service/shapes/service-pattern-9.png" alt="shape" />
          <img src="assets/img/service/shapes/service-pattern-10.png" alt="shape" />
        </div>
      </div>

      <div class="service-item">
        <img src="assets/img/social-network/fanpage.png" alt="Fanpage" width="auto" height="150" class="center-img" />
        <h3>Xây dựng Fanpage</h3>
        <p>
          Tư vấn xây dựng Fanpage chuyên nghiệp với hình ảnh và video bắt mắt,
          thể hiện rõ sự uy tín và phong cách của doanh nghiệp.
        </p>

        <div class="theme-button">
          <a routerLink="/xay-dung-fanpage" class="default-btn">Chi tiết</a>
        </div>

        <div class="service-shape">
          <img src="assets/img/service/shapes/service-pattern-6.png" alt="shape" />
          <img src="assets/img/service/shapes/service-pattern-7.png" alt="shape" />
          <img src="assets/img/service/shapes/service-pattern-8.png" alt="shape" />
          <img src="assets/img/service/shapes/service-pattern-9.png" alt="shape" />
          <img src="assets/img/service/shapes/service-pattern-10.png" alt="shape" />
        </div>
      </div>
    </div>
  </div>
</div>

<section class="why-us">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-6 p-0">
        <div class="why-us-img"></div>
      </div>

      <div class="col-lg-6">
        <div class="why-us-text ptb-100">
          <div class="section-head">
            <span>Tại sao chọn chúng tôi</span>
            <h2>
              Lựa chọn hàng đầu trong<br />
              lĩnh vực CNTT & Truyền thông
            </h2>
          </div>

          <div class="media d-flex">
            <i class="icofont-check align-self-start mr-3"></i>
            <div class="media-body">
              <h5 class="mt-0">Kinh nghiệm và chuyên môn</h5>
              <p>
                Đội ngũ có kinh nghiệm lâu năm trong ngành truyền thông và CNTT,
                đã thực hiện nhiều dự án lớn với khách hàng đa dạng.
              </p>
            </div>
          </div>

          <div class="media d-flex">
            <i class="icofont-check align-self-start mr-3"></i>
            <div class="media-body">
              <h5 class="mt-0">Giải pháp toàn diện</h5>
              <p>
                Cung cấp đầy đủ các dịch vụ từ tư vấn, thiết kế đến triển khai
                và bảo trì hệ thống, giúp khách hàng tiết kiệm thời gian và tối
                ưu hóa chi phí.
              </p>
            </div>
          </div>

          <div class="media d-flex">
            <i class="icofont-check align-self-start mr-3"></i>
            <div class="media-body">
              <h5 class="mt-0">Hỗ trợ khách hàng chuyên nghiệp</h5>
              <p>
                Hỗ trợ 24/7, giải quyết nhanh chóng các vấn đề phát sinh, đảm
                bảo dịch vụ không bị gián đoạn.
              </p>
            </div>
          </div>

          <div class="media d-flex">
            <i class="icofont-check align-self-start mr-3"></i>
            <div class="media-body">
              <h5 class="mt-0">Chi phí hợp lý</h5>
              <p class="mb-0">
                Cung cấp dịch vụ chất lượng với mức giá cạnh tranh, tối ưu chi
                phí cho khách hàng.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="process-section process-style-three pb-70">
  <div class="container">
    <div class="section-head text-center">
      <h2>QUY TRÌNH LÀM VIỆC</h2>
      <p>
        Chúng tôi tập trung vào sự chuyên nghiệp, cam kết chất lượng và tính
        minh bạch trong mỗi giai đoạn.
      </p>
    </div>

    <div class="row">
      <div class="col-md-4 col-sm-6">
        <div class="process-card">
          <i class="icofont-touch"></i>
          <h3>Tư vấn & phân tích</h3>

          <p>
            Chúng tôi bắt đầu bằng việc lắng nghe và hiểu rõ nhu cầu, thách thức
            của bạn. Qua quá trình tư vấn chi tiết, chúng tôi phân tích mục tiêu
            kinh doanh để đề xuất các giải pháp phù hợp.
          </p>
          <img src="assets/img/process-pattern-2.png" alt="arrow" />
        </div>
      </div>

      <div class="col-md-4 col-sm-6">
        <div class="process-card">
          <i class="icofont-world"></i>
          <h3>Lập kế hoạch chiến lược</h3>
          <p>
            Đội ngũ của chúng tôi phát triển một lộ trình dự án toàn diện, vạch
            ra các cột mốc quan trọng, thời gian thực hiện và kết quả cần đạt.
            Điều này giúp đảm bảo chúng tôi luôn đồng hành với mục tiêu của bạn
            và đặt ra kỳ vọng rõ ràng ngay từ đầu.
          </p>
          <img src="assets/img/process-pattern-2.png" alt="arrow" />
        </div>
      </div>

      <div class="col-md-4 col-sm-6 offset-sm-3 offset-md-0">
        <div class="process-card">
          <i class="icofont-recycle-alt"></i>
          <h3>Thiết kế giải pháp tùy chỉnh</h3>
          <p>
            Dựa trên phân tích, chúng tôi thiết kế một giải pháp tùy chỉnh tích
            hợp công nghệ mới nhất và các xu hướng hiện đại, đảm bảo giải pháp
            hòa nhập mượt mà với môi trường kinh doanh của bạn.
          </p>
        </div>
      </div>
    </div>

    <div class="row margin-top-40">
      <div class="col-md-4 col-sm-6">
        <div class="process-card">
          <i class="icofont-touch"></i>
          <h3>Triển khai & Phát triển</h3>
          <p>
            Đội ngũ chuyên gia của chúng tôi chịu trách nhiệm triển khai giải
            pháp, thông báo thường xuyên cho bạn trong suốt quá trình. Chúng tôi
            cam kết hoàn thành đúng tiến độ, tích hợp trơn tru và không gây gián
            đoạn hoạt động của bạn.
          </p>
          <img src="assets/img/process-pattern-2.png" alt="arrow" />
        </div>
      </div>

      <div class="col-md-4 col-sm-6">
        <div class="process-card">
          <i class="icofont-world"></i>
          <h3>Khởi chạy & Hỗ trợ</h3>
          <p>
            Sau khi triển khai thành công, chúng tôi tiến hành khởi chạy giải
            pháp và cung cấp hỗ trợ liên tục. Đội ngũ của chúng tôi sẵn sàng hỗ
            trợ 24/7 để giải quyết mọi nhu cầu hoặc điều chỉnh cần thiết.
            <img src="assets/img/process-pattern-2.png" alt="arrow" />
          </p>
        </div>
      </div>

      <div class="col-md-4 col-sm-6 offset-sm-3 offset-md-0">
        <div class="process-card">
          <i class="icofont-recycle-alt"></i>
          <h3>Tối ưu hóa liên tục</h3>
          <p>
            Chúng tôi không chỉ dừng lại ở việc triển khai. Chúng tôi theo dõi
            hiệu suất và đưa ra các đề xuất tối ưu hóa để giữ cho hệ thống của
            bạn hoạt động hiệu quả và ổn định.
          </p>
        </div>
      </div>
    </div>

    <div class="process-shapes">
      <img src="assets/img/process-pattern-3.png" alt="shape" />
      <img src="assets/img/process-pattern-4.png" alt="shape" />
      <img src="assets/img/process-pattern-5.png" alt="shape" />
      <img src="assets/img/process-pattern-6.png" alt="shape" />
      <img src="assets/img/process-pattern-7.png" alt="shape" />
      <img src="assets/img/process-pattern-6.png" alt="shape" />
    </div>
  </div>
</div>

<section class="recent-project-section pt-100 pb-70">
  <div class="container">
    <div class="section-head text-center">
      <h2 class="highlight-text-website">BÁO GIÁ THIẾT KẾ WEBSITE</h2>
    </div>

    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-6">
        <div class="price-card-area">
          <div class="price-head-text">
            <h4>A1 – Khởi nghiệp</h4>
            <h2>Từ 3.0 triệu < 3.9 triệu</h2>
          </div>

          <div class="plan-features">
            <ul>
              <li>
                <i class="icofont-check-circled"></i>
                <span style="color: #3e5684">Thời gian thực hiện: 3 - 4 ngày</span>
              </li>
              <li>
                <i class="icofont-check-circled"></i> Tặng tên miền quốc tế trị
                giá 350,000
              </li>
              <li>
                <i class="icofont-check-circled"></i> Tặng hosting SSD cao cấp
                băng thông không giới hạn
              </li>
              <li>
                <i class="icofont-check-circled"></i> Tặng 03 email tên miền
                chuyên nghiệp
              </li>
              <li><i class="icofont-check-circled"></i> Tặng All in One SEO</li>
              <li>
                <i class="icofont-check-circled"></i> Phần mềm bản quyền chống
                virus
              </li>
              <li>
                <i class="icofont-check-circled"></i> Tặng 02 banner thiết kế
                đẹp
              </li>
              <li>
                <i class="icofont-check-circled"></i> Tặng SSL – Chứng chỉ bảo
                mật
              </li>
              <li><i class="icofont-check-circled"></i> Thiết kế chuẩn SEO</li>
              <li>
                <i class="icofont-check-circled"></i> Giao diện thân thiện trên
                di động, máy tính bảng,...
              </li>
              <li>
                <i class="icofont-check-circled"></i> Tích hợp Hotline, Zalo,
                Facebook, Youtube, Messenger
              </li>
              <li><i class="icofont-check-circled"></i> Bảo hành 1 năm</li>
              <li>
                <i class="icofont-check-circled"></i>
                <span style="color: #ff0000">Backup, sao lưu dữ liệu hàng tuần</span>
              </li>
              <li>
                <i class="icofont-check-circled"></i>
                <span style="color: #ff0000">Cam kết hoàn tiền 100% nếu không hài lòng</span>
              </li>
              <li>
                <i class="icofont-check-circled"></i>
                <span style="color: #ff0000">Bàn giao code, hosting, tên miền</span>
              </li>
              <li>
                <i class="icofont-check-circled"></i>
                <span style="color: #ff0000">Cam kết tốc độ tải trang dưới 3 giây</span>
              </li>
            </ul>
          </div>

          <div class="plan-btn">
            <a routerLink="/lien-he">Tư vấn</a>
          </div>
        </div>
      </div>

      <div class="col-lg-6 col-md-6 col-sm-6">
        <div class="price-card-area active-price">
          <div class="price-head-text">
            <h4>A2 – Gói cơ bản</h4>
            <h2>Từ 3.8 triệu < 4.8 triệu</h2>
          </div>

          <div class="plan-features">
            <ul>
              <li>
                <i class="icofont-check-circled"></i>
                <span style="color: #3e5684">Thời gian thực hiện: 3 - 4 ngày</span>
              </li>
              <li>
                <i class="icofont-check-circled"></i> Tặng tên miền quốc tế trị
                giá 350,000
              </li>
              <li>
                <i class="icofont-check-circled"></i> Tặng hosting SSD cao cấp
                băng thông không giới hạn
              </li>
              <li>
                <i class="icofont-check-circled"></i> Tặng 03 email tên miền
                chuyên nghiệp
              </li>
              <li><i class="icofont-check-circled"></i> Tặng All in One SEO</li>
              <li>
                <i class="icofont-check-circled"></i> Phần mềm bản quyền chống
                virus
              </li>
              <li>
                <i class="icofont-check-circled"></i> Tặng 02 banner thiết kế
                đẹp
              </li>
              <li>
                <i class="icofont-check-circled"></i> Tặng SSL – Chứng chỉ bảo
                mật
              </li>
              <li><i class="icofont-check-circled"></i> Thiết kế chuẩn SEO</li>
              <li>
                <i class="icofont-check-circled"></i> Giao diện thân thiện trên
                di động, máy tính bảng,...
              </li>
              <li>
                <i class="icofont-check-circled"></i> Tích hợp Hotline, Zalo,
                Facebook, Youtube, Messenger
              </li>
              <li><i class="icofont-check-circled"></i> Bảo hành 3 năm</li>
              <li>
                <i class="icofont-check-circled"></i>
                <span style="color: #ff0000">Backup, sao lưu dữ liệu hàng tuần</span>
              </li>
              <li>
                <i class="icofont-check-circled"></i>
                <span style="color: #ff0000">Cam kết hoàn tiền 100% nếu không hài lòng</span>
              </li>
              <li>
                <i class="icofont-check-circled"></i>
                <span style="color: #ff0000">Bàn giao code, hosting, tên miền</span>
              </li>
              <li>
                <i class="icofont-check-circled"></i>
                <span style="color: #ff0000">Cam kết tốc độ tải trang dưới 3 giây</span>
              </li>
            </ul>
          </div>

          <div class="plan-btn">
            <a routerLink="/lien-he">Tư vấn</a>
          </div>
        </div>
      </div>

      <div class="col-lg-6 col-md-6 offset-md-3 col-sm-6 offset-lg-0 offset-sm-3">
        <div class="price-card-area">
          <div class="price-head-text">
            <h4>A3 – Pro: Chuyên nghiệp</h4>
            <h2>Từ 4.8 triệu < 10 triệu</h2>
          </div>

          <div class="plan-features">
            <ul>
              <li>
                <i class="icofont-check-circled"></i>
                <span style="color: #3e5684">Thời gian thực hiện: 6 - 7 ngày</span>
              </li>
              <li>
                <i class="icofont-check-circled"></i> Tặng tên miền trị giá
                350,000đ
              </li>
              <li>
                <i class="icofont-check-circled"></i> Tặng hosting SSD cao cấp
                băng thông không giới hạn
              </li>
              <li>
                <i class="icofont-check-circled"></i> Tặng 05 email tên miền
                chuyên nghiệp
              </li>
              <li><i class="icofont-check-circled"></i> Tặng All in One SEO</li>
              <li>
                <i class="icofont-check-circled"></i> Phần mềm bản quyền chống
                virus
              </li>
              <li>
                <i class="icofont-check-circled"></i> Tặng 02 banner thiết kế
                đẹp
              </li>
              <li>
                <i class="icofont-check-circled"></i> Tặng SSL – Chứng chỉ bảo
                mật
              </li>
              <li><i class="icofont-check-circled"></i> Thiết kế chuẩn SEO</li>
              <li>
                <i class="icofont-check-circled"></i> Giao diện thân thiện trên
                di động, máy tính bảng,...
              </li>
              <li>
                <i class="icofont-check-circled"></i> Tích hợp Hotline, Zalo,
                Facebook, Youtube, Messenger
              </li>
              <li><i class="icofont-check-circled"></i> Bảo hành trọn đời</li>
              <li>
                <i class="icofont-check-circled"></i>
                <span style="color: #ff0000">Backup, sao lưu dữ liệu hàng tuần</span>
              </li>
              <li>
                <i class="icofont-check-circled"></i>
                <span style="color: #ff0000">Cam kết hoàn tiền 100% nếu không hài lòng</span>
              </li>
              <li>
                <i class="icofont-check-circled"></i>
                <span style="color: #ff0000">Bàn giao code, hosting, tên miền</span>
              </li>
              <li>
                <i class="icofont-check-circled"></i>
                <span style="color: #ff0000">Cam kết tốc độ tải trang dưới 3 giây</span>
              </li>
              <li>
                <i class="icofont-check-circled"></i> Tích hợp công cụ Báo cáo
                Google Analytics
              </li>
              <li>
                <i class="icofont-check-circled"></i> Đăng và tối ưu 10 - 15 sản
                phẩm/bài viết
              </li>
            </ul>
          </div>

          <div class="plan-btn">
            <a routerLink="/lien-he">Tư vấn</a>
          </div>
        </div>
      </div>

      <div class="col-lg-6 col-md-6 offset-md-3 col-sm-6 offset-lg-0 offset-sm-3">
        <div class="price-card-area">
          <div class="price-head-text">
            <h4>A4 – VIP: Cao cấp</h4>
            <h2>Từ 10 triệu</h2>
          </div>

          <div class="plan-features">
            <ul>
              <li>
                <i class="icofont-check-circled"></i>
                <span style="color: #3e5684">Thời gian thực hiện: Tuỳ độ phức tạp của dự án</span>
              </li>
              <li>
                <i class="icofont-check-circled"></i> Tặng tên miền trị giá
                350,000đ
              </li>
              <li>
                <i class="icofont-check-circled"></i> Tặng hosting SSD cao cấp
                băng thông không giới hạn
              </li>
              <li>
                <i class="icofont-check-circled"></i> Tặng 05 email tên miền
                chuyên nghiệp
              </li>
              <li><i class="icofont-check-circled"></i> Tặng All in One SEO</li>
              <li>
                <i class="icofont-check-circled"></i> Phần mềm bản quyền chống
                virus
              </li>
              <li>
                <i class="icofont-check-circled"></i> Tặng 02 banner thiết kế
                đẹp
              </li>
              <li>
                <i class="icofont-check-circled"></i> Tặng SSL – Chứng chỉ bảo
                mật
              </li>
              <li><i class="icofont-check-circled"></i> Thiết kế chuẩn SEO</li>
              <li>
                <i class="icofont-check-circled"></i> Giao diện thân thiện trên
                di động, máy tính bảng,...
              </li>
              <li>
                <i class="icofont-check-circled"></i> Tích hợp Hotline, Zalo,
                Facebook, Youtube, Messenger
              </li>
              <li><i class="icofont-check-circled"></i> Bảo hành trọn đời</li>
              <li>
                <i class="icofont-check-circled"></i>
                <span style="color: #ff0000">Backup, sao lưu dữ liệu hàng tuần</span>
              </li>
              <li>
                <i class="icofont-check-circled"></i>
                <span style="color: #ff0000">Cam kết hoàn tiền 100% nếu không hài lòng</span>
              </li>
              <li>
                <i class="icofont-check-circled"></i>
                <span style="color: #ff0000">Bàn giao code, hosting, tên miền</span>
              </li>
              <li>
                <i class="icofont-check-circled"></i>
                <span style="color: #ff0000">Cam kết tốc độ tải trang dưới 3 giây</span>
              </li>
              <li>
                <i class="icofont-check-circled"></i> Tích hợp công cụ Báo cáo
                Google Analytics
              </li>
              <li>
                <i class="icofont-check-circled"></i> Đăng và tối ưu 10 - 15 sản
                phẩm/bài viết
              </li>
            </ul>
          </div>

          <div class="plan-btn">
            <a routerLink="/lien-he">Tư vấn</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="carousel-home container">
  <div class="row">
    <div class="col-12">
      <div class="service-style-three bg-white">
        <div class="container-fluid">
          <div class="service-slider-5item owl-carousel owl-theme">
            <div class="service-item img-slide-home d-flex align-items-center justify-content-center" *ngFor="let item of listHopTac">
              <img src="{{item.url}}" alt="Development"
                class="center-img " />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <section class="team-section pb-70">
  <div class="container">
    <div class="section-head text-center">
      <h2>Our <span>Team Members</span></h2>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor contratc ut labore et dolore magna aliqua.
      </p>
    </div>

    <div class="row">
      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="team-card">
          <div class="team-img">
            <img src="assets/img/team/1.png" alt="team member" />

            <div class="team-social">
              <a href="#" target="_blank"><i class="icofont-facebook"></i></a>
              <a href="#" target="_blank"><i class="icofont-twitter"></i></a>
              <a href="#" target="_blank"><i class="icofont-linkedin"></i></a>
              <a href="#" target="_blank"><i class="icofont-instagram"></i></a>
            </div>
          </div>

          <div class="team-text">
            <h4>John Smith</h4>
            <p>Head of Cleaner</p>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="team-card">
          <div class="team-img">
            <img src="assets/img/team/2.png" alt="team member" />

            <div class="team-social">
              <a href="#" target="_blank"><i class="icofont-facebook"></i></a>
              <a href="#" target="_blank"><i class="icofont-twitter"></i></a>
              <a href="#" target="_blank"><i class="icofont-linkedin"></i></a>
              <a href="#" target="_blank"><i class="icofont-instagram"></i></a>
            </div>
          </div>

          <div class="team-text">
            <h4>Marry</h4>
            <p>Cleaner</p>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="team-card">
          <div class="team-img">
            <img src="assets/img/team/3.png" alt="team member" />

            <div class="team-social">
              <a href="#" target="_blank"><i class="icofont-facebook"></i></a>
              <a href="#" target="_blank"><i class="icofont-twitter"></i></a>
              <a href="#" target="_blank"><i class="icofont-linkedin"></i></a>
              <a href="#" target="_blank"><i class="icofont-instagram"></i></a>
            </div>
          </div>

          <div class="team-text">
            <h4>John Smith</h4>
            <p>Office Cleaner</p>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="team-card">
          <div class="team-img">
            <img src="assets/img/team/4.png" alt="team member" />

            <div class="team-social">
              <a href="#" target="_blank"><i class="icofont-facebook"></i></a>
              <a href="#" target="_blank"><i class="icofont-twitter"></i></a>
              <a href="#" target="_blank"><i class="icofont-linkedin"></i></a>
              <a href="#" target="_blank"><i class="icofont-instagram"></i></a>
            </div>
          </div>

          <div class="team-text">
            <h4>Eve</h4>
            <p>Assistant</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->

<!-- <div class="testimonial-section testimonial-bg ptb-100">
  <div class="testimonial-area">
    <div class="container">
      <div class="testimonial-slider owl-carousel owl-theme text-center">
        <div class="sin-testiText">
          <h2>Stepheny Mc.man</h2>
          <span>Co-worker of 3S</span>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do is
            ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices .
            Risus commodo viverra maecenas accumsan lacus vel facilisis.
          </p>
        </div>

        <div class="sin-testiText">
          <h2>Jackson</h2>
          <span>Graphics Designer of 3S</span>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do is
            ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices .
            Risus commodo viverra maecenas accumsan lacus vel facilisis.
          </p>
        </div>

        <div class="sin-testiText">
          <h2>Amelia</h2>
          <span>Web Developer of 3S</span>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do is
            ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices .
            Risus commodo viverra maecenas accumsan lacus vel facilisis.
          </p>
        </div>
      </div>
    </div>
  </div>
</div> -->

<!-- <section class="blog-section pt-100">
  <div class="container">
    <div class="section-head text-center">
      <h2>Latest <span>Blogs</span></h2>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor contratc ut labore et dolore magna aliqua.
      </p>
    </div>

    <div class="blog-slider owl-carousel owl-theme">
      <div class="blog-area">
        <div class="blog-img">
          <a routerLink="/blog-details" class="d-block"
            ><img src="assets/img/blog/1.png" alt="blog image"
          /></a>
        </div>

        <div class="blog-text">
          <h4><a routerLink="/blog-details">How to cleanroom yourself</a></h4>
          <ul>
            <li><i class="icofont-calendar"></i> 20 Feb 2020</li>
            <li>
              <i class="icofont-ui-user"></i> <a routerLink="/">John Doe</a>
            </li>
          </ul>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor contratc ut labore.
          </p>
          <a routerLink="/blog-details" class="blog-btn"
            >Chi tiết <i class="icofont-rounded-right"></i
          ></a>
        </div>
      </div>

      <div class="blog-area">
        <div class="blog-img">
          <a routerLink="/blog-details" class="d-block"
            ><img src="assets/img/blog/2.png" alt="blog image"
          /></a>
        </div>

        <div class="blog-text">
          <h4><a routerLink="/blog-details">Enjoy the working time</a></h4>
          <ul>
            <li><i class="icofont-calendar"></i> 20 Feb 2020</li>
            <li>
              <i class="icofont-ui-user"></i> <a routerLink="/">John Doe</a>
            </li>
          </ul>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor contratc ut labore.
          </p>
          <a routerLink="/blog-details" class="blog-btn"
            >Chi tiết <i class="icofont-rounded-right"></i
          ></a>
        </div>
      </div>

      <div class="blog-area">
        <div class="blog-img">
          <a routerLink="/blog-details" class="d-block"
            ><img src="assets/img/blog/3.png" alt="blog image"
          /></a>
        </div>

        <div class="blog-text">
          <h4>
            <a routerLink="/blog-details">Self cleaning is the best drill</a>
          </h4>
          <ul>
            <li><i class="icofont-calendar"></i> 20 Feb 2020</li>
            <li>
              <i class="icofont-ui-user"></i> <a routerLink="/">John Doe</a>
            </li>
          </ul>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor contratc ut labore.
          </p>
          <a routerLink="/blog-details" class="blog-btn"
            >Chi tiết <i class="icofont-rounded-right"></i
          ></a>
        </div>
      </div>

      <div class="blog-area">
        <div class="blog-img">
          <a routerLink="/blog-details" class="d-block"
            ><img src="assets/img/blog/1.png" alt="blog image"
          /></a>
        </div>

        <div class="blog-text">
          <h4><a routerLink="/blog-details">How to cleanroom yourself</a></h4>
          <ul>
            <li><i class="icofont-calendar"></i> 20 Feb 2020</li>
            <li>
              <i class="icofont-ui-user"></i> <a routerLink="/">John Doe</a>
            </li>
          </ul>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor contratc ut labore.
          </p>
          <a routerLink="/blog-details" class="blog-btn"
            >Chi tiết <i class="icofont-rounded-right"></i
          ></a>
        </div>
      </div>

      <div class="blog-area">
        <div class="blog-img">
          <a routerLink="/blog-details" class="d-block"
            ><img src="assets/img/blog/2.png" alt="blog image"
          /></a>
        </div>

        <div class="blog-text">
          <h4><a routerLink="/blog-details">Enjoy the working time</a></h4>
          <ul>
            <li><i class="icofont-calendar"></i> 20 Feb 2020</li>
            <li>
              <i class="icofont-ui-user"></i> <a routerLink="/">John Doe</a>
            </li>
          </ul>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor contratc ut labore.
          </p>
          <a routerLink="/blog-details" class="blog-btn"
            >Chi tiết <i class="icofont-rounded-right"></i
          ></a>
        </div>
      </div>

      <div class="blog-area">
        <div class="blog-img">
          <a routerLink="/blog-details" class="d-block"
            ><img src="assets/img/blog/3.png" alt="blog image"
          /></a>
        </div>

        <div class="blog-text">
          <h4>
            <a routerLink="/blog-details">Self cleaning is the best drill</a>
          </h4>
          <ul>
            <li><i class="icofont-calendar"></i> 20 Feb 2020</li>
            <li>
              <i class="icofont-ui-user"></i> <a routerLink="/">John Doe</a>
            </li>
          </ul>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor contratc ut labore.
          </p>
          <a routerLink="/blog-details" class="blog-btn"
            >Chi tiết <i class="icofont-rounded-right"></i
          ></a>
        </div>
      </div>

      <div class="blog-area">
        <div class="blog-img">
          <a routerLink="/blog-details" class="d-block"
            ><img src="assets/img/blog/1.png" alt="blog image"
          /></a>
        </div>

        <div class="blog-text">
          <h4><a routerLink="/blog-details">How to cleanroom yourself</a></h4>
          <ul>
            <li><i class="icofont-calendar"></i> 20 Feb 2020</li>
            <li>
              <i class="icofont-ui-user"></i> <a routerLink="/">John Doe</a>
            </li>
          </ul>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor contratc ut labore.
          </p>
          <a routerLink="/blog-details" class="blog-btn"
            >Chi tiết <i class="icofont-rounded-right"></i
          ></a>
        </div>
      </div>

      <div class="blog-area">
        <div class="blog-img">
          <a routerLink="/blog-details" class="d-block"
            ><img src="assets/img/blog/2.png" alt="blog image"
          /></a>
        </div>

        <div class="blog-text">
          <h4><a routerLink="/blog-details">Enjoy the working time</a></h4>
          <ul>
            <li><i class="icofont-calendar"></i> 20 Feb 2020</li>
            <li>
              <i class="icofont-ui-user"></i> <a routerLink="/">John Doe</a>
            </li>
          </ul>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor contratc ut labore.
          </p>
          <a routerLink="/blog-details" class="blog-btn"
            >Chi tiết <i class="icofont-rounded-right"></i
          ></a>
        </div>
      </div>

      <div class="blog-area">
        <div class="blog-img">
          <a routerLink="/blog-details" class="d-block"
            ><img src="assets/img/blog/3.png" alt="blog image"
          /></a>
        </div>

        <div class="blog-text">
          <h4>
            <a routerLink="/blog-details">Self cleaning is the best drill</a>
          </h4>
          <ul>
            <li><i class="icofont-calendar"></i> 20 Feb 2020</li>
            <li>
              <i class="icofont-ui-user"></i> <a routerLink="/">John Doe</a>
            </li>
          </ul>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor contratc ut labore.
          </p>
          <a routerLink="/blog-details" class="blog-btn"
            >Chi tiết <i class="icofont-rounded-right"></i
          ></a>
        </div>
      </div>
    </div>
  </div>
</section> -->