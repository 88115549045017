<div class="blog-title blog-title-bg bg-2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="blog-title-text">
                    <h2>Blog Details</h2>
                    <ul>
                        <li>
                            <a routerLink="/index">Home</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            <a routerLink="/tin-tuc">Blogs</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            Blog Details
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="blog-details-area blog-details-page-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <!-- <img src="assets/img/blog/7.png" width="100%" alt="service"> -->
                <!-- <div class="blog-details-post"> -->

                <!-- NỘI DUNG -->
                <div class="thiekeweb_noidung_1">
                    <p><span style="font-size: 14pt;"><strong><span style="color: #3e5684;">Là số ít công ty thiết kế
                                    web có dẫn chứng thực tế, website do chúng tôi triển khai có tác động quan trọng đến
                                    việc mang về thành công cho nhiều khách hàng với doanh thu từ 2 tỷ – 10
                                    tỷ/tháng</span></strong>. Với kinh nghiệm gần 10 năm triển khai thành công cho nhiều
                            khách hàng không chỉ ở mảng thiết kế web, mà đồng thời trực tiếp triển khai các chiến dịch
                            quảng cáo tối ưu chi phí – mang về hiệu quả cao. Để làm được điều này đòi hỏi sự tìm tòi cái
                            mới, tối ưu liên tục từng trang đích, từng nút bấm…Mỗi ngày chúng tôi liên tục cải tiến để
                            áp dụng vào các website mới. </span></p>
                    <p><span style="font-size: 14pt;">Chúng tôi hoàn toàn đáp ứng được đa dạng các nhu cầu từ cơ bản như
                            website giới thiệu doanh nghiệp/dịch vụ, hoặc yêu cầu thiết kế một website có giao diện đẹp
                            – chuẩn SEO…và <strong>cao cấp hơn là website hội đủ 10 tiêu chuẩn website chất
                                lượng</strong> để phục vụ cho chiến lược phát triển bán hàng. Khách hàng của chúng tôi
                            đa dạng từ các doanh nghiệp/các nhân khởi nghiệp, đến các doanh nghiệp lớn muốn xây dựng hệ
                            thống website chuyên nghiệp.</span></p>
                    <p><span style="font-size: 14pt;"><span style="color: #3e5684;"><strong>Chúng tôi đã giúp cho nhiều
                                    doanh nghiệp tăng trưởng vượt trội với nguồn lực đầu tư ban đầu hạn
                                    chế.</strong></span> <strong><span style="color: #ff0000;">Và đây là sự khác biệt
                                    lớn giữa chúng tôi với các công ty thiết kế website khác.</span></strong></span></p>
                    <p><span style="font-size: 14pt;">Bài viết này có nội dung khá dài, không chỉ là bài quảng cáo, đó
                            là sự chia sẻ từ gần 10 năm kinh nghiệm của chúng tôi chia sẻ đến quý doanh nghiệp. <span
                                style="color: #ff0000;"><strong>Nếu bạn dành thời gian đọc kỹ, chúng tôi tin rằng bài
                                    viết có thể giúp ích nhiều cho bạn.</strong></span></span></p>
                    <h3>Trước tiên, cùng hiểu rõ <strong>4 lợi ích vượt trội</strong> từ việc <strong>Sở hữu website
                            chuyên nghiệp mang lại</strong></h3>
                </div>

                <!-- LỢI ÍCH -->
                <div class="row">
                    <div class="col-12">
                        <div class="slider-container">
                            <div class="slider-blog-details-full-1-slider">
                                <div class="slide">
                                    <div class="row align-items-center">
                                        <div class="col-lg-6">
                                            <div class="thiekeweb__loiich_items_1">
                                                <h3>1. Tạo hiệu quả kinh doanh vượt trội</h3>
                                                <p>Gần 90% website được thiết kế <strong>chưa đạt chuẩn, hoặc không
                                                        được làm nội dung bài
                                                        bản</strong>. Bạn để ý kỹ xem, mỗi doanh nghiệp lớn trong
                                                    mỗi lĩnh vực, website của
                                                    họ được thiết kế rất bài bản từ giao diện đến nội dung. Web càng
                                                    chất lượng càng giúp họ
                                                    tối ưu chi phí, gia tăng hiệu quả. Nên họ luôn <span
                                                        style="color: #3e5684;"><strong>“liên tục cải
                                                            tiến”</strong></span> để tạo ra trải
                                                    nghiệm website tốt nhất. Trong khi các yếu tố về giá cả, dịch vụ
                                                    tương tự nhau,
                                                    <strong>sở hữu web chất lượng chính là sự khác biệt giúp bạn lên
                                                        so với đối thủ hoặc
                                                        theo kịp những đối thủ mạnh hơn trước đó.</strong>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <img src="../../../../assets/img/loi-ich-website-ban-hang-1.png" alt=""
                                                draggable="false" srcset="">
                                        </div>
                                    </div>
                                </div>
                                <div class="slide">
                                    <div class="row align-items-center">
                                        <div class="col-lg-6">
                                            <div class="thiekeweb__loiich_items_1">
                                                <h3>2. Tối ưu hiệu quả chiến dịch quảng cáo</h3>
                                                <p>Với <strong>phần lớn</strong> các lĩnh vực, quảng cáo trên Google
                                                    hiện là kênh mang về
                                                    hiệu quả kinh doanh tốt nhất rồi đến facebook, tiktok… Dựa trên kinh
                                                    nghiệm triển khai
                                                    quảng cáo <strong>HIỆU QUẢ </strong>(Xem chi tiết các dự án chúng
                                                    tôi triển khai thành
                                                    công ở phần Case study – Câu chuyện thành công)&nbsp;cho hơn 100
                                                    khách hàng với ngân
                                                    sách hàng trăm tỉ/năm đã chi tiêu. Chúng tôi đúc rút ra rằng,
                                                    website được thiết kế đẹp,
                                                    tốc độ tải trang nhanh là yếu tố quan trọng nhất ảnh hưởng đến hiệu
                                                    quả các chiến dịch
                                                    quảng cáo.</p>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <img src="../../../../assets/img/loi-ich-website-ban-hang-2.png" alt=""
                                                draggable="false" srcset="">
                                        </div>
                                    </div>
                                </div>
                                <div class="slide">
                                    <div class="row align-items-center">
                                        <div class="col-lg-6">
                                            <div class="thiekeweb__loiich_items_1">
                                                <h3>3. Tăng chuyển đổi bán hàng</h3>
                                                <p>“Hành động chuyển đổi” là hành vi khách hàng click vào các nút Gọi
                                                    điện, Chat Zalo, Để
                                                    lại Email hoặc chất lượng hơn là Đặt hàng. Website chất lượng là web
                                                    thiết kế các nút
                                                    chuyển đổi này nổi bật, có tính thúc đẩy hành động của khách hàng.
                                                    Càng nhiều hành động
                                                    chuyển đổi được ghi nhận, quảng cáo của bạn càng rẻ, lượng khách
                                                    hàng tiếp cận càng lớn.
                                                    Mục tiêu đầu tiên quan trọng nhất của người làm marketing hay xa hơn
                                                    làm kinh doanh đó
                                                    chính là “thu hút nhiều hành động chuyển đổi” nhất có thể</p>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <img src="../../../../assets/img/loi-ich-website-ban-hang-3.png" alt=""
                                                draggable="false" srcset="">
                                        </div>
                                    </div>
                                </div>
                                <div class="slide">
                                    <div class="row align-items-center">
                                        <div class="col-lg-6">
                                            <div class="thiekeweb__loiich_items_1">
                                                <h3>4. Nâng cao giá trị thương hiệu</h3>
                                                <p>Ngày nay, website đã được coi là 1 trong những thành phần quan trọng
                                                    trong bộ nhận diện
                                                    thương hiệu của doanh nghiệp. Sở hữu website thiết kế chuyên nghiệp
                                                    giúp <strong>tạo
                                                        niềm tin về sự uy tín – chuyên nghiệp với Khách hàng, Đối tác.
                                                    </strong>Website cũng
                                                    là kênh quan trọng để tạo ấn tượng đối các&nbsp;<strong>Nhân sự
                                                        tương lai </strong>tìm
                                                    hiểu về doanh nghiệp của bạn.</p>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <img src="../../../../assets/img/loi-ich-website-ban-hang-4.png" alt=""
                                                draggable="false" srcset="">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Dots (indicators) -->
                            <div class="dots">
                                <span class="dot active" data-index="0"></span>
                                <span class="dot" data-index="1"></span>
                                <span class="dot" data-index="2"></span>
                                <span class="dot" data-index="3"></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="tieu-chi-web-content" id="tieuchuan">
                            <h2 class="tc-title">10 tiêu chuẩn quan trọng cần có của một website chất lượng</h2>
                            <p>Chúng tôi chia sẻ 10 tiêu chuẩn <strong><span style="color: #ff0000;">từ cơ
                                        bản</span></strong> đến <strong><span style="color: #ff0000;">nâng
                                        cao</span></strong> mà chúng tôi đang áp dụng để thiết kế website chất lượng.
                                Nếu bạn không lựa chọn dịch vụ thiết kế web của chúng tôi, hy vọng các kiến thức này có
                                thể giúp bạn trong việc lựa chọn các công ty thiết kế web phù hợp.</p>
                            <p>Với một website không đạt chuẩn, ví dụ như tốc độ tải trang chậm hơn 3 giây, thì 90%
                                khách hàng sẽ thoát ngay khỏi web của bạn. Đặc biệt, nếu bạn thiết kế web để chạy quảng
                                cáo Google <strong>(phần lớn khách hàng tìm đến Newtrust cho mục đích này)</strong>
                                – điều này sẽ khiến ngân sách quảng cáo của bạn bị lãng phí, ảnh hưởng tiêu cực đến SEO.
                                Số tiền bị lãng phí một tháng do web không tối ưu ảnh hưởng đến quảng cáo còn cao hơn cả
                                chi phí thiết kế website.</p>
                            <p><strong><span style="color: #3e5684;">Dưới đây là 10 tiêu chí chi tiết. Click vào từng
                                        mục để xem chi tiết.</span></strong></p>
                            <div class="tc-list-box">
                                <div class="tc-list1 row ul">
                                    <div class="col-lg-6">
                                        <div class="row">
                                            <div class="col-lg-12 li">
                                                <div class="tc-qs" (click)="ToggleTieuChi('TieuChi1')"><label
                                                        class="tc-text">Website dễ sử dụng, thân
                                                        thiện</label><br>
                                                    <span class="tc-number">1</span>
                                                </div>
                                                <div class="tc-aw TieuChi1" *ngIf="TieuChi1" @expandCollapse><span
                                                        style="color: #3e5684;"><strong>Ở phía khách
                                                            hàng:</strong></span><br>
                                                    Web có giao diện đẹp mà khó sử dụng như: <span
                                                        style="color: #ff0000;">cần
                                                        đến quá 3 thao tác mới thấy nội dung cần tìm; các bước đặt mua
                                                        hàng phải
                                                        qua quá 3 bước; lạm dụng hiệu ứng; bộ lọc hoặc chức năng tìm
                                                        kiếm không
                                                        thông minh…</span>thì cũng ít giá trị. Yếu tố đầu tiên của một
                                                    website
                                                    chất lượng là website cần dễ sử dụng. Những điểm thể hiện web thân
                                                    thiện, dễ
                                                    dùng:<br>
                                                    <span style="color: #000000;">1. Bố cục giao diện dễ nhìn</span><br>
                                                    <span style="color: #000000;">2. Kích thước chữ không bị nhỏ (nên từ
                                                        14 –
                                                        18px tùy font chữ)</span><br>
                                                    <span style="color: #000000;">3. Menu điều hướng nổi bật</span><br>
                                                    <span style="color: #000000;">4. Nếu là web bán hàng thì trang danh
                                                        mục sản
                                                        phẩm có các bộ lọc thông minh</span><br>
                                                    <span style="color: #000000;">5. Trang chi tiết sản phẩm trình bày
                                                        đẹp, đầy
                                                        đủ thông tin</span><br>
                                                    <span style="color: #000000;">6. Chức năng đặt hàng hoặc liên hệ đơn
                                                        giản sẽ
                                                        giúp gia tăng tỷ lệ chuyển đổi khách hàng</span>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 li">
                                                <div class="tc-qs" (click)="ToggleTieuChi('TieuChi2')"><label
                                                        class="tc-text">Hệ thống quản trị dễ sử
                                                        dụng</label><br>
                                                    <span class="tc-number">2</span>
                                                </div>
                                                <div class="tc-aw TieuChi2" *ngIf="TieuChi2" @expandCollapse><span
                                                        style="color: #3e5684;"><strong>Ở phía người quản trị
                                                            web</strong></span><br>
                                                    1. Dễ dàng đăng bài viết về tin tức – dịch vụ hoặc sản phẩm.<br>
                                                    2. Dễ dàng TÌM, THÊM, SỬA, XÓA các thành phần (module) chính của
                                                    website:
                                                    Sản phẩm, bài viết, danh mục, banner<br>
                                                    3. Các thông tin nếu thường xuyên cần thay đổi ví dụ như các banner
                                                    chương
                                                    trình khuyến mãi thì được thiết kế để người quản trị web chủ động
                                                    thay đổi
                                                    được<br>
                                                    4. Có thể dễ dàng phân quyền nhiều cấp độ cho người quản trị website
                                                    và vừa
                                                    cần bảo mật cao</div>
                                            </div>
                                            <div class="col-lg-12 li">
                                                <div class="tc-qs" (click)="ToggleTieuChi('TieuChi3')"><label
                                                        class="tc-text">Tốc độ tải trang nhanh dưới 3
                                                        giây</label><br>
                                                    <span class="tc-number">3</span>
                                                </div>
                                                <div class="tc-aw TieuChi3" *ngIf="TieuChi3" @expandCollapse>Tốc độ web
                                                    chỉ chậm 1 giây
                                                    thì khả <span style="color: #ff0000;">năng mất đi 20% khách hàng
                                                    </span>(theo thống kê
                                                    từ Google). Đặc biệt tỷ lệ truy cập web trên di động ngày càng cao
                                                    (hiện đạt
                                                    khoảng 70%) thì tốc độ web càng cần phải nhanh vì sự kiên nhẫn của
                                                    khách
                                                    hàng khi truy cập trên di động không cao bằng khi truy cập trên máy
                                                    tính.
                                                    Những đơn vị lớn như Thegioididong họ có tiêu chuẩn full load tối đa
                                                    là 3
                                                    giây. Hiện website do Newtrust làm đều đạt được chuẩn này. Thực
                                                    tế
                                                    khoảng 2 giây.</div>
                                            </div>
                                            <div class="col-lg-12 li">
                                                <div class="tc-qs" (click)="ToggleTieuChi('TieuChi4')"><label
                                                        class="tc-text">Giao diện di động thân
                                                        thiện</label><br>
                                                    <span class="tc-number">4</span>
                                                </div>
                                                <div class="tc-aw TieuChi4" *ngIf="TieuChi4" @expandCollapse>70% khách
                                                    hàng truy cập qua
                                                    di động, nên website của bạn cần
                                                    có trải nghiệm tốt trên di động. Nếu web bạn không có giao diện di
                                                    động tốt,
                                                    khả năng trên 70% khách hàng của bạn sẽ rời ngay khi vào website của
                                                    bạn.
                                                    Bạn chỉ cần nhập link website cần triển khai vào công cụ đo độ thân
                                                    thiện
                                                    của Google
                                                    <a href="https://search.google.com/test/mobile-friendly"
                                                        target="_blank" rel="noopener"><strong>tại đây</strong></a>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 li">
                                                <div class="tc-qs" (click)="ToggleTieuChi('TieuChi5')"><label
                                                        class="tc-text">Link điều hướng, Bộ lọc thông
                                                        minh</label><br>
                                                    <span class="tc-number">5</span>
                                                </div>
                                                <div class="tc-aw TieuChi5" *ngIf="TieuChi5" @expandCollapse>1.
                                                    <strong>Link điều hướng
                                                    </strong><em>(hoặc
                                                        breadcrumbs)</em> nổi bật giúp gia tăng trải nghiệm Khách hàng
                                                    qua cách
                                                    giúp Khách hàng dễ tương tác với những danh mục liên quan khác. Đây
                                                    là 1
                                                    trong các tiêu chí quan trọng mà website cần có khi SEO website. Đây
                                                    là ví
                                                    dụ về breadcrumb: <a
                                                        href="../../../../assets/img/day-la-breadcrumb.png"
                                                        target="_blank" rel="noopener"><strong>Xem</strong></a><br>
                                                    2. <strong>Bộ lọc thông minh:</strong> Với web bán hàng, bộ lọc sản
                                                    phẩm cần
                                                    có các tiêu chí lọc thông minh để Khách hàng dễ lựa chọn và so sánh
                                                    các sản
                                                    phẩm với nhau
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="row">
                                            <div class="col-lg-12 li">
                                                <div class="tc-qs" (click)="ToggleTieuChi('TieuChi6')"><label
                                                        class="tc-text">Thiết kế Website chuẩn SEO</label><br>
                                                    <span class="tc-number">6</span>
                                                </div>
                                                <div class="tc-aw TieuChi6" *ngIf="TieuChi6" @expandCollapse>Là yếu tố
                                                    cơ bản cần phải
                                                    có hiện nay khi thiết kế web. Các
                                                    website chúng tôi thiết kế đều đạt chuẩn này. Có nhiều tiêu chí để
                                                    đánh giá
                                                    website chuẩn SEO.  Với trên 8 năm kinh nghiệm (đội ngũ nhân
                                                    sự có hơn
                                                    10 năm kinh nghiệm) trong lĩnh vực triển khai dịch vụ SEO và Thiết
                                                    kế web
                                                    chuẩn SEO, chúng tôi tự tin website do chúng tôi thiết kế đảm bảo
                                                    các yếu tố
                                                    chuẩn SEO. Thể hiện qua một số lĩnh vực có độ cạnh tranh cao như
                                                    <strong>xây
                                                        dựng, nội thất, vật liệu xây dựng</strong>&nbsp;chúng chúng tôi
                                                    đã SEO
                                                    thành công với một số dự án điển hình như
                                                    <strong>KinhNghiemLamNha.net</strong> có lượng traffics gần 100,000
                                                    truy
                                                    cập/tháng.
                                                </div>
                                            </div>
                                            <div class="col-lg-12 li">
                                                <div class="tc-qs" (click)="ToggleTieuChi('TieuChi7')"><label
                                                        class="tc-text">Danh mục sản phẩm thông
                                                        minh</label><br>
                                                    <span class="tc-number">7</span>
                                                </div>
                                                <div class="tc-aw TieuChi7" *ngIf="TieuChi7" @expandCollapse>Với trang
                                                    web bán hàng,
                                                    trang <strong>Danh mục sản
                                                        phẩm</strong> là trang có vai trò quan trọng hàng đầu, vì đây là
                                                    trang
                                                    mà phần lớn Khách hàng sẽ truy cập <span
                                                        style="color: #ff0000;"><strong>ĐẦU
                                                            TIÊN</strong></span> để tìm thông tin sản phẩm. Vì vậy trang
                                                    này cần
                                                    được thiết kế sáng sủa, bộ lọc hoặc bộ điều hướng thông minh, giúp
                                                    Khách
                                                    hàng dễ dàng duyệt tìm các sản phẩm họ cần. Nếu không sẽ không có
                                                    bước quan
                                                    trọng tiếp theo là việc Khách hàng truy cập vào trang <strong>Chi
                                                        tiết sản
                                                        phẩm</strong></div>
                                            </div>
                                            <div class="col-lg-12 li">
                                                <div class="tc-qs" (click)="ToggleTieuChi('TieuChi8')"><label
                                                        class="tc-text">Trang sản phẩm thiết kế nổi
                                                        bật</label><br>
                                                    <span class="tc-number">8</span>
                                                </div>
                                                <div class="tc-aw TieuChi8" *ngIf="TieuChi8" @expandCollapse>Trang này
                                                    có vai trò quan
                                                    trọng nhất, bởi đây là trang đích
                                                    cuối nơi Khách hàng ra quyết định mua hàng hoặc nhấn vào nút Gọi
                                                    điện hoặc
                                                    để lại thông tin qua các bảng yêu cầu thông tin nên bạn cần quan tâm
                                                    đặc
                                                    biệt vào giao diện và chức năng của trang này. Vì vậy cần đầu tư
                                                    thật tốt về
                                                    giao diện, nội dung để thu hút khách hàng đặt mua hoặc liên hệ.
                                                </div>
                                            </div>
                                            <div class="col-lg-12 li">
                                                <div class="tc-qs" (click)="ToggleTieuChi('TieuChi9')"><label
                                                        class="tc-text">Nút kêu gọi hành động hấp
                                                        dẫn</label><br>
                                                    <span class="tc-number">9</span>
                                                </div>
                                                <div class="tc-aw TieuChi9" *ngIf="TieuChi9" @expandCollapse>Là các nút
                                                    như:<strong><span style="color: #3e5684;"> Đặt
                                                            hàng nhanh, Thêm vào giỏ, Hotline, Liên hệ, Zalo,
                                                            Facebook</span></strong>…web của bạn cần có các nút nổi bật
                                                    để kích
                                                    thích hành động tương tác cho ra data. Với hơn 8 năm triển khai
                                                    nhiều dự án
                                                    lớn nhỏ, chúng tôi đã đúc rút ra các giao diện nút, vị trí nút mang
                                                    lại hiệu
                                                    quả cao. Tất cả các web được thiết kế bởi Newtrust đều được tích
                                                    hợp các
                                                    chức năng này.</div>
                                            </div>
                                            <div class="col-lg-12 li">
                                                <div class="tc-qs" (click)="ToggleTieuChi('TieuChi10')"><label
                                                        class="tc-text">Điểm tối ưu từ 70 điểm trở
                                                        lên</label><br>
                                                    <span class="tc-number">10</span>
                                                </div>
                                                <div class="tc-aw TieuChi10" *ngIf="TieuChi10" @expandCollapse>Trên các
                                                    trang đánh giá
                                                    các chỉ số quan trọng là<br>
                                                    <strong>1. Google page speed</strong> nên đạt từ 70 điểm và<br>
                                                    <strong>2. Quan tâm đến trải nghiệm Core Web Vitals</strong>.<br>
                                                    <strong>Lưu ý:</strong> <span style="color: #3e5684;"><strong>Những
                                                            chỉ số
                                                            này thường dành cho các website có giá trị thiết kế trên 10
                                                            triệu</strong></span>. Nếu đạt được 9/10 các tiêu chí đầu,
                                                    website
                                                    của bạn đã nằm trong top 10% website được tối ưu đạt các tiêu chuẩn
                                                    cả về kỹ
                                                    thuật lẫn trải nghiệm rồi. Vì để tối ưu các chỉ số này tốn rất nhiều
                                                    công
                                                    tinh chỉnh hoặc fix từng vấn đề nhỏ. Nhiều khi website được thiết kế
                                                    đã đạt
                                                    chuẩn các chỉ số này, nhưng chỉ vì có vài ảnh đăng lên có dung lượng
                                                    vượt
                                                    ngưỡng – ví dụ ảnh chỉ cần tầm 200Mb mà lại đăng ảnh có dung lượng
                                                    2Mb thì
                                                    điểm chỉ số này bị ảnh hưởng lớn. Nếu bạn mới khởi nghiệp thì hiện
                                                    chưa cần
                                                    quá quan tâm đến các chỉ số này.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="lua-chon-cty-tkw-content">
                    <h2 class="tc-title">Lựa chọn công ty thiết kế web uy tín, chuyên nghiệp</h2>
                    <p>Hiện kênh tìm kiếm khách hàng qua website chiếm đến 90% hầu hết các lĩnh vực, là nền tảng để phục
                        vụ cho các hoạt động marketing, quảng cáo, SEO. Nên việc <span style="color: #ff0000;">tìm được
                            công ty thiết kế website uy tín, có năng lực </span>thực sự rất quan trọng<span
                            style="color: #ff0000;">. Nói không quá, website không chất lượng có khả năng ảnh hưởng đến
                            hơn 50% doanh thu của doanh nghiệp</span>. Hiện nay có rất nhiều công ty cung cấp dịch vụ
                        thiết kế website. Giá thiết kế web cũng có sự chênh lệch rất lớn. Một website <span
                            style="color: #302f2f;"><strong>nhìn có vẻ giống nhau về giao diện</strong></span> có công
                        ty thiết kế web chỉ làm web chỉ từ <strong>2 – 3 triệu</strong>, nhưng cũng có công ty thiết kế
                        web làm web đó thể đến <strong>20 – 30 triệu</strong> hoặc hơn. Sự chênh lệch lớn này thường là
                        do bên làm web từ 2 – 3 triệu chỉ “nhái” được giao diện hiển thị bên ngoài, trong khi chức năng
                        bên trong của website 20 – 30 triệu mới là thứ đáng giá. Giao diện và chức năng luôn phải đi
                        cùng nhau, web rẻ mà chức năng không đáp ứng thì cũng không có giá trị gì. Đây là điều gây khó
                        khăn cho nhiều khách hàng khi cần thiết kế web.</p>
                    <p><span style="color: #3e5684;"><strong>Newtrust đánh giá một công ty thiết kế web uy tín, chất
                                lượng dựa trên 4 tiêu chí sau:</strong></span></p>
                    <ul>
                        <li><label>Dự án nổi bật công ty đó đã làm</label><br>
                            “Những việc bạn đã làm thể hiện năng lực của bạn!” Nhìn vào những dự án công ty thiết kế web
                            uy tín đã làm sẽ giúp bạn đánh giá được sản phẩm đó có chất lượng hay không. Bạn có thể xem
                            một số Khách hàng nổi bật mà bộ phận Dịch vụ marketing của chúng tôi đã thiết kế và vận hành
                            hoạt động marketing thành công, với mức doanh thu từ 2 tỷ/tháng trở lên <span class="lc-number">01</span></li>
                        <li><label>Số năm kinh nghiệm của công ty thiết kế website</label><br>
                            Số năm kinh nghiệm là một yếu tố đáng để bạn quan tâm bởi những tích lũy của họ có thể giúp
                            bạn không phải gặp các rủi ro về những tính năng không cần thiết phải làm gây tốn chi phí.
                            Có thể một số công ty mới thành lập nhưng những thành viên trong công ty đấy đã có nhiều năm
                            kinh nghiệm ở các dự án lớn thì thì bạn cũng có thể cân nhắc lựa chọn.<span
                                class="lc-number">02</span></li>
                        <li><label>Chi phí thiết kế website phù hợp</label><br>
                            Nếu chọn gói web theo mẫu, và bạn mới khởi nghiệp mức đầu tư khoảng 2.5 – 4 triệu là bạn đã
                            có một website khá. Từ 4 – 10 triệu là bạn có web chất lượng tốt. Nếu có điều kiện hoặc muốn
                            hệ thống website có thể mang về mức doanh thu từ 500 triệu – 1 tỷ/tháng trở lên thì mức đầu
                            nên từ mức 10 – 20 triệu cho thiết kế web. Lưu ý không phải chỉ thiết kế website là có thể
                            mang về doanh thu ngay.<span class="lc-number">03</span></li>
                        <li><label>Chính sách hỗ trợ và Cam kết của công ty</label><br>
                            Bạn nên chọn công ty có <span style="color: #ff0000;"><em>Quy trình thiết kế web</em></span>
                            và <span style="color: #ff0000;"><em>Chính sách hỗ trợ rõ ràng</em></span>. Hãy đưa ra các
                            yêu cầu về chính sách hỗ trợ hợp lý và yêu cầu đưa vào hợp đồng. Một số tiêu chí&nbsp; tham
                            khảo: <em><strong>1.</strong> Thời gian phản hồi khi có vấn đề về web; <strong>2.</strong>
                                Hướng dẫn sử dụng hoặc hỗ trợ các thắc mắc trong quá trình quản trị website;
                                <strong>3.</strong> Kênh tiếp nhận thông tin hỗ trợ có thuận tiện không</em>.<span
                                class="lc-number">04</span></li>
                    </ul>
                </div>

                <div class="ldp_webweb_box5_bottom">
                    <div class="ldp_webweb_title tc-title">
                        <h3>8 trang chức năng quan trọng<br>
                            cần có của web bán hàng chất lượng</h3>
                        <p>Phần này áp dụng cho web bán hàng. Nếu là web về Dịch vụ/Doanh nghiệp thì thay trang Danh mục
                            sản phẩm thay thành trang Danh mục dịch vụ/Lĩnh vực; còn trang Chi tiết sản phẩm thay thành
                            trang Chi tiết dịch vụ/lĩnh vực. Bỏ giỏ hàng.</p>
                    </div>

                </div>
                <app-carousel [items]="items" [autoplay]="false" [interval]="100"
                    [showIndicators]="false"></app-carousel>

                <div class="tkw-bangia-title">
                    <h2 class="tc-title">Bảng giá thiết kế website – Chi phí thiết kế website bao nhiêu là hợp lý?</h2>
                    <p style="text-align: justify;">Là băn khoăn của rất nhiều khách hàng khi <strong>“không biết chi
                            phí
                            thiết kế website bao nhiêu là hợp lý?”. </strong>Đây là chủ đề mà trong không gian bài viết
                        này khó có thể diễn tả hết. Tôi có viết một bài chi tiết có nội dung <span
                            style="color: #ff0000;"><span style="color: #3e5684;">“<strong>Chi tiết từ A – Z các khoản
                                    chi phí thiết kế website và Chi phí duy trì website</strong>“</span><span
                                style="color: #000000;">, để ở gần cuối bài viết này sẽ giúp bạn hiểu rõ toàn bộ nội
                                dung về chủ đề này.</span></span></p>
                    <p style="text-align: justify;">Giá thiết kế website không có mức “giá sàn” nhưng cũng không quá
                        biến
                        động. Chi phí thiết kế website chủ yếu phụ thuộc vào <strong>Giao diện </strong>và<strong> Tính
                            năng của website</strong>. Nếu sử dụng ý tưởng website có sẵn thì chi phí rẻ có thể từ 2.5 –
                        10 triệu. Nếu thiết kế mới hoàn toàn thì chi phí gồm cả phần <strong>Thiết kế giao diện đồ họa +
                            Lập trình web + Kiểm thử</strong> thì chi phí sẽ cao hơn với mức giá gia động từ 2 – 5
                        triệu/trang. Newtrust.vn sẽ giúp bạn hiểu rõ tại sao lại có sự khác nhau này. Giá thành
                        website hiện chia làm 2 loại chính như sau:</p>
                    <p style="text-align: justify;"><span
                            style="text-decoration: underline; color: #ff0000;"><strong>Gói
                                web theo mẫu:</strong></span> là các website đã có khung ý tưởng, giao diện sẵn. Thời
                        gian triển khai nhanh, chi phí rẻ phù hợp để làm web giới thiệu doanh nghiệp hoặc doanh nghiệp
                        <strong>trung bình, nhỏ</strong>. Giá giao động từ 3.0 triệu – dưới 10&nbsp; triệu.
                    </p>
                    <p style="text-align: justify;"><span
                            style="text-decoration: underline; color: #ff0000;"><strong>Gói
                                thiết kế mới theo yêu cầu</strong></span>: là gói thiết kế theo yêu cầu riêng của Khách
                        hàng có giao diện độc quyền chuyên sâu hoặc yêu cầu làm tương tự theo 1 web cụ thể có trên thị
                        trường. Thiết kế web mới cần thiết kế mới cả giao diện đồ họa và lập trình chức năng nên thời
                        gian triển khai thường cao hơn gấp 3 – 5 lần so với website theo mẫu nên chi phí cũng cao hơn
                        tương ứng từ 3 – 5 lần. Web theo yêu cầu phù hợp với các doanh nghiệp lớn có ngân sách tốt, cần
                        website thiết kế khác biệt, cần web thực sự khác biệt phục vụ cho các mục tiêu triển khai các
                        chiến dịch marketing/quảng cáo tổng thể – đáp ứng được các mục tiêu doanh thu từ trên 500 triệu
                        – đến trên vài tỷ doanh thu/tháng trở lên.</p>
                </div>

                <div class="list-baogia-box">
                    <div class="row">
                        <div class="col-xs-12 col-sm-6 col-md-3 col-baogia pb-1">
                            <div class="baogia-box baogia-box1">
                                <h3 class="baogia-top-title">A1 – Khởi nghiệp</h3>
                                <div class="baogia-details">
                                    <p>Phù hợp với khách hàng là cá nhân mới khởi nghiệp</p>
                                    <div class="baogia-price">
                                        <h2>Từ 2.5 &lt; 3.8 triệu</h2>
                                    </div>
                                    <div class="baogia-featured">
                                        <ul>
                                            <li><strong><span style="color: #fff;">MẪU WEB GÓI A1:</span></strong><a
                                                    class="goiweb" title="Xem ngay"
                                                    href="https://thietkewebre.vn/danh-muc/thiet-ke-website-startup/"
                                                    target="_blank" rel="noopener">Xem ngay</a></li>
                                            <li><span style="color: #3e5684;">Thời gian thực hiện: 1 – 2 ngày</span>
                                            </li>
                                            <li>Tặng tên miền quốc tế trị giá 350,000</li>
                                            <li>Tặng hosting SSD cao cấp băng thông không giới hạn</li>
                                            <li>Tặng 03 email tên miền chuyên nghiệp</li>
                                            <li>Tặng Yoast SEO Premium bản quyền</li>
                                            <li>Phần mềm bản quyền chống virus</li>
                                            <li>Tặng 02 banner thiết kế đẹp</li>
                                            <li>Tặng SSL – Chứng chỉ bảo mật</li>
                                            <li>Thiết kế chuẩn SEO</li>
                                            <li>Giao diện thân thiện trên di động</li>
                                            <li>Tích hợp Hotline, Zalo, Facebook, Youtube, Livechat</li>
                                            <li>Bảo hành trọn đời</li>
                                            <li><span style="color: #ff0000;">Backup, sao lưu dữ liệu hàng tuần</span>
                                            </li>
                                            <li><span style="color: #ff0000;">Cam kết hoàn tiền 100% nếu không hài
                                                    lòng</span></li>
                                            <li><span style="color: #ff0000;">Bàn giao code, hosting, tên miền</span>
                                            </li>
                                            <li><a id="btnForm4" class="btnForm zoom-button" title="Đăng ký"
                                                    href="#dangky-goi-thiet-ke-website">Tư vấn gói này</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-3 col-baogia pb-1">
                            <div class="baogia-box baogia-box1">
                                <h3 class="baogia-top-title">A2 – Gói cơ bản</h3>
                                <div class="baogia-details">
                                    <h2></h2>
                                    <p>Tối ưu tốt cho quảng cáo bán hàng, mức đầu tư vừa phải. Có thể nâng cấp dần</p>
                                    <div class="baogia-price">
                                        <h2>Từ 3.8 triệu &lt; 4.8 triệu</h2>
                                    </div>
                                    <div class="baogia-featured">
                                        <ul>
                                            <li><strong><span style="color: #fff;">MẪU WEB GÓI A2:</span></strong><a
                                                    class="goiweb" title="Xem ngay"
                                                    href="https://thietkewebre.vn/danh-muc/thiet-ke-website-goi-co-ban/"
                                                    target="_blank" rel="noopener">Xem ngay</a></li>
                                            <li><span style="color: #3e5684;">Thời gian thực hiện: 3 ngày</span></li>
                                            <li>Tặng tên miền quốc tế trị giá 350,000</li>
                                            <li>Tặng hosting SSD cao cấp băng thông không giới hạn</li>
                                            <li>Tặng 05 email tên miền chuyên nghiệp</li>
                                            <li>Tặng Yoast SEO Premium bản quyền</li>
                                            <li>Phần mềm bản quyền chống virus</li>
                                            <li>Tặng 02 banner thiết kế đẹp</li>
                                            <li>Tặng SSL – Chứng chỉ bảo mật</li>
                                            <li>Thiết kế chuẩn SEO</li>
                                            <li>Giao diện thân thiện trên di động</li>
                                            <li>Tích hợp Hotline, Zalo, Facebook, Youtube, Livechat</li>
                                            <li>Bảo hành trọn đời</li>
                                            <li><span style="color: #ff0000;">Backup, sao lưu dữ liệu hàng tuần</span>
                                            </li>
                                            <li><span style="color: #ff0000;">Cam kết hoàn tiền 100% nếu không hài
                                                    lòng</span></li>
                                            <li><span style="color: #ff0000;">Bàn giao code, hosting, tên miền</span>
                                            </li>
                                            <li><a id="btnForm1" class="btnForm" title="Đăng ký"
                                                    href="#dangky-goi-thiet-ke-website">Tư vấn gói này</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-3 col-baogia pb-1">
                            <div class="baogia-box baogia-box2">
                                <h3 class="baogia-top-title">A3 – Pro: Chuyên nghiệp</h3>
                                <div class="baogia-details">
                                    <p><span style="color: #ff0000;">Giao diện đẹp, tối ưu toàn diện cho truyền thông
                                            quảng cáo. Mức đầu tư vừa phải cho doanh nghiệp muốn phát triển bài bản mà
                                            chi phí không quá lớn</span></p>
                                    <div class="baogia-price">
                                        <h2>Từ 4.8 triệu &lt; 10 triệu</h2>
                                    </div>
                                    <div class="baogia-featured">
                                        <ul>
                                            <li><strong><span style="color: #fff;">MẪU WEB GÓI A3:</span></strong><a
                                                    class="goiweb" title="Xem ngay"
                                                    href="https://thietkewebre.vn/danh-muc/thiet-ke-website-goi-pro/"
                                                    target="_blank" rel="noopener">Xem ngay</a></li>
                                            <li><span style="color: #3e5684;">Thời gian thực hiện: 5 ngày</span></li>
                                            <li>Tặng tên miền trị giá 350,000đ</li>
                                            <li>Tặng hosting SSD cao cấp băng thông không giới hạn</li>
                                            <li>Tặng 05 email tên miền chuyên nghiệp</li>
                                            <li>Tặng Yoast SEO Premium bản quyền</li>
                                            <li>Phần mềm bản quyền chống virus</li>
                                            <li>Tặng 02 banner thiết kế đẹp</li>
                                            <li>Tặng SSL – Chứng chỉ bảo mật</li>
                                            <li>Thiết kế chuẩn SEO</li>
                                            <li>Giao diện thân thiện trên di động</li>
                                            <li>Tích hợp Hotline, Zalo, Facebook, Youtube, Livechat</li>
                                            <li>Bảo hành trọn đời</li>
                                            <li><span style="color: #ff0000;">Backup, sao lưu dữ liệu hàng tuần</span>
                                            </li>
                                            <li><span style="color: #ff0000;">Cam kết hoàn tiền 100% nếu không hài
                                                    lòng</span></li>
                                            <li><span style="color: #ff0000;">Bàn giao code, hosting, tên miền</span>
                                            </li>
                                            <li><span style="color: #ff0000;">Cam kết tốc độ tải trang dưới 3
                                                    giây</span></li>
                                            <li>Tích hợp công cụ Báo cáo Google Analytics</li>
                                            <li>Đăng và tối ưu 30 sản phẩm</li>
                                            <li><a id="btnForm2" class="btnForm" title="Đăng ký"
                                                    href="#dangky-goi-thiet-ke-website">Tư vấn gói này</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-3 col-baogia pb-1">
                            <div class="baogia-box baogia-box3">
                                <h3 class="baogia-top-title">A4 – VIP: Cao cấp</h3>
                                <div class="baogia-details">
                                    <h2></h2>
                                    <p>Thiết kế sáng tạo, chức năng hay. Nền tảng tăng trưởng doanh thu đột phá</p>
                                    <div class="baogia-price">
                                        <h2>Từ 10.000.000</h2>
                                    </div>
                                    <div class="baogia-featured">
                                        <ul>
                                            <li><strong><span style="color: #ff0000;">Thiết kế theo yêu
                                                        cầu.</span></strong><a class="goiweb" title="Xem ngay"
                                                    href="https://thietkewebre.vn/danh-muc/thiet-ke-website-goi-vip/"
                                                    target="_blank" rel="noopener">Xem ngay</a></li>
                                            <li><strong><span style="color: #ff0000;">Thời gian thực hiện: 8 – 10
                                                        ngày</span></strong></li>
                                            <li>Tặng tên miền trị giá 350,000đ</li>
                                            <li>Tặng hosting SSD cao cấp băng thông không giới hạn</li>
                                            <li>Tặng 05 email tên miền chuyên nghiệp</li>
                                            <li>Tặng Yoast SEO Premium bản quyền</li>
                                            <li>Phần mềm bản quyền chống virus</li>
                                            <li>Tặng 02 banner thiết kế đẹp</li>
                                            <li>Tặng SSL – Chứng chỉ bảo mật</li>
                                            <li>Thiết kế chuẩn SEO</li>
                                            <li>Giao diện thân thiện trên di động</li>
                                            <li>Tích hợp Hotline, Zalo, Facebook, Youtube, Livechat</li>
                                            <li>Bảo hành trọn đời</li>
                                            <li><span style="color: #ff0000;">Backup, sao lưu dữ liệu hàng tuần</span>
                                            </li>
                                            <li><span style="color: #ff0000;">Cam kết hoàn tiền 100% nếu không hài
                                                    lòng</span></li>
                                            <li><span style="color: #ff0000;">Bàn giao code, hosting, tên miền</span>
                                            </li>
                                            <li><span style="color: #ff0000;">Cam kết tốc độ tải trang dưới 3
                                                    giây</span></li>
                                            <li>Tích hợp công cụ Báo cáo Google Analytics</li>
                                            <li>Đăng và tối ưu 30 sản phẩm</li>
                                            <li><a id="btnForm3" class="btnForm" title="Đăng ký"
                                                    href="#dangky-goi-thiet-ke-website">Tư vấn gói này</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bg-ghichu">
                    <div class="container">
                        <p><strong>Giải thích thêm: </strong><strong>Hosting cao cấp năm đầu. </strong>Hosting cấu hình
                            cao tối ưu cho tốc độ tải trang nhanh: RAM 64GB, 2CPU x 12 core 24 threads</p>
                    </div>
                </div>


                <div class="row SauTieuChuan">
                    <div class="col-12">
                        <h2 class="tc-title" style="margin: 20px 0;"><span style="color: #000;">Lý do khách hàng lựa
                                chọn NEWTRUST.VN</span></h2>
                        <div class="tieu-chi-web-content" id="tieuchuan">
                            <div class="tc-list-box">
                                <div class="tc-list1 row ul">
                                    <div class="col-lg-6">
                                        <div class="row">
                                            <div class="col-lg-12 li">
                                                <div class="tc-qs" (click)="ToggleTieuChi('TieuChi11')"><label
                                                        class="tc-text">Năng lực thực thi qua dự án</label><br>
                                                    <span class="tc-number">1</span>
                                                </div>
                                                <div class="tc-aw TieuChi11" *ngIf="TieuChi11" style="display: block;" @expandCollapse>100% các website của các
                                                    thương hiệu nổi bật như ở mục <strong><a
                                                            href="https://thietkewebre.vn/khach-hang/" target="_blank"
                                                            rel="noopener">Dự án website khách hàng nổi bật</a></strong>
                                                    là do chúng tôi triển khai. Nhiều công ty thiết kế web khác để logo
                                                    một số website của các công ty lớn như ngân hàng, tập đoàn nghìn
                                                    tỉ…để thể hiện đó là các dự án họ đã làm nhưng thực tế là không
                                                    đúng. Đa phần các website lớn sẽ có đội ngũ lập trình viên làm tại
                                                    công ty (inhouse) hoặc sẽ thuê các công ty phần mềm lớn làm hợp đồng
                                                    trị giá từ vài trăm triệu đến nhiều tỷ đồng. Một số website nổi bật
                                                    của một số ngành mà Newtrust đã triển khai: mảng bếp có
                                                    <strong>Bep68</strong>; vật liệu xây dựng có <strong>Newlando.vn,
                                                        Hiepthuy.vn</strong>; nội thất có
                                                    <strong>Luxfuni.com</strong>;&nbsp;thời trang đồng phục có
                                                    WegoUniform (Wego.net.vn), xây dựng có
                                                    <strong>MaxhomeGroup.vn</strong></div>
                                            </div>
                                            <div class="col-lg-12 li">
                                                <div class="tc-qs" (click)="ToggleTieuChi('TieuChi12')"><label
                                                        class="tc-text">Hệ thống quản trị dễ sử
                                                        dụng</label><br>
                                                    <span class="tc-number">2</span>
                                                </div>
                                                <div class="tc-aw TieuChi12" *ngIf="TieuChi12" style="display: block;" @expandCollapse>
                                                    <p><span style="color: #000000;">Ngoài thiết kế web, chúng tôi vận hành thương hiệu </span><span style="color: #000000;"><span style="color: #3366ff;"><b>GooAds </b></span><span style="color: #000000;">chuyên cung cấp dịch vụ marketing với trên 10 năm kinh nghiệm </span><span style="color: #ff0000;">triển khai quảng cáo, marketing tổng thể thành công cho các ngành hàng: Dịch vụ vận tải, Bán ô tô, Vật liệu xây dựng, Thiết kế thi công nội thất, Bán lẻ nội thất, Bếp, Xây nhà trọn gói, Thời trang…</span><span style="color: #000000;">như ở các thương hiệu ở mục 1. Chúng tôi thấu hiểu và đã tích hợp </span><span style="color: #3e5684;"><strong>những tiêu chí quan trọng mỗi </strong>website cần có giúp nâng cao chuyển đổi, nâng cao hiệu quả quảng cáo</span><span style="color: #000000;">. Một vài trong các tiêu chí quan trọng:</span></span> <span style="color: #ff0000;">tốc độ load web nhanh, giao diện thân thiện di động, các nút chuyển đổi hấp dẫn để gia tăng việc thu thập thông tin khách hàng</span>…<span style="color: #000000;">Một trong chứng thực cho năng lực là </span><span style="color: #000000;"><span style="color: #3366ff;"><b>GooAds </b></span><span style="color: #000000;">đã được cấp chứng nhận là Đối tác quảng cáo Google Ads về năng lực triển khai. Click vào nhãn chứng thực để xem chi tiết</span><b style="color: #000000;">:</b></span></p>
                                                    <p><a href="https://www.google.com/partners/agency?id=2459183713" target="_blank" rel="noopener"><img decoding="async" loading="lazy" class="alignnone lazy-loaded" src="https://www.gstatic.com/partners/badge/images/2022/PartnerBadgeClickable.svg" data-lazy-type="image" data-src="https://www.gstatic.com/partners/badge/images/2022/PartnerBadgeClickable.svg" alt="Chứng nhận đối tác Google ads" width="140" height="140"><noscript><img decoding="async" loading="lazy" class="alignnone" src="https://www.gstatic.com/partners/badge/images/2022/PartnerBadgeClickable.svg" alt="Chứng nhận đối tác Google ads" width="140" height="140" /></noscript></a></p>
                                                    </div>
                                            </div>
                                            <div class="col-lg-12 li">
                                                <div class="tc-qs" (click)="ToggleTieuChi('TieuChi13')"><label
                                                        class="tc-text">Tốc độ tải trang nhanh dưới 3
                                                        giây</label><br>
                                                    <span class="tc-number">3</span>
                                                </div>
                                                <div class="tc-aw TieuChi13" *ngIf="TieuChi13" @expandCollapse>Chúng tôi hỗ trợ Khách hàng trong suốt quá trình sử dụng dịch vụ một cách nhanh chóng qua nhóm Zalo, điện thoại, khi gặp vấn đề bạn chỉ cần chat vào group sẽ có các bộ phận chuyên trách hỗ trợ.</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="row">
                                            <div class="col-lg-12 li">
                                                <div class="tc-qs" (click)="ToggleTieuChi('TieuChi14')"><label
                                                        class="tc-text">Thiết kế Website chuẩn SEO</label><br>
                                                    <span class="tc-number">6</span>
                                                </div>
                                                <div class="tc-aw TieuChi14" *ngIf="TieuChi14" @expandCollapse>Năm 2020 – 2021 và 2023 gần như mọi doanh nghiệp đều bị ảnh hưởng bởi đại dịch và khủng hoảng kinh tế dẫn đến phải có sự thay đổi mang tính sống còn nếu muốn tồn tại. Sự chuyển biến lớn trong chiến lược phát triển của Newtrust là xây dựng hệ thống để có thể nhân bản nhanh chóng nhưng vẫn phải đảm bảo chất lượng sản phẩm giúp tối ưu chi phí phát triển một website. Điều này giúp chúng tôi đáp ứng được cả tiêu chí về chất lượng mà vẫn đảm bảo chi phí rẻ. Chi phí thiết kế Website tại Newtrust.vn cam kết chất lượng tốt và giá rẻ trọn gói chỉ từ 2,5 triệu là Khách hàng đã có thể có một website tối ưu có đầy đủ tính năng để bán hàng. Hơn nữa, website được thiết kế để sau này dễ dàng nâng cấp cho các mục tiêu lớn hơn tương ứng với sự phát triển của doanh nghiệp khách hàng
                                                </div>
                                            </div>
                                            <div class="col-lg-12 li">
                                                <div class="tc-qs" (click)="ToggleTieuChi('TieuChi15')"><label
                                                        class="tc-text">Danh mục sản phẩm thông
                                                        minh</label><br>
                                                    <span class="tc-number">7</span>
                                                </div>
                                                <div class="tc-aw TieuChi15" *ngIf="TieuChi15" @expandCollapse>Web chuẩn SEO là tiêu chí mặc định được tại các website chúng tôi thiết kế. Web chuẩn SEO mà không có những tính năng hỗ trợ tạo chuyển đổi thì cũng ít giá trị. Newtrust dựa trên kinh nghiệm thực tế, chúng tôi đưa những tính năng nổi bật giúp kích cầu hoặc kích thích việc Khách hàng tương tác, hay mua hàng của bạn</div>
                                            </div>
                                            <div class="col-lg-12 li">
                                                <div class="tc-qs" (click)="ToggleTieuChi('TieuChi16')"><label
                                                        class="tc-text">Trang sản phẩm thiết kế nổi
                                                        bật</label><br>
                                                    <span class="tc-number">8</span>
                                                </div>
                                                <div class="tc-aw TieuChi16" *ngIf="TieuChi16" @expandCollapse>Chúng tôi cam kết hoàn tiền 100% nếu Khách hàng không hài lòng, điều đó thể hiện cam kết về chất lượng của chúng tôi.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <!-- <div class="page-button">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="theme-button">
                                <a routerLink="/" class="default-btn">
                                    <i class="icofont-rounded-left"></i>
                                    Previous Post
                                </a>
                            </div>
                        </div>
                        <div class="col-md-3 offset-md-6">
                            <div class="theme-button">
                                <a routerLink="/" class="default-btn">
                                    Next Post
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>